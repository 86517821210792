import React from 'react';
import { useNavigate } from 'react-router-dom';

import testAvatar from '../../../../assets/images/png/test-avatar.png';
import { useProductMutation } from '../../../../hooks';
import { COLORS } from '../../../../theme';
import { IProductResponse, IStatus } from '../../../../types';
import {dateTransform, FilePath, statusTransform} from '../../../../utils';
import { Loader, Pagination } from '../../../common/component';
import * as StyledTable from '../../../common/component/table/table.styled';
import { APP_KEYS } from '../../../common/constants';
import { Iuuid } from '../../../common/types';
import { urlUtil } from '../../../common/utils';
import {IFiltersData} from "../filters/filters";
import {Png} from "../../../../assets";

export interface IActionTable {
  products: IProductResponse[];
  count: number;
  isManager?: boolean;
  pageStatus?: IStatus;
  page: number;
  onPageChange: (v: number) => void;
  isLoading?: boolean;
  setFilters?: (data: IFiltersData) => void;
  orders?: { [name: string]: string }
}

export const InventoryTable = ({
  products,
  isManager = false,
  count,
  pageStatus,
  page,
  onPageChange,
  isLoading,
    orders, setFilters
}: IActionTable) => {
  const navigate = useNavigate();
  const { mutate } = useProductMutation.status({
    status: pageStatus,
    page,
    is_dashboard: false,
    role: 'admin'
  });

  const onClick = (id: Iuuid) => {
    navigate(urlUtil(`${APP_KEYS.ROUTER_KEYS.SINGLE_PRODUCT}/${id}`));
  };

  const statusBtnText = {
    Pending: {
      text: 'Approve',
      onClick: (id: Iuuid) => {
        mutate({
          product_id: id,
          status: 'Approved'
        });
      }
    },
    Approved: {
      text: 'Send',
      onClick: (id: Iuuid) => {
        mutate({
          product_id: id,
          status: 'Sent'
        });
      }
    },
    Rejected: {
      text: 'Review',
      onClick
    },
    Sent: {
      text: 'View',
      onClick
    },
    Archived: {
      text: 'Renew',
      onClick: (id: Iuuid) => {
        mutate({
          product_id: id,
          status: 'Pending'
        });
      }
    }
  };

  const onChangeOrder = (name: string) => {
    // @ts-ignore
    setFilters((prev: IFiltersData) => {
      if (!prev.orders[name]) {
        return {...prev, orders: {...prev.orders, [name]: 'ASC'}}
      }
      if (prev.orders[name] === 'ASC') {
        return {...prev, orders: {...prev.orders, [name]: 'DESC'}}
      }
      if (prev.orders[name] === 'DESC') {
        delete prev.orders[name]

        return {...prev, orders: {...prev.orders}}
      }
      return {...prev}
    })
  };

  const sort = (name: string) => {
    // @ts-ignore
    if (!orders[name]) {
      return <StyledTable.Sort onClick={onChangeOrder.bind(this, name)}>
        <img src={Png.sortImage}/>
      </StyledTable.Sort>
    }
    // @ts-ignore
    if (orders[name]) {
      // @ts-ignore
      return orders[name] === 'ASC' ?
          <StyledTable.Sort onClick={onChangeOrder.bind(this, name)}>    <img src={Png.sortImage}/>  <span>a-z</span></StyledTable.Sort >:
          <StyledTable.Sort onClick={onChangeOrder.bind(this, name)}>    <img src={Png.sortImage}/>  <span>z-a</span></StyledTable.Sort >


    }
  };

  // @ts-ignore
  const buttonInfo = isManager && pageStatus ? statusBtnText[pageStatus] : null;

  return (
    <>
      <StyledTable.Table>
        <StyledTable.Head>
          <StyledTable.Row>
            {/*<StyledTable.HeadRow>Status</StyledTable.HeadRow>*/}
            {/*<StyledTable.HeadRow>Date</StyledTable.HeadRow>*/}
            <StyledTable.HeadRow>
              <div> Item Category {sort('category')}</div>
            </StyledTable.HeadRow>
            <StyledTable.HeadRow>
              <div> Manufacturer{sort('manufacturer_mame')}</div>
            </StyledTable.HeadRow>

            <StyledTable.HeadRow>
              <div>  Model #{sort('model_number')}</div>
            </StyledTable.HeadRow>
            <StyledTable.HeadRow>Auction Status</StyledTable.HeadRow>
            {/*<StyledTable.HeadRow>First Name</StyledTable.HeadRow>*/}
            {/*<StyledTable.HeadRow>Last Name</StyledTable.HeadRow>*/}

            {/*{buttonInfo?.text && (*/}
            {/*    <StyledTable.HeadRow className='button'>Auction</StyledTable.HeadRow>*/}
            {/*)}*/}
          </StyledTable.Row>
        </StyledTable.Head>
        <StyledTable.Body>
          {products
            ? products?.map(
                (
                  {
                    status,
                    createdAt,
                    category,
                    manufacturer_mame,
                    id,
                    user,
                    model_number,
                    is_listed
                  }: IProductResponse,
                  index
                ) => {
                  const { text } = status ? statusTransform(status) : statusTransform('Now Status');
                  // const created_at = dateTransform(createdAt);

                  return (
                    <StyledTable.Row key={`${id}${index}`} onClick={onClick.bind(this, id)}>
                      {/*<StyledTable.Data className='title'>*/}
                      {/*  <StyledTable.ItemLabel*/}
                      {/*    className='tooltip'*/}
                      {/*    linesToTruncate={1}*/}
                      {/*    tooltipText={text}*/}
                      {/*    // background={background}*/}
                      {/*  >*/}
                      {/*    {text}*/}
                      {/*  </StyledTable.ItemLabel>*/}
                      {/*</StyledTable.Data>*/}

                      {/*<StyledTable.Data className='title'>*/}
                      {/*  <StyledTable.ItemLabel*/}
                      {/*    className='tooltip'*/}
                      {/*    linesToTruncate={1}*/}
                      {/*    tooltipText={created_at}*/}
                      {/*  >*/}
                      {/*    {created_at}*/}
                      {/*  </StyledTable.ItemLabel>*/}
                      {/*</StyledTable.Data>*/}

                      <StyledTable.Data className='title'>
                        <StyledTable.ItemLabel
                          className='tooltip'
                          linesToTruncate={1}
                          tooltipText={category}
                        >
                          {category}
                        </StyledTable.ItemLabel>
                      </StyledTable.Data>

                      <StyledTable.Data className='title'>
                        <StyledTable.ItemLabel
                          className='tooltip'
                          linesToTruncate={1}
                          tooltipText={manufacturer_mame}
                        >
                          {manufacturer_mame}
                        </StyledTable.ItemLabel>
                      </StyledTable.Data>

                      <StyledTable.Data className='title'>
                        <StyledTable.ItemLabel
                          className='tooltip'
                          linesToTruncate={1}
                          tooltipText={model_number}
                        >
                          {model_number}
                        </StyledTable.ItemLabel>
                      </StyledTable.Data>

                      <StyledTable.Data className='title'>
                        {/*<StyledTable.Avatar*/}
                        {/*  src={user?.avatar ? user?.avatar : testAvatar}*/}
                        {/*  alt='user-avatar'*/}
                        {/*/>*/}
                        <StyledTable.ItemLabel
                          className='tooltip'
                          linesToTruncate={1}
                          tooltipText={is_listed ? 'Currently Listed' : 'Not Listed'}
                        >
                          {is_listed ? 'Currently Listed' : 'Not Listed'}
                        </StyledTable.ItemLabel>
                      </StyledTable.Data>

                      {/*{buttonInfo?.text && (*/}
                      {/*    <StyledTable.Data className='button'>*/}
                      {/*        <StyledTable.ButtonTable*/}
                      {/*            content={buttonInfo.text}*/}
                      {/*            onClick={buttonInfo.onClick.bind(this, id)}*/}
                      {/*            type='button'*/}
                      {/*            variant='inverse2'*/}
                      {/*        />*/}
                      {/*    </StyledTable.Data>*/}
                      {/*)}*/}

                      {/*<StyledTable.Data className='title'>*/}
                      {/*  <StyledTable.ItemLabel*/}
                      {/*    className='tooltip'*/}
                      {/*    linesToTruncate={1}*/}
                      {/*    tooltipText={user.first_name}*/}
                      {/*  >*/}
                      {/*    {user.first_name}*/}
                      {/*  </StyledTable.ItemLabel>*/}
                      {/*</StyledTable.Data>*/}
                      {/*<StyledTable.Data className='title'>*/}
                      {/*  <StyledTable.ItemLabel*/}
                      {/*    className='tooltip'*/}
                      {/*    linesToTruncate={1}*/}
                      {/*    tooltipText={user.last_name}*/}
                      {/*  >*/}
                      {/*    {user.last_name}*/}
                      {/*  </StyledTable.ItemLabel>*/}
                      {/*</StyledTable.Data>*/}
                    </StyledTable.Row>
                  );
                }
              )
            : null}
          {!isLoading && !products?.length && (
            <StyledTable.NowData>No items submitted yet.</StyledTable.NowData>
          )}
          {isLoading && (
            <StyledTable.NowData>
              <Loader size='small' color={COLORS.green} height='100%' />
            </StyledTable.NowData>
          )}
          {count && +count > 10 ? (
            <Pagination
              totalCount={+count}
              pageSize={100}
              siblingCount={1}
              onPageChange={onPageChange}
              currentPage={page}
            />
          ) : null}
        </StyledTable.Body>
      </StyledTable.Table>
    </>
  );
};
