import axios from 'axios';
import { UseQueryResult, useQuery } from 'react-query';

import { APP_KEYS } from '../../module/common/constants';
import { IFiltersData } from '../../module/inventory-manager/component';
import { IAllInfoProduct, IAllInfoProductSuperAdmin, IStatusMutation } from '../../types';

const all_info_product = (
  data: IStatusMutation,
  filters?: IFiltersData
): UseQueryResult<IAllInfoProduct> => {
  // @ts-ignore
  delete filters?.users;
  // @ts-ignore
  delete filters?.country;

  return useQuery(
    [
      APP_KEYS.QUERY_KEYS.GET_ALL_PRODUCTS_ADMIN,
      data.page,
      data.is_dashboard,
      data.status,
      data.is_download,
      filters
    ],
    async () => {
      const token = localStorage.getItem(APP_KEYS.STORAGE_KEYS.TOKEN);

      let queryString = `${process.env.REACT_APP_SERVER_URL}/admin/all-info-product?page=${
        data.page
      }&is_dashboard=${data.is_dashboard ?? false}&status=${data.status}&is_download=${
        data.is_download
      }`;

      const { data: _data } = await axios.post(
        queryString,
        { filters },
        {
          ...(data.is_download ? { responseType: 'blob' } : {}),
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return _data;
      // adminService.all_info_product(data, filters)
    },
    {
      onSuccess: (_data: IAllInfoProduct) => {
        if (data.is_download) {
          // @ts-ignore
          const downloadUrl = window.URL.createObjectURL(new Blob([_data]));
          const downloadLink = document.createElement('a');
          downloadLink.href = downloadUrl;
          downloadLink.setAttribute('download', 'example.xlsx');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }
      },
      enabled: data.role === 'admin',
      refetchOnWindowFocus: true,
      cacheTime: 0
    }
  );
};

const all_info_product_super_admin = (
  data: IStatusMutation,
  filters?: IFiltersData
): UseQueryResult<IAllInfoProductSuperAdmin> =>
  useQuery(
    [
      APP_KEYS.QUERY_KEYS.GET_ALL_PRODUCTS_SUPER_ADMIN,
      data.page,
      data.is_dashboard,
      data.status,
      data.is_download,
      filters
    ],
    async () => {
      let queryString = `${process.env.REACT_APP_SERVER_URL}/admin/super-all-info-product?page=${
        data.page
      }&is_dashboard=${data.is_dashboard ?? false}&status=${data.status}&is_download=${
        data.is_download
      }`;

      const token = localStorage.getItem(APP_KEYS.STORAGE_KEYS.TOKEN);

      const { data: _data } = await axios.post(
        queryString,
        { filters },
        {
          ...(data.is_download ? { responseType: 'blob' } : {}),
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      return _data;
      // adminService.all_info_product_super_admin(data, filters),
    },

    {
      onSuccess: (_data: IAllInfoProductSuperAdmin) => {
        if (data.is_download) {
          // @ts-ignore
          const downloadUrl = window.URL.createObjectURL(new Blob([_data]));
          const downloadLink = document.createElement('a');
          downloadLink.href = downloadUrl;
          downloadLink.setAttribute('download', 'example.xlsx');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }
      },
      enabled: (data.role === 'super_admin' || data.role === 'viewer') ,
      refetchOnWindowFocus: true,
      cacheTime: 0
      // keepPreviousData: true,
      // refetchOnMount: true,
      // refetchOnReconnect: true,
    }
  );

export const useAdminQuery = {
  all_info_product,
  all_info_product_super_admin
};
