import { EnhancedWithAuthHttpService, HttpFactoryService } from '../module/common/services';
import { ExpectedFromQuery, IMessage, Iuuid } from '../module/common/types';
import {
  IGetAllFiltersUsers,
  IGetAllUsers,
  IGetAllUsersAndGroup,
  IGetOneUser,
  IUserCreate,
  IUserEdit
} from '../types';

class UserService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}

  async all(data: IGetAllFiltersUsers): Promise<ExpectedFromQuery<IGetAllUsers>> {
    return this.httpService.post<IGetAllUsers, IGetAllFiltersUsers>('/user/all', data);
  }

  async all_and_group(): Promise<ExpectedFromQuery<IGetAllUsersAndGroup>> {
    return this.httpService.get<IGetAllUsersAndGroup>('/user/all-and-group');
  }

  async findOne(id?: Iuuid | null): Promise<ExpectedFromQuery<IGetOneUser>> {
    const url = id ? `/user?user_id=${id}` : '/user';
    return this.httpService.get<IGetOneUser>(url, {});
  }

  async post(data: IUserCreate): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.post<IMessage, IUserCreate>('/user', data);
  }

  async put(data: IUserEdit, id?: Iuuid): Promise<ExpectedFromQuery<IMessage>> {
    const url = id ? `/user?user_id=${id}` : '/user';
    return this.httpService.put<IMessage, Partial<IUserEdit>>(url, data);
  }

  async delete(id?: Iuuid): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.delete<IMessage>(`/user/${id}`);
  }
}

const factory = new HttpFactoryService();
export const userService = new UserService(factory.createAuthHttpService());
