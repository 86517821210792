import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { Icons } from '../../../../assets';
import { FilePath } from '../../../../utils';
import * as Styled from './accordion.styled';

export interface IAccordion {
  title: string;
  children: ReactNode;
  index: number;
  style?: any;
  icon?: any;
  onClickIcon?: () => void;
  onClick?: () => void;
  isOpen?: boolean;
  isNewWindow?: boolean;
  propComponent?: ReactNode;
}

export const Accordion = ({
  title,
  children,
  index = 0,
  style,
  icon,
  onClickIcon,
  isNewWindow,
  onClick,
  isOpen,
  propComponent
}: IAccordion) => {
  const ref = useRef<HTMLDetailsElement | null>(null);

  const [isOpenDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (isOpen && index === 0 && ref.current) {
      ref.current.open = true;
    }
  }, [isOpen, index]);

  const toggleDetails = () => {
    onClick && onClick();
    setOpenDrawer(!isOpenDrawer);
  };

  return (
    <Styled.Details ref={ref} id={`details${index}`} styled={style} onClick={toggleDetails}>
      <Styled.Summary className='summary'>
        <Styled.Span>{title}</Styled.Span>
        <Styled.IconContainer>
          {icon && <img onClick={onClickIcon} className='propIcon' src={icon} alt='icon' />}

          <img
            className={isNewWindow ? 'icon2' : 'icon'}
            src={isNewWindow ? FilePath(Icons.arrowRightIcon) : FilePath(Icons.arrowBottomIcon)}
            alt='arrow'
          />

          {propComponent}
        </Styled.IconContainer>
      </Styled.Summary>

      <div className='children'>{children}</div>
    </Styled.Details>
  );
};
