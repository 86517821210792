export const COLORS = {
  white: '#fff',
  white50: '#F4F4F4',
  white100: '#F3F3F3',
  white200: '#F5F6FE',
  white250: '#d0d5dd',
  semiWhite: '#d0d5dd',

  black: '#000',
  black20: 'rgba(0, 0, 0, 0.80)',
  black25: 'rgba(0, 0, 0, 0.60)',
  black30: 'rgba(0, 0, 0, 0.50)',
  black50: 'rgba(0, 0, 0, 0.40)',
  black100: 'rgba(0, 0, 0, 0.25)',
  black125: 'rgba(0, 0, 0, 0.20)',
  black150: 'rgba(0, 0, 0, 0.10)',
  black200: 'rgba(0, 0, 0, 0.03)',
  black250: 'rgba(0, 0, 0, 0.05)',

  gray: '#6B6775',
  gray100: '#98a2b3',

  purple: '#A458BE',
  purple20: '#CD41FF',
  purple40: '#A458BE',
  purple100: '#F7DFFF',
  blue: '#189EC8',
  blue20: '#4198FF',
  blue100: '#D4F2FF',
  orange: '#D68C35',
  orange50: '#C29D3B',
  orange100: '#FFEAD2',

  mainRed: '#A23030',
  red50: '#ec091f',
  red100: '#A330x30',
  red125: '#A23030',
  red150: '#FCE6E6',
  green: '#247B43',
  green50: '#33a95d',
  green100: 'rgba(230, 252, 235, 0.90)'
};
