import styled from 'styled-components';

import {Icons, Png} from '../../../../assets';
import { COLORS, MEDIA, SHADOWS, SPACES } from '../../../../theme';
import { FilePath } from '../../../../utils';
import { Center, DivCommon } from '../../../common/styles';

export const Container = styled.div`
  width: 100%;
  height: 100dvh;

  display: flex;

  background: ${COLORS.white50};
`;

export const Div = styled.div<{ isBackground?: boolean }>`
  width: ${({ isBackground }) => (isBackground ? '50%' : '100%')};
  height: 100%;

  overflow-y: auto;

  ${Center};
  padding: 2rem 0;

  overflow-y: auto;

  padding-bottom: ${SPACES.xxxxl};
  margin: 0 auto;

  &::-webkit-scrollbar {
    width: 0px;
    background-color: ${COLORS.black50};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.green};
  }

  @media screen and (max-width: ${MEDIA.laptop_custom}) {
    width: 100%;
  }
`;
export const BackgroundDiv = styled(Div)`
  margin: 0;
  //background: no-repeat center / 175% auto url(${FilePath(Icons.logo)});

  background-size: cover !important;

  @media screen and (max-width: ${MEDIA.laptop_custom}) {
    display: none;
  }
`;

export const Content = styled(DivCommon)`
  width: 70%;
  max-width: 33rem;
  height: fit-content;

  margin: auto;
  padding: 4rem;

  background: ${COLORS.white};
  border-radius: ${SPACES.s};
  box-shadow: ${SHADOWS.xxs};

  @media screen and (max-width: ${MEDIA.laptop_custom}) {
    width: 93%;
    padding: 4rem 1rem;
  }

  @media screen and (max-width: ${MEDIA.laptop}) {
    padding: ${SPACES.xxxxl};
  }
`;
