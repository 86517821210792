import { Icons } from '../assets';
import { COLORS } from '../theme';
import { IStatus } from '../types';
import { FilePath } from './file-path';

export interface IStatusTransform {
  text: string;
  background: string;
  icon: any;
  status: IStatus;
}

export interface IStatusTransformObj {
  [key: string]: IStatusTransform;
}

export const statusTransform = (status: IStatus) => {
  const statusObj: IStatusTransformObj = {
    Pending: {
      text: status,
      background: COLORS.orange50,
      icon: FilePath(Icons.clockIcon),
      status: 'Pending'
    },
    Approved: {
      text: status,
      background: COLORS.blue,
      icon: FilePath(Icons.successIcon2),
      status: 'Approved'
    },
    Rejected: {
      text: status,
      background: COLORS.red125,
      icon: FilePath(Icons.rejectedIcon),
      status: 'Rejected'
    },
    Sent: {
      text: status,
      background: COLORS.green,
      icon: FilePath(Icons.actionIcon),
      status: 'Sent'
    },
    'In Auction': {
      text: status,
      background: COLORS.purple40,
      icon: FilePath(Icons.arrowRight2),
      status: 'In Auction'
    },
    'Out of Auction': {
      text: status,
      background: COLORS.orange,
      icon: FilePath(Icons.outActione),
      status: 'Out of Auction'
    },
    'Sold at Auction': {
      text: status,
      background: COLORS.green,
      icon: FilePath(Icons.successIcon2),
      status: 'Sold at Auction'
    },
    Archived: {
      text: status,
      background: COLORS.black50,
      icon: FilePath(Icons.companiesIcon),
      status: 'Archived'
    },

    'New Opportunity': {
      text: status,
      background: COLORS.orange50,
      icon: FilePath(Icons.companiesIcon),
      status: 'New Opportunity'
    },
    'Hot Lead': {
      text: status,
      background: COLORS.blue,
      icon: FilePath(Icons.companiesIcon),
      status: 'Hot Lead'
    },
    'Medium Lead': {
      text: status,
      background: COLORS.orange50,
      icon: FilePath(Icons.companiesIcon),
      status: 'Medium Lead'
    },
    'Now Status': {
      text: '-',
      background: COLORS.orange50,
      icon: FilePath(Icons.companiesIcon),
      status: 'Now Status'
    }
  };

  return statusObj[status];
};
