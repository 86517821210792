import {EnhancedWithAuthHttpService, HttpFactoryService} from '../module/common/services';
import {ExpectedFromQuery, IMessage} from '../module/common/types';
import {IManufacturerAll, IManufacturerPost} from "../types/manufacturers.type";

class ManufacturertsServiceService {
    constructor(private httpService: EnhancedWithAuthHttpService) {
    }

    async all(): Promise<ExpectedFromQuery<IManufacturerAll>> {
        return this.httpService.get<IManufacturerAll>('/manufacturer/all');
    }

    async post(data: IManufacturerPost): Promise<ExpectedFromQuery<IMessage>> {
        return this.httpService.post<IMessage, IManufacturerPost>('/manufacturer', data);
    }
}

const factory = new HttpFactoryService();
export const manufacturertsService = new ManufacturertsServiceService(factory.createAuthHttpService());
