import styled, { css } from 'styled-components';

import { ReactComponent as IconCopySVG } from '../../../../assets/icons/clock-icon.svg';
import { COLORS, FONTS, SHADOWS, SPACES } from '../../../../theme';
import { CommonSettingNavigationItem, DivCommon, Fonts } from '../../../common/styles';

export const InfoContainer = styled(DivCommon)`
  height: 100%;

  overflow-y: auto;
  padding-bottom: 2rem;

  &::-webkit-scrollbar {
    width: 0px;
    background-color: ${COLORS.black50};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.green};
  }
`;

export const Container = styled(DivCommon)`
  border-radius: 12px;
  padding: ${SPACES.xxxxxxl} ${SPACES.xxxxxxls};

  background: ${COLORS.white};
  box-shadow: ${SHADOWS.xxs};
`;

export const Container2 = styled.div`
  width: 40%;
  height: fit-content;
  border-radius: 12px;

  padding: ${SPACES.xxxxxxls} ${SPACES.l};

  background: ${COLORS.white};
  box-shadow: ${SHADOWS.xxs};

  gap: ${SPACES.xxxxl};

  display: flex;
  flex-wrap: wrap;
`;

export const PlusContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  & .icon {
    cursor: pointer;
  }
`;

export const SettingItem = styled(CommonSettingNavigationItem)<{ backgroundColor?: string }>`
  height: 2rem;
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${COLORS.white};
  padding: ${SPACES.xxxs} ${SPACES.s} !important;
`;

export const Image = styled.div<{
  is_primary_photo: boolean;
  is_manufacturer_tag: boolean;
  path: any;
  index: number;
}>`
  position: relative;
  cursor: pointer;

  ${({ index }) =>
    index === 0
      ? css`
          max-height: 15rem;
          width: 100% !important;
          height: 15rem !important;
        `
      : css`
          width: 45%;
          aspect-ratio: 1/1;
        `};

  background: url(${({ path }) => path});
  background-position: center;
  background-size: cover;

  &::before,
  &::after {
    content: '';
    ${Fonts};
    font-size: ${FONTS.SIZES.s};
    color: ${COLORS.white};

    padding: ${SPACES.xxxxs} ${SPACES.s};
    border-radius: 50px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }

  ${({ is_primary_photo }) =>
    is_primary_photo &&
    css`
      &::before {
        content: 'PRIMARY';
        background-color: ${COLORS.blue20};
        display: block;
      }
    `};

  ${({ is_manufacturer_tag }) =>
    is_manufacturer_tag &&
    css`
      &::after {
        content: 'MFG TAG';
        font-size: ${FONTS.SIZES.xxs};
        background-color: ${COLORS.purple20};
        display: block;
      }
    `};

  ${({ is_manufacturer_tag, is_primary_photo }) =>
    is_manufacturer_tag &&
    is_primary_photo &&
    css`
      &::before {
        left: 30%;
      }
      &::after {
        left: 70%;
        font-size: ${FONTS.SIZES.s};
      }
    `};
`;

export const Ul = styled.ul`
  margin-top: ${SPACES.xxxxl};
  gap: ${SPACES.l};
  display: flex;
  flex-direction: column;
`;

export const Li = styled.li`
  word-break: break-word;

  display: flex;
  gap: ${SPACES.l};
`;
