import React from 'react';

import { ILoaderProps } from '../../types';
import * as Styled from './loading.styled';

export const Loader = ({ size, margin, height, className, id, ...restProps }: ILoaderProps) => (
  <Styled.MyContainer height={height} id={id} margin={margin} className={className}>
    <Styled.Loader size={size ?? 'medium'} {...restProps} />
  </Styled.MyContainer>
);
