export const hourUtil = (_time: string | undefined): string => {
  const timeParts = _time ? _time.split(':') : '';

  // @ts-ignore
  const inputTime = new Date(0, 0, 0, timeParts[0], timeParts[1], timeParts[2]);
  const outputTime = inputTime.toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  return outputTime;
};

export const dateTransform = (_time: string | undefined, isSingle?: boolean): string => {
  const time = new Date(_time as string);

  const year = time.getFullYear();
  const date = time.getDate();
  const month = time.getMonth();

  if (isSingle) {
    const month2 = time.toLocaleString('en', { month: 'long' });

    return `${month2} ${date},${year}`;
  }

  return `${month + 1}/${date}/${year}`;
};
