import { Form, Formik, getIn } from 'formik';
import React, { useState } from 'react';

import { Icons } from '../../../../assets';
import { useCompanyQuery, useUserMutation, useUserQuery } from '../../../../hooks';
import { COLORS, FONTS, SPACES } from '../../../../theme';
import { IUserCreate, IUserEdit } from '../../../../types';
import { FilePath } from '../../../../utils';
import {
  AvatarSetup,
  Button,
  Input,
  InputContainer,
  InputMatchedWords,
  Loader,
  Loading,
  PopupLayout,
  Portal
} from '../../../common/component';
import { InputsConst, Location, returnUserRoles } from '../../../common/constants';
import { DivCommon, SubTitleCommon, TitleCommon } from '../../../common/styles';
import { Iuuid } from '../../../common/types';
import { validationSchemaUser } from '../../validation/shema';
import * as Styled from './user-crud.styled';

export interface IUserCrud {
  onClose: () => void;
  isView?: boolean;
  id?: Iuuid | any;
  isCreate?: boolean;
  isEditUser?: boolean;
  isMainUser?: boolean;
}

export const UserCrud = ({
  onClose,
  isView = false,
  id = null,
  isCreate = true,
  isEditUser = false,
  isMainUser = false
}: IUserCrud) => {
  const [isEdit, setEdit] = useState(!isView);
  const [isDelete, setIsDelete] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const { post, put, deleteUser } = useUserMutation;
  const { findOne, findOneCacheMainUser } = useUserQuery;
  const { all: allCompanies } = useCompanyQuery;

  const mainUser = findOneCacheMainUser();

  const { isLoading: isLoadingCompanies, data: dataCompanies } = allCompanies({}, {});
  const { isLoading: isLoadingPost, mutate: mutatePost } = post();
  const { isLoading: isLoadingPut, mutate: mutatePut } = put(id, { isMainUser });
  const { isLoading: isLoadingDelete, mutate: mutateDelete } = deleteUser(id);
  const { isLoading: isLoadingData, data } = findOne(id);

  const isLoading = isLoadingPost || isLoadingPut || isLoadingDelete;

  const companies =
    dataCompanies?.companies && dataCompanies?.companies.length
      ? mainUser?.user?.role === 'admin'
        ? dataCompanies?.companies.filter((v) => v.id === mainUser.user.company_info.id)
        : dataCompanies.companies.map((v) => ({
            name: v.name,
            id: v.id
          }))
      : [];

  const toggleIsDelete = (flag?: boolean) => {
    setIsDelete(flag ?? false);
  };

  const onDelete = () => {
    toggleIsDelete();
    mutateDelete();
    onClose();
  };

  const onResetPassword = () => {
    setEdit(true);
    setIsReset(true);
  };

  const onSubmit = (_data: any) => {
    if (id || !isCreate) {
      delete _data.password;
    }

    id || !isCreate ? mutatePut(_data as IUserEdit) : mutatePost(_data as IUserCreate);

    onClose();
  };

  const roles = returnUserRoles(data?.user.role ?? mainUser?.user?.role ?? 'user');

  const locations = (company_info: any) => {
    if (!company_info) return Location;

    const location = dataCompanies?.companies
      ? dataCompanies?.companies.reduce((acc, v) => {
          if (v.id === company_info.id) {
            // @ts-ignore
            acc.push(...(v?.location as string));
          }
          return acc;
        }, [])
      : [];

    return location;
  };

  return (
    <Styled.Container>
      {isLoadingData || isLoadingCompanies ? <Loading className='full-screen' /> : null}
      <DivCommon fd='row' ai='center'>
        <TitleCommon fw={FONTS.WEIGHTS.semi_bold}>Account Settings</TitleCommon>
        <Styled.CloseBtn onClick={onClose} />
      </DivCommon>

      <Formik
        initialValues={{
          first_name: isView ? data?.user.first_name : '',
          last_name: isView ? data?.user.last_name : '',
          email: isView ? data?.user.email : '',
          phone: isView ? data?.user.phone : '',
          ...(isView && isReset
            ? {
                current_password: '',
                new_password: '',
                confirm_password: ''
              }
            : {
                password: ''
              }),

          company_info: isView ? data?.user.company_info ?? '' : '',
          location: isView ? data?.user.location ?? [] : [],
          role: isView ? data?.user.role ?? '' : ''
        }}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={validationSchemaUser(isReset, !isView)}
      >
        {({ isValid, values }) => (
          <Form>
            {isEditUser && (
              <>
                <TitleCommon ta='center' mt={SPACES.xxxxl} mb={SPACES.l}>
                  {values.first_name} {values.last_name}
                </TitleCommon>
                <DivCommon margin={`${SPACES.xxxxl} 0 0 0`} width='100%' ai='center'>
                  <AvatarSetup name='avatar' readOnly={!isEdit} />
                </DivCommon>
              </>
            )}

            <TitleCommon mt={SPACES.xxxxl} mb={SPACES.l}>
              User Settings
            </TitleCommon>
            <Styled.Line />

            <InputContainer readOnly={!isEdit} />

            <TitleCommon mt={SPACES.xxxxl} mb={SPACES.l}>
              Company Information
            </TitleCommon>
            <Styled.Line />

            <InputMatchedWords
              readOnly={isCreate ? !isEdit : true}
              matchedWords={companies}
              visibleItem='name'
              height='3.5rem'
              isDontChange
              mt={SPACES.xxxxl}
              {...InputsConst.company_info}
            />
            {getIn(values, 'company_info').name?.length ? (
              <InputMatchedWords
                matchedWords={locations(values.company_info)}
                visibleItem='city'
                height='3.5rem'
                mt={SPACES.xxxxl}
                {...InputsConst.location}
                readOnly={!isEdit}
                isDontChange
                isChip
              />
            ) : null}

            <InputMatchedWords
              matchedWords={roles}
              height='3.5rem'
              mt={SPACES.xxxxl}
              {...InputsConst.role}
              readOnly={isCreate ? !isEdit : true}
              isDontChange
            />

            <TitleCommon mt={SPACES.xxxxl} mb={SPACES.l}>
              Security
            </TitleCommon>
            <Styled.Line mb={SPACES.l} />

            {isView ? (
              <>
                <SubTitleCommon>
                  {isReset
                    ? 'To change your password, simply click on the "Reset Password" link and follow the provided instructions to update your account\'s security settings.'
                    : 'To change your password, please input your current password followed by your new password. Hit the “Save Changes” button below to finish updating your password.'}
                </SubTitleCommon>

                {!isReset && (
                  <DivCommon margin={`${SPACES.l} 0 0 0 `}>
                    <SubTitleCommon onClick={onResetPassword} color={COLORS.green} cursor='pointer'>
                      Reset Password
                    </SubTitleCommon>
                  </DivCommon>
                )}
                {isReset &&
                  (
                    [
                      'current_password',
                      'new_password',
                      'confirm_password'
                    ] as (keyof typeof InputsConst)[]
                  ).map((v, index) => (
                    <Input
                      key={index}
                      mt={SPACES.xxxxl}
                      readOnly={!isEdit}
                      type='password'
                      height='3.5rem'
                      {...InputsConst[v]}
                    />
                  ))}
              </>
            ) : (
              <Input
                mt={SPACES.xxxxl}
                readOnly={!isEdit}
                type='password'
                height='3.5rem'
                {...InputsConst.password}
              />
            )}

            {!isView && (
              <Button
                mt={SPACES.xxxxl}
                content={
                  !isLoading ? (
                    'Create Account'
                  ) : (
                    <Loader size='small' color={COLORS.green} height='auto' />
                  )
                }
                type='submit'
                variant='primary'
                disabled={!isValid || isLoading}
              />
            )}

            {isView && !isEdit && (
              <Button
                mt={SPACES.xxxxl}
                content='Edit Account'
                type='button'
                variant='primary'
                onClick={() => setEdit(true)}
              />
            )}

            {isView && isEdit && (
              <>
                <Button
                  mt={SPACES.xxxxl}
                  content={
                    !isLoading ? (
                      'Save Changes'
                    ) : (
                      <Loader size='small' color={COLORS.green} height='auto' />
                    )
                  }
                  type='submit'
                  variant='primary'
                  disabled={!isValid || isLoading}
                />
                <Button
                  mt={SPACES.xxxxl}
                  content='Cancel'
                  type='button'
                  variant='inverse2'
                  onClick={() => {
                    setIsReset(false);
                    setEdit(false);
                  }}
                />

                <Button
                  mt={SPACES.xxxxl}
                  content='Delete User'
                  type='button'
                  variant='delete'
                  startIcon={FilePath(Icons.deleteIcon)}
                  widthIcon='1.2rem'
                  background={COLORS.mainRed}
                  onClick={toggleIsDelete.bind(this, true)}
                />
              </>
            )}
          </Form>
        )}
      </Formik>

      {isDelete ? (
        <Portal>
          <PopupLayout onClose={toggleIsDelete.bind(this, false)}>
            <TitleCommon ta='center' mb={SPACES.l}>
              Are you sure you want to delete your account?{' '}
            </TitleCommon>
            <SubTitleCommon width='100%' ta='center'>
              This action cannot be reverted.
            </SubTitleCommon>
            <DivCommon>
              <Button
                mt={SPACES.xxxxl}
                content='Yes'
                type='button'
                variant='delete'
                onClick={onDelete}
              />
              <Button content='Cancel' type='button' variant='inverse2' onClick={toggleIsDelete} />
            </DivCommon>
          </PopupLayout>
        </Portal>
      ) : null}
    </Styled.Container>
  );
};
