import { Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useSingUp } from '../../../../hooks/auth';
import { COLORS, SPACES } from '../../../../theme';
import { ISingUp } from '../../../../types';
import { Button, Input, InputContainer, Loader } from '../../../common/component';
import { APP_KEYS, InputsConst } from '../../../common/constants';
import { SubTitleCommon, TitleCommon } from '../../../common/styles';
import { PageLayout } from '../../common';
import { validationSchemaSingUp } from '../../validation/shema';

export const SingUp = () => {
  const navigate = useNavigate();

  const { isLoading, mutate } = useSingUp();

  const onSubmit = (data: ISingUp) => {
    mutate(data);
  };

  const onNavigate = () => {
    navigate(APP_KEYS.ROUTER_KEYS.LOGIN);
  };

  const onBlur = () => {
    window.scrollTo(0, 0);
  };

  return (
    <PageLayout isBackground={false}>
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          password: '',
          confirm_password: ''
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchemaSingUp}
      >
        {({ isValid }) => (
          <Form onBlur={onBlur}>
            <TitleCommon>Create Account</TitleCommon>
            <SubTitleCommon color={COLORS.black50} mt={SPACES.xxs}>
              Already have an account?
              <SubTitleCommon
                cursor='pointer'
                ml={SPACES.xxxxs}
                onClick={onNavigate}
                color={COLORS.green}
              >
                Sign in here.
              </SubTitleCommon>
            </SubTitleCommon>

            <InputContainer />

            <Input mt={SPACES.xxxxl} type='password' height='3.5rem' {...InputsConst.password} />

            <Input
              mt={SPACES.xxxxl}
              type='password'
              height='3.5rem'
              {...InputsConst.confirm_password}
            />

            <Button
              mt={SPACES.xxxxl}
              content={
                !isLoading ? (
                  'Create Account'
                ) : (
                  <Loader size='small' color={COLORS.green} height='auto' />
                )
              }
              type='submit'
              variant='primary'
              disabled={!isValid || isLoading}
            />
          </Form>
        )}
      </Formik>
    </PageLayout>
  );
};
