import styled, { css } from 'styled-components';

import { FONTS, SPACES } from '../../../../theme';
import { CloseButton } from '../../../common/component';
import { DrawerContainer } from '../../../common/styles';

export const Container = styled(DrawerContainer)``;

export const CloseBtn = styled(CloseButton)`
  margin: 0 0 0 auto;
  width: 1.5rem;
  height: 1.5rem;
`;

export const StyleAccordion = css`
  & > .children {
    padding: ${SPACES.l} 0;
    display: flex;
    flex-direction: column;
    gap: ${SPACES.l};
  }

  & > .summary > span {
    font-weight: ${FONTS.WEIGHTS.semi_bold};
    font-size: ${FONTS.SIZES.l};
  }
`;

export const StyleAccordion2 = css`
  & > .children {
    padding: ${SPACES.l};
  }

  & > .summary > span {
    font-weight: ${FONTS.WEIGHTS.semi_bold};
    font-size: ${FONTS.SIZES.l};
  }
`;
