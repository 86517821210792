import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SPACES, TRANSITIONS } from '../../../../../theme';
import { Fonts, IconCommon, Margin } from '../../../styles';
import { IButtonAppearances, IButtonProps, IIconButton, TNavLink } from '../../../types';

const style = css<IButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: ${({ width }) => width ?? '100%'};
  height: ${({ height }) => height ?? '44px'};

  padding: ${({ pads }) => pads ?? `${SPACES.l} ${SPACES.xxxxl}`};

  ${Margin};
  ${Fonts};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  color: ${COLORS.white};

  border-radius: 1.5rem;

  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  background-color: transparent;

  transition: all ${TRANSITIONS.duration.fast} ${TRANSITIONS.function.linear};
  cursor: pointer;

  &:disabled {
    cursor: initial;
    background-color: ${COLORS.white50};
    padding: ${({ pads }) => pads ?? `${SPACES.l} ${SPACES.xxxxl}`};
  }

  @media screen and (max-width: ${MEDIA.mobile_m}) {
    font-size: ${FONTS.SIZES.xxsm};
    line-height: ${FONTS.SIZES.xxxxl};
  }

  @media screen and (max-width: ${MEDIA.mobile_l}) {
    font-size: ${FONTS.SIZES.s};
    line-height: ${FONTS.SIZES.xxxxl};
  }
`;

const buttonAppearances: IButtonAppearances = {
  primary: `
    background-color: ${COLORS.green};
    border: 1px solid ${COLORS.green};
    color: ${COLORS.white};
        cursor: pointer;
        
          & > .start {
   background-color: ${COLORS.white};
  }


  &:hover,
  &:focus {
    background-color: ${COLORS.green50};
  }
  &:active {
    background-color: ${COLORS.green50};
    border-color: ${COLORS.green50};
  }
  &:disabled {
    background-color: ${COLORS.white50};
    border-color: ${COLORS.white50};
    color: ${COLORS.black50};

    &:hover {
      pointer-events: none;
    }
  }
  

`,
  inverse: `
    background-color: ${COLORS.white};
    border: 1px solid ${COLORS.black50};
    color: ${COLORS.black50};
        cursor: pointer;
        
  & > .start {
   background-color: ${COLORS.black50};
  }


  &:hover,
  &:focus {
    background-color: ${COLORS.black50};
        color: ${COLORS.white};

    & > .start {
   background-color: ${COLORS.white};
  }
  }
  &:active {
    background-color: ${COLORS.black50};
    border-color: ${COLORS.black50};
  }
  &:disabled {
    background-color: ${COLORS.white50};
    border-color: ${COLORS.white50};
    color: ${COLORS.black50};

    &:hover {
      pointer-events: none;
    }
  }
`,
  inverse2: `
    background-color: ${COLORS.white};
    border: 1px solid ${COLORS.green};
    color: ${COLORS.green};
        cursor: pointer;
        
  & > .start {
   background-color: ${COLORS.green};
  }


  &:hover,
  &:focus {
    background-color: ${COLORS.green};
     color: ${COLORS.white};
     
      & > .start {
   background-color: ${COLORS.white};
  }
  }
  &:active {
    background-color: ${COLORS.green};
    border-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  &:disabled {
    background-color: ${COLORS.white50};
    border-color: ${COLORS.white50};
    color: ${COLORS.black50};

    &:hover {
      pointer-events: none;
    }
  }
`,
  delete: `
    background-color: ${COLORS.white};
    border: 1px solid ${COLORS.mainRed};
    color: ${COLORS.mainRed};
        cursor: pointer;
        
  & > .start {
   background-color: ${COLORS.mainRed};
  }


  &:hover,
  &:focus {
    background-color: ${COLORS.red50};
    color: ${COLORS.white};
    
     & > .start {
   background-color: ${COLORS.white};
  }
  }
  &:active {
    background-color: ${COLORS.red50};
    border-color: ${COLORS.red50};
    color: ${COLORS.white};
  }
  &:disabled {
    background-color: ${COLORS.white50};
    border-color: ${COLORS.white50};
    color: ${COLORS.black50};

    &:hover {
      pointer-events: none;
    }
  }
 
`
};

export const StyledButton = styled.button<IButtonProps>`
  ${style}
  ${({ variant }) => buttonAppearances[variant as keyof typeof buttonAppearances]}
  cursor: pointer;
`;

export const NavLink = styled(Link)<TNavLink>`
  text-decoration: none;
  color: inherit;
  ${style}
  ${({ variant }) => buttonAppearances[variant as keyof typeof buttonAppearances]}
`;

export const Icon = styled(IconCommon)<IIconButton>`
  height: ${({ widthIcon }) => (widthIcon ? `${widthIcon} !important` : '25px !important')};
  aspect-ratio: 1/1;
  margin-right: ${({ marginIcon }) => marginIcon ?? SPACES.xs};
  margin-left: ${({ marginIcon }) => marginIcon ?? SPACES.xs};
`;

export const Icon2 = styled(IconCommon)<IIconButton>`
  height: ${({ widthIcon }) => (widthIcon ? `${widthIcon} !important` : '25px !important')};
  margin-right: ${({ marginIcon }) => marginIcon ?? SPACES.xs};
`;
