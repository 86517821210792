import { AxiosError } from 'axios';
import { QueryClient, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { toastContainer } from '../../module/common/component/toast';
import { APP_KEYS } from '../../module/common/constants';
import { IMessage, Iuuid } from '../../module/common/types';
import { productService } from '../../services';
import {
  IAuthError,
  IMessageProductStatus,
  IProduct,
  IProductEdit,
  ISoldChange,
  IStatusChange,
  IStatusMutation
} from '../../types';

const onError = (_err: AxiosError<IAuthError>) => {
  const err = _err.response?.data as IAuthError;
  toastContainer.error({ title: err.message ?? _err.message });
};

const onSuccess = async ({ message }: IMessage) => {
  toastContainer.success({ title: message });
};

const post = (socket: any) => {
  const navigate = useNavigate();
  const client = useQueryClient();
  return useMutation<any, AxiosError<IAuthError>, IProduct>(
    (data: IProduct) => productService.post(data),
    {
      onSuccess: async (data: IMessage) => {
        await onSuccess(data);

        await Promise.allSettled([
          client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_ALL_PRODUCTS_MY]),
          client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_ALL_PRODUCTS_ADMIN]),
          client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_ALL_PRODUCTS_SUPER_ADMIN]),
          client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_ALL_PRODUCTS])
        ]);

        socket.emit('update_item');
        navigate(APP_KEYS.ROUTER_KEYS.GUEST_AFTER_ITEM);
      },
      onError: (_err: AxiosError<IAuthError>) => {
        const err = _err.response?.data as IAuthError;

        if (err.errorCode === 210) {
          toastContainer.error({ title: err.message ?? _err.message, text: err.error });
        } else if (err.errorCode === 111) {
          onError(_err);
          localStorage.removeItem(APP_KEYS.STORAGE_KEYS.TOKEN);
          navigate(APP_KEYS.ROUTER_KEYS.LOGIN);
        } else {
          onError(_err);
        }
      }
    }
  );
};

const onSuccessStatus = async (
  { message, status: _status }: IMessageProductStatus,
  client: QueryClient,
  { page, is_dashboard, status, id, role }: IStatusMutation
) => {
  await onSuccess({ message });

  const promises = [];

  if (role === 'admin') {
    promises.push(
      client.invalidateQueries([
        APP_KEYS.QUERY_KEYS.GET_ALL_PRODUCTS_ADMIN,
        page,
        is_dashboard,
        status
      ])
    );
  }

  if (role === 'super_admin') {
    promises.push(
      client.invalidateQueries([
        APP_KEYS.QUERY_KEYS.GET_ALL_PRODUCTS_SUPER_ADMIN,
        page,
        is_dashboard,
        status
      ])
    );
  }

  if (id && _status) {
    promises.push(client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_PRODUCT, id]));
  }

  await Promise.allSettled(promises);
};

const put = (options: IStatusMutation, socket: any) => {
  const client = useQueryClient();
  return useMutation<any, AxiosError<IAuthError>, IProductEdit>(
    (data: IProductEdit) => productService.put(data),
    {
      onSuccess: async (data) => {
        await onSuccessStatus(data, client, options);
        socket.emit('update_item');
        await client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_PRODUCT, options.id]);
      },
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};

const status = (options: IStatusMutation, socket?: any) => {
  const client = useQueryClient();
  return useMutation<any, AxiosError<IAuthError>, IStatusChange>(
    (data: IStatusChange) => productService.status(data),
    {
      onSuccess: async (data: IMessageProductStatus) => {
        await onSuccessStatus(data, client, options);
        socket.emit('update_item');
      },
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};

const sold = (options: { id: Iuuid }, socket: any) => {
  const client = useQueryClient();
  return useMutation<any, AxiosError<IAuthError>, ISoldChange>(
    (data: ISoldChange) => productService.sold(data),
    {
      onSuccess: async ({ message }: IMessage) => {
        await client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_PRODUCT, options.id]);
        await onSuccess({ message });
        socket.emit('update_item');
      },
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};

const remove = () => {
  const client = useQueryClient();
  return useMutation<any, AxiosError<IAuthError>, Iuuid>(
    (data: Iuuid) => productService.remove(data),
    {
      onSuccess: async ({ message }: IMessage) => {
        await client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_ALL_PRODUCTS_SUPER_ADMIN]);
        await client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_ALL_PRODUCTS_ADMIN]);
        await onSuccess({ message });
      },
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};

export const useProductMutation = {
  post,
  put,
  status,
  sold,
  remove
};
