import { getIn, useFormikContext } from 'formik';
import React, { ChangeEvent, memo, useRef, useState } from 'react';

import { Icons } from '../../../../../assets';
import { COLORS } from '../../../../../theme';
import { FilePath } from '../../../../../utils';
import { useClickOutside } from '../../../hooks';
import { IconCommon } from '../../../styles';
import { IInputMatchedWordsProps } from '../../../types';
import { searchKeyPressControlScrol } from '../../../utils';
import { InputHint } from './input-hint';
import * as Styled from './input-matched-words.styled';

export const InputMatchedWords = memo(
  ({
    name,
    placeholder,
    matchedWords,
    isFilter = false,
    isFilterVisibleAllData = false,
    isChip = false,
    isDontChange = false,
    isInput = false,
    readOnly = false,
    readOnlyKeyboard = false,
    isEdit = false,
    isErrorVisible = false,
    width,
    height,
    label,
    innerPads,
    visibleItem,
    ...props
  }: IInputMatchedWordsProps) => {
    const {
      values,
      handleChange,
      setValues,
      setFieldValue,
      touched,
      errors,
      setFieldTouched,
      setFieldError
    } = useFormikContext();

    const value = getIn(values, name);
    const touche = getIn(touched, name);
    const error = getIn(errors, name);

    const isChipChecking = Array.isArray(value) || isChip;

    const [selectedHint, setSelectedHint] = useState<number | null>(null);

    const onTransfonmValue = (_value: string | any): string =>
      visibleItem && _value ? _value[visibleItem] : _value ?? '';

    const [input, setInput] = useState<string>(isEdit ? onTransfonmValue(value) ?? '' : '');
    const [focused, setFocused] = useState(false);
    const [isOnChange, setIsOnChange] = useState(false);

    const inputHintBlockRef = useRef<HTMLUListElement | null>(null);

    const isIncludesValue = (filterString: string, filterArray?: []) => {
      const array = filterArray ?? matchedWords;

      const filterData = array?.filter((v: any) => {
        const transfonmValue = onTransfonmValue(v);

        return transfonmValue?.toLowerCase()?.includes(filterString?.trim().toLowerCase());
      });

      return !!filterData.length;
    };

    const onSetTouched = (flag: boolean) => {
      if (!readOnly) {
        setFieldTouched(name, flag);

        setFocused(flag);

        flag && inputHintBlockRef.current && inputHintBlockRef.current.focus();
      }
    };

    const setValue = (str: string | any, ind: number) => {
      const _value = onTransfonmValue(str);
      onSetTouched(false);
      setIsOnChange(false);
      setSelectedHint(ind);
      isChipChecking || !isFilterVisibleAllData ? setInput('') : setInput(_value);

      isIncludesValue(_value) &&
        setValues((v: any) => {
          const prevValueName = getIn(v, name);
          const nextValueName = isChipChecking
            ? isIncludesValue(_value, prevValueName)
              ? [...prevValueName]
              : [...prevValueName, str]
            : str;

          return { ...v, [name]: nextValueName };
        });
    };

    const deleteItem = (srt: string) => {
      const nextValue = value?.filter((v: any) => {
        const transfonmValue = onTransfonmValue(v);
        const deleteValue = onTransfonmValue(srt);

        return transfonmValue !== deleteValue;
      });

      setFieldValue(name, nextValue);
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;

      setInput(inputValue);
      setIsOnChange(true);

      !isIncludesValue(inputValue) && setFieldError(name, 'No Options found');
    };

    const filterItem = isChipChecking || isFilter ? input : onTransfonmValue(value);
    const filterData = filterItem?.length
      ? matchedWords?.filter((v: any) => {
          const transfonmValue = onTransfonmValue(v);
          return transfonmValue?.toLowerCase().includes(filterItem.toLowerCase().trim());
        })
      : matchedWords;

    const data = isFilter  ? filterData : matchedWords;

    const pads = innerPads;

    const { ref } = useClickOutside(() => {
      if (focused) {
        setFocused(false);
      }
    });

    const isError = isErrorVisible ? !!error : !!error && touche;

    const _value =
      (isChipChecking || isFilter)  ? input : onTransfonmValue(value);

    return (
      <Styled.InputBlock width={width} focused={focused} readOnly={readOnly} ref={ref} {...props}>
        {label && (
          <Styled.Label isError={!focused && isError} htmlFor='avatar'>
            {label}
          </Styled.Label>
        )}
        <Styled.Input
          autoComplete='off'
          isError={isError}
          width={width}
          height={height}
          name={name}
          value={_value}
          id={name}
          innerPads={pads}
          readOnly={readOnly || readOnlyKeyboard}
          placeholder={placeholder}
          onClick={onSetTouched.bind(this, !focused)}
          onChange={(e) => {
            isChipChecking || isFilter ? onChange(e) : isDontChange ? () => {} : handleChange(e);
          }}
          onKeyDown={searchKeyPressControlScrol({
            selectedHint,
            setInputValue: setValue,
            setSelectedHint,
            matchedWords: data,
            ref: inputHintBlockRef
          })}
          touche={touche}
        />

        <Styled.Arrow
          focused={focused}
          isLabel={!!label}
          src={FilePath(Icons.arrowBottomIcon)}
          alt='arrowIcon'
        />

        {focused && data?.length > 0 && (
          <Styled.SuggestedBlock ref={inputHintBlockRef}>
            {data.map((str, ind) => {
              const str_value = onTransfonmValue(str);
              const selected = isChipChecking
                ? isIncludesValue(str_value, value)
                : str_value === onTransfonmValue(value) || ind === selectedHint;

              return (
                <InputHint
                  str={str_value}
                  key={`${ind}-${value}-${selectedHint}`}
                  setValue={() => setValue(str, ind)}
                  selected={selected}
                  isChip={isChipChecking}
                />
              );
            })}
          </Styled.SuggestedBlock>
        )}

        {isError && error !== 'is required' ? (
          <Styled.ErrorInfoContainer>
            <Styled.ErrorInfoText>{error}</Styled.ErrorInfoText>
          </Styled.ErrorInfoContainer>
        ) : null}

        {isChipChecking && !isInput && value?.length ? (
          <Styled.ChipContainer>
            {value.map((str: string, index: number) => (
              <Styled.Chip onClick={deleteItem.bind(this, str)} key={index}>
                {onTransfonmValue(str)}
                <IconCommon
                  height='0.625rem'
                  cursor='pointer'
                  icon={FilePath(Icons.closeIcon)}
                  background={COLORS.black}
                />
              </Styled.Chip>
            ))}
          </Styled.ChipContainer>
        ) : null}
      </Styled.InputBlock>
    );
  }
);
