import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import io from 'socket.io-client';

import { APP_KEYS } from '../constants';

let socket: any = null;

export const useConnectionSocket = ({ role }: { role?: string }): { socket: any } => {
  const client = useQueryClient();
  useEffect(() => {
    socket = io(process.env.REACT_APP_SERVER_SOCKET_URL ?? '', {
      transports: ['websocket', 'polling'],
      query: { role: role ?? 'test' }
    });

    socket.on('connect', () => console.log(`Client connected: ${socket.id}`));

    socket.on('update_item', () => {
      client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_ALL_PRODUCTS]).then();
      client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_ALL_PRODUCTS_MY]).then();
      client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_ALL_PRODUCTS_ADMIN]).then();
      client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_ALL_PRODUCTS_SUPER_ADMIN]).then();
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
    };
  }, []);

  return { socket };
};
