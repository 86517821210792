import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { Icons, Png } from '../../../../assets';
import { COLORS, SPACES } from '../../../../theme';
import { IGetOneUser } from '../../../../types';
import { FilePath } from '../../../../utils';
import { Drawer } from '../../../common/component';
import { APP_KEYS } from '../../../common/constants';
import { useClickOutside } from '../../../common/hooks';
import { DivCommon } from '../../../common/styles';
import { urlUtil } from '../../../common/utils';
import { UserCrud } from '../../../user-setting/component';
import { IAdminPage } from '../../types';
import * as Styled from './admin-page.styled';
import { UserName } from './admin-page.styled';

export const AdminPage = ({ children, user_id }: IAdminPage) => {
  const navigate = useNavigate();
  const client = useQueryClient();
  const { pathname } = useLocation();

  const [isVisible, setIsVisible] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const { ref } = useClickOutside(() => setIsVisible(false));

  const onNavigate = (to: string) => {
    navigate(to);
  };

  const onToggleDrawer = (flag: boolean) => {
    setOpenDrawer(flag);
  };

  const data = client.getQueryData<IGetOneUser | undefined>(APP_KEYS.QUERY_KEYS.GET_MAIN_USER);

  const onLogout = () => {
    client.removeQueries(APP_KEYS.QUERY_KEYS.GET_MAIN_USER);
    localStorage.removeItem(APP_KEYS.STORAGE_KEYS.TOKEN);
    onNavigate(APP_KEYS.ROUTER_KEYS.GUEST);
  };

  return (
    <Styled.Container fd='row' width='100%'>
      <DivCommon width='4.5rem' height='100%' jc='space-between'>
        <DivCommon padding={`${SPACES.xxxxl} 0 0 0`} jc='center' ai='center'>
          <Styled.Logo src={FilePath(Icons.logo)} alt='logo' />
        </DivCommon>
        <DivCommon jc='center' ai='center' gap={SPACES.xxxxl}>
          {[
            // {
            //   icon: FilePath(Icons.home),
            //   name: 'Inventory Dashboard',
            //   path: urlUtil(APP_KEYS.ROUTER_KEYS.INVENTORY_DASHBOARD)
            // },
            {
              icon: FilePath(Icons.inventory),
              name: 'Inventory Manager',
              path: urlUtil(APP_KEYS.ROUTER_KEYS.INVENTORY_PRODUCT)
            }
            // {
            //   icon: FilePath(Icons.action),
            //   name: 'Auction Tracker',
            //   path: urlUtil(APP_KEYS.ROUTER_KEYS.ACTION_TRACKER)
            // }
          ].map(({ icon, name, path }, index) => (
            <Styled.IconContainer
              key={index}
              className='tooltip'
              tooltipText={name}
              isActive={pathname === path}
              onClick={onNavigate.bind(this, path)}
            >
              <Styled.Icon cursor='pointer' icon={icon} />
            </Styled.IconContainer>
          ))}
        </DivCommon>
        {
          data?.user.role === 'viewer' ? (
            <DivCommon/>
          ): (
                <DivCommon padding={`0 0 ${SPACES.xxxxl} 0`} jc='center' ai='center'>
                  <Styled.IconContainer
                      isActive={urlUtil(APP_KEYS.ROUTER_KEYS.USER_SETTING) === pathname}
                      onClick={onNavigate.bind(this, urlUtil(APP_KEYS.ROUTER_KEYS.USER_SETTING))}
                  >
                    <Styled.Icon cursor='pointer' icon={FilePath(Icons.setting)} />
                  </Styled.IconContainer>
                </DivCommon>
            )
        }

      </DivCommon>

      <DivCommon width='calc(100% - 4.5rem)' height='100%' background={COLORS.white50}>
        <DivCommon ai='flex-end' padding={`${SPACES.xxxxxxls} ${SPACES.xxxxl}`}>
          <Styled.UserName onClick={() => setIsVisible(!isVisible)}>
            {data?.user.first_name} {data?.user.last_name}
            <img
              className={isVisible ? 'isVisible' : ''}
              src={FilePath(Icons.arrowBottomIcon)}
              alt='arrow'
            />
          </Styled.UserName>

          {isVisible && (
            <Styled.Setting ref={ref}>
              {[
                {
                  icon: FilePath(Icons.setting),
                  text: 'Account Settings',
                  onClick: onToggleDrawer.bind(this, true)
                },
                {
                  icon: FilePath(Icons.logaut),
                  text: 'Logout',
                  onClick: onLogout
                }
              ].map(({ icon, text, onClick }, index) => (
                <Styled.Div key={index}>
                  <Styled.SettingItem className='item' onClick={onClick}>
                    <Styled.Icon icon={icon} />
                    {text}
                  </Styled.SettingItem>
                  <Styled.Line className='line' />
                </Styled.Div>
              ))}
            </Styled.Setting>
          )}
        </DivCommon>

        <Styled.ContainerChildren width='100%' padding='0 7rem'>
          {children}
        </Styled.ContainerChildren>
      </DivCommon>

      <Drawer onClose={onToggleDrawer.bind(this, false)} open={openDrawer}>
        <UserCrud
          onClose={onToggleDrawer.bind(this, false)}
          isView
          isCreate={false}
          id={user_id}
          isEditUser
          isMainUser
        />
      </Drawer>
    </Styled.Container>
  );
};
