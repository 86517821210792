import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { toastContainer } from '../../module/common/component/toast';
import { APP_KEYS } from '../../module/common/constants';
import { IMessage, IToken } from '../../module/common/types';
import { authService } from '../../services';
import { IAuthError, ILogin, ISingUp } from '../../types';

const onError = (_err: AxiosError<IAuthError>, navigate: ReturnType<typeof useNavigate>) => {
  const err = _err.response?.data as IAuthError;
  toastContainer.error({ title: err.message ?? _err.message });

  if (err.errorCode === 124) {
    navigate(APP_KEYS.ROUTER_KEYS.AFTER_SING_UP);
  }
  if (err.errorCode === 125) {
    navigate(APP_KEYS.ROUTER_KEYS.SING_UP);
  }
};

const onSuccess = ({ message }: IMessage) => {
  toastContainer.success({ title: message });
};

export const useLogin = () => {
  const navigate = useNavigate();

  return useMutation<any, AxiosError<IAuthError>, ILogin>(
    (data: ILogin) => authService.login(data),
    {
      onSuccess: ({ token }: IToken) => {
        localStorage.setItem(APP_KEYS.STORAGE_KEYS.TOKEN, token);

        navigate(APP_KEYS.ROUTER_KEYS.HOME);
      },
      onError: (err: AxiosError<IAuthError>) => onError(err, navigate)
    }
  );
};

export const useSingUp = () => {
  const navigate = useNavigate();

  return useMutation<any, AxiosError<IAuthError>, ISingUp>(
    (data: ISingUp) => authService.singUp(data),
    {
      onSuccess: (data) => {
        onSuccess(data);
        navigate(APP_KEYS.ROUTER_KEYS.AFTER_SING_UP);
      },
      onError: (err: AxiosError<IAuthError>) => onError(err, navigate)
    }
  );
};
