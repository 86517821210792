import { useField } from 'formik';
import React, { forwardRef, useRef } from 'react';

import { useAutoResizeTextArea } from '../../../hooks';
import { IInputTextareaProps } from '../../../types';
import * as Styled from './input-text-area.styled';

export const InputTextArea = forwardRef<HTMLDivElement, IInputTextareaProps>(
  (
    {
      rows,
      value: propsValue,
      onChange,
      label,
      name,
      resizable = true,
      placeholder,
      margin,
      maxLength,
      readOnly,
      ...props
    },
    ref
  ) => {
    const returnData = () => {
      if (onChange && propsValue !== null && propsValue !== undefined) {
        return {
          value: propsValue,
          onChange: (e: any) => onChange(e.target.value),
          isError: false,
          error: ''
        };
      }

      const [field, { touched, error, value }] = useField(name);

      return {
        value,
        isError: !!error && touched,
        error,
        onChange: (e: any) => field.onChange(e)
      };
    };

    const { value, onChange: _onChange, error, isError } = returnData();

    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

    useAutoResizeTextArea(resizable ? textAreaRef.current : null, value, rows);

    return (
      <Styled.InputContainer margin={margin || ''} ref={ref} {...props}>
        {label && (
          <Styled.Label isError={false} htmlFor={name}>
            {label}
          </Styled.Label>
        )}

        <Styled.Input
          ref={resizable ? textAreaRef : null}
          isError={false}
          rows={rows}
          id={name}
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={readOnly}
          value={value}
          onChange={_onChange}
        />

        {maxLength ? (
          <Styled.MaxLength>
            <Styled.MaxLengthText>
              {value?.length}/{maxLength}
            </Styled.MaxLengthText>
          </Styled.MaxLength>
        ) : null}

        {isError && error !== 'is required' ? (
          <Styled.ErrorInfoContainer>
            <Styled.ErrorInfoText>{error}</Styled.ErrorInfoText>
          </Styled.ErrorInfoContainer>
        ) : null}
      </Styled.InputContainer>
    );
  }
);
