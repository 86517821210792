import * as Yup from 'yup';

import { Iuuid } from '../../common/types';
import { commonValidation, validateSchema } from '../../common/validation/validate-shema';

export const validationSchemaCompanyLocation = Yup.object().shape({
  country: validateSchema.text,
  city: validateSchema.text,
  state: validateSchema.text,
  zip: validateSchema.text
});

export const validationSchemaCompany = (id?: Iuuid) => {
  if (!id) {
    return Yup.object().shape({
      ...commonValidation,
      name: validateSchema.text,
      location: Yup.array()
        .of(validationSchemaCompanyLocation)
        .min(1, 'Minimum 1 row in the "Location" field')
    });
  }

  return Yup.object().shape({
    ...commonValidation,
    name: validateSchema.text,
    location: Yup.array()
      .of(validationSchemaCompanyLocation)
      .min(1, 'Minimum 1 row in the "Location" field')
  });
};

export const commonValidationCompany = {
  ...commonValidation,
  company_info: Yup.object().required('is required'),
  location: Yup.array()
    .of(validationSchemaCompanyLocation)
    .min(1, 'Minimum 1 row in the "Location" field'),
  role: validateSchema.text
};

export const validationSchemaUser = (isEdit: boolean, isView: boolean) => {
  if (isEdit) {
    return Yup.object().shape({
      ...commonValidationCompany,
      current_password: validateSchema.password,
      new_password: validateSchema.password,
      confirm_password: validateSchema.password.oneOf(
        [Yup.ref('confirm_password')],
        'The password confirmation does not match'
      )
    });
  } else if (isView) {
    return Yup.object().shape({
      ...commonValidationCompany,
      password: validateSchema.password
    });
  } else {
    return Yup.object().shape({
      ...commonValidationCompany
    });
  }
};
