import styled from 'styled-components';

import { COLORS, SPACES } from '../../../../theme';
import { DivCommon } from '../../../common/styles';

export const Container = styled(DivCommon)`
  height: 100vh !important;
`;

export const BodyDiv = styled(DivCommon)`
  width: 100%;
  height: 100%;

  padding: ${SPACES.l};

  overflow: hidden;
`;

export const Content = styled.div`
  width: 100%;
  height: fit-content;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0px;
    background-color: ${COLORS.black50};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.green};
  }
`;

export const Item = styled(DivCommon)`
  flex-direction: row;
  align-items: center;

  padding: ${SPACES.l};
  gap: ${SPACES.l};

  background: ${COLORS.white};
  margin-bottom: ${SPACES.s};
`;

export const ItemUserImage = styled.img`
  height: 2rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
`;

export const ImageContainer = styled.div`
  height: 3rem;
  aspect-ratio: 1/1;
  background: ${COLORS.green100};
  border-radius: 8px;
`;
