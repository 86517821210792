import React from 'react';
import { useNavigate } from 'react-router-dom';

import { COLORS, FONTS, SPACES } from '../../../../theme';
import { Button } from '../../../common/component';
import { APP_KEYS } from '../../../common/constants';
import { DivCommon, SubTitleCommon, TitleCommon } from '../../../common/styles';
import * as Styled from './warning.styled';

export const Warning = () => {
  const navigate = useNavigate();
  return (
    <DivCommon width='100%' height='100%' ai='center' jc='center'>
      <Styled.ContainerInfo background={COLORS.white}>
        <TitleCommon
          color={COLORS.black20}
          ta='center'
          mb={SPACES.xxxxl}
          fw={FONTS.WEIGHTS.semi_bold}
        >
          Warning
        </TitleCommon>
        <SubTitleCommon ml={SPACES.l} color={COLORS.black20} mb='4.5rem' ta='center'>
          Please open this on Mobile or Tablet version.
        </SubTitleCommon>

        <Button
          content='Return to Login'
          mt={SPACES.l}
          type='button'
          variant='inverse2'
          onClick={() => navigate(APP_KEYS.ROUTER_KEYS.LOGIN)}
        />
      </Styled.ContainerInfo>
    </DivCommon>
  );
};
