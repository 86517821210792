export const Location = ['Dallas', 'Oklahoma City', 'Raleigh'];

export const Category = [
  'Extraction CO2',
  'Extraction BHO',
  'Extraction Ethanol',
  'Cartridge Filler',
  'Vessel / Tank',
  'Solvent Recovery',
  'Trimmer',
  'HPLC',
  'Wiped Film',
  'Falling Film',
  'Short Path',
  'Chiller',
  'Dryign Equipment',
  'Laboratory Equipment',
  'Rosin Press',
  'Rotary Evaporator',
  'Packaging',
  'Cultivation / Grow Equipment',
  'Farm Equipment',
  'Glass Reactor',
  'Filter',
  'Freezer',
  'Vacuum Oven',
  'Sorter',
  'Other'
];

export const ManufacturerNames = [
  'Across International',
  'Agilent',
  'Buchi',
  'Capna',
  'Cascade Sciences',
  'Delta Seperations',
  'Green Broz',
  'Green Vault',
  'Heidolph',
  'Huber',
  'Julabo',
  'Lab 1st',
  'Labconco',
  'Munch Machines',
  'Other',
  'Pinnacle Stainless',
  'PolyScience',
  'Precision Stainless',
  'PuriFlash',
  'Pure Pressure',
  'Root Sciences',
  'Shimadzu',
  'SRI Instruments',
  'STM',
  'Summit Research',
  'TruSteel',
  'Twister',
  'United Science'
];
