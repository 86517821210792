import { APP_KEYS } from '../../common/constants';

export const urlUtil = (url: string) => {
  return `${APP_KEYS.ROUTER_KEYS.HOME}${url}`;
};

export const isPageExist = (page: string | undefined): boolean => {
  if (!page) return false;

  const pageWithRoot = `${page}`;

  const singleProductRegex = new RegExp(`^${urlUtil(APP_KEYS.ROUTER_KEYS.SINGLE_PRODUCT)}/\\d+$`);

  const doesExist =
    pageWithRoot === urlUtil(APP_KEYS.ROUTER_KEYS.INVENTORY_PRODUCT) ||
    pageWithRoot === urlUtil(APP_KEYS.ROUTER_KEYS.ACCOUNT_SETTING) ||
    singleProductRegex.test(pageWithRoot) ||
    pageWithRoot === urlUtil(APP_KEYS.ROUTER_KEYS.USER_SETTING);
  // pageWithRoot === urlUtil(APP_KEYS.ROUTER_KEYS.INVENTORY_DASHBOARD) ||
  // pageWithRoot === urlUtil(APP_KEYS.ROUTER_KEYS.ACTION_TRACKER) ||

  return doesExist;
};
