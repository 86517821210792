import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Icons } from '../../../../assets';
import { COLORS, SPACES } from '../../../../theme';
import { FilePath, IStatusTransform } from '../../../../utils';
import { useClickOutside } from '../../hooks';
import * as Styled from './navigation-component.styled';

type IArrayType = Omit<IStatusTransform, 'status' | 'background'> & {
  status?: string;
  background?: string;
};

export interface INavigationComponentButton {
  array: IArrayType[];
  isPage: string;
  setIsPage?: (value: any) => void;
  backgroundColor?: string;
}

export const NavigationComponentButton = ({
  array,
  backgroundColor,
  setIsPage,
  isPage
}: INavigationComponentButton) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onNavigate = (str?: string) => {
    if (setIsPage) {
      setIsPage(str);
    } else {
      navigate(`${location.pathname}?status=${str}`);
    }
  };

  return (
    <Styled.Container width='fit-content' fd='row' gap={SPACES.xxxxxxls}>
      {array.map(({ icon, text, background, status }, index) => (
        <Styled.SettingItem
          backgroundColor={backgroundColor ?? background}
          key={index}
          className='button'
          isActive={isPage === (status ?? text)}
          onClick={onNavigate.bind(this, status ?? text)}
        >
          <Styled.Icon icon={icon} className='icon' />
          {text}
        </Styled.SettingItem>
      ))}
    </Styled.Container>
  );
};

export interface INavigationComponentFilters {
  array: { icon: any; text: string; onClick: () => void }[] | any[];
  backgroundColor?: string;
}

export const NavigationComponentFilters = ({
  array,
  backgroundColor
}: INavigationComponentFilters) => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref } = useClickOutside(() => setIsVisible(false));

  return (
    <Styled.ContainerFilter
      width='fit-content'
      fd='row'
      jc='flex-end'
      gap={SPACES.xxxxxxls}
      anyStyled={Styled.DivCom}
    >
      {array.map(({ icon, text, onClick }, index) => (
        <Styled.SettingItem key={index + text} onClick={onClick} className='filter'>
          {text}
          <Styled.Icon height={SPACES.l} icon={icon} className='icon' />
        </Styled.SettingItem>
      ))}
      <Styled.Filters
        backgroundColor={backgroundColor}
        isVisible={isVisible}
        onClick={() => setIsVisible(!isVisible)}
      >
        <Styled.Icon
          className='icon'
          icon={FilePath(Icons.filterIcon)}
          background={backgroundColor ?? COLORS.green}
        />
      </Styled.Filters>

      {isVisible && (
        <Styled.Setting ref={ref}>
          {array.map(({ icon, text, onClick }, index) => (
            <Styled.Div key={index}>
              <Styled.SettingItem2 className='item' onClick={onClick}>
                <Styled.Icon icon={icon} height='1rem' />
                {text}
              </Styled.SettingItem2>
              <Styled.Line className='line' />
            </Styled.Div>
          ))}
        </Styled.Setting>
      )}
    </Styled.ContainerFilter>
  );
};
