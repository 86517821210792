import styled, { css } from 'styled-components';

import { COLORS, FONTS, MEDIA, SHADOWS, SPACES } from '../../../../theme';
import { Center, CommonSettingNavigationItem, DivCommon, Fonts, IconCommon } from '../../styles';

const HoverAndActive = css<{ backgroundColor?: string }>`
  background: ${({ backgroundColor }) => backgroundColor ?? COLORS.green};
`;

export const SettingItem = styled(CommonSettingNavigationItem)<{ backgroundColor?: string }>`
  &.button {
    &:hover {
      ${HoverAndActive};
    }

    ${({ isActive }) => isActive && HoverAndActive};
  }

  &.filter {
    @media screen and (max-width: ${MEDIA.tablet}) {
      display: none;
    }
  }
`;

export const Container = styled(DivCommon)`
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.1px;
    height: 0.1px;
    background-color: ${COLORS.green};
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.orange};
  }
`;
export const ContainerFilter = styled(DivCommon)`
  min-width: 20%;
`;

export const Filters = styled.div<{ isVisible: boolean; backgroundColor?: string }>`
  display: none;

  height: 2.5rem;
  aspect-ratio: 1/1;
  border: 1px solid ${({ backgroundColor }) => backgroundColor ?? COLORS.green};
  border-radius: 12px;

  ${({ isVisible }) =>
    isVisible &&
    css<{ backgroundColor?: string }>`
      background: ${({ backgroundColor }) => backgroundColor ?? COLORS.green};
      & > .icon {
        background: ${COLORS.white};
      }
    `}

  @media screen and (max-width: ${MEDIA.tablet}) {
    display: flex;
    ${Center};
  }
`;

export const Icon = styled(IconCommon)``;

export const DivCom = css`
  position: relative;
`;

export const Setting = styled(DivCommon)`
  min-width: 13rem;

  margin-top: ${SPACES.m};
  padding: ${SPACES.xxsm} ${SPACES.s};

  border-radius: ${SPACES.s};
  background: ${COLORS.white};
  box-shadow: ${SHADOWS.xxs};

  position: absolute;
  top: 2.8rem;
  right: 0;
  z-index: 3;

  &::before {
    content: '';
    position: absolute;
    top: -15px;
    right: 10px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent ${COLORS.white} transparent;
  }
`;

export const Div = styled.div`
  &:first-child {
    & .item {
      border-radius: 8px 8px 0 0;
    }
  }

  &:last-child {
    & .item {
      border-radius: 0 0 8px 8px;
    }

    & .line {
      display: none;
    }
  }
`;

export const SettingItem2 = styled.div`
  display: flex;

  cursor: pointer;

  gap: ${SPACES.s};
  padding: ${SPACES.s} ${SPACES.xxs};

  display: flex;
  align-items: center;

  ${Fonts};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};

  cursor: pointer;

  &:hover {
    background: ${COLORS.black200};
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 2px;
  background: ${COLORS.black250};
`;
