import { FieldArray, Form, Formik, getIn, useFormikContext } from 'formik';
import React, { useState } from 'react';

import { Icons } from '../../../../assets';
import { useCompanyMutation, useCompanyQuery } from '../../../../hooks';
import { COLORS, FONTS, SPACES } from '../../../../theme';
import { ICompany } from '../../../../types';
import { FilePath } from '../../../../utils';
import {
  Button,
  Input,
  InputContainer,
  InputMatchedWords,
  InputMatchedWordsDynamic,
  Loader,
  Loading,
  PopupLayout,
  Portal
} from '../../../common/component';
import { Country, InputsConst, State } from '../../../common/constants';
import { DivCommon, TitleCommon } from '../../../common/styles';
import { Iuuid } from '../../../common/types';
import { validationSchemaCompany, validationSchemaCompanyLocation } from '../../validation/shema';
import * as Styled from './company-crud.styled';

export interface ICompanyCrud {
  onClose: () => void;
  isView?: boolean;
  id?: Iuuid;
}

export const CompanyCrud = ({ onClose, isView = false, id }: ICompanyCrud) => {
  const { post, put, deleteCompany } = useCompanyMutation;
  const { findOne } = useCompanyQuery;

  const { isLoading: isLoadingPost, mutate: mutatePost } = post();
  const { isLoading: isLoadingPut, mutate: mutatePut } = put(id);
  const { isLoading: isLoadingDelete, mutate: mutateDelete } = deleteCompany(id);
  const { isLoading: isLoadingData, data } = findOne(id);

  const isLoading = isLoadingPost || isLoadingPut || isLoadingDelete;

  const [isEdit, setEdit] = useState(!isView);
  const [isDelete, setIsDelete] = useState(false);
  const [newLocation, setNewLocation] = useState(false);

  const toggleIsDelete = (flag?: boolean) => {
    setIsDelete(flag ?? false);
  };

  const toggleIsLocation = (flag?: boolean) => {
    setNewLocation(flag ?? false);
  };

  const onSubmitAddLocation = (data?: any, push?: any) => {
    if (data.country_other && data.country === 'Other') {
      data.country = data.country_other;
    }

    delete data.country_other;

    push(data);

    toggleIsLocation();
  };

  const onDelete = () => {
    toggleIsDelete();
    onClose();
    mutateDelete();
  };

  const onSubmit = (_data: ICompany) => {
    if (id) {
      delete _data?.password;
    }
    id ? mutatePut(_data) : mutatePost(_data);

    onClose();
  };

  return (
    <Styled.Container>
      {isLoadingData ? <Loading className='full-screen' /> : null}

      <DivCommon fd='row' ai='center'>
        <TitleCommon fw={FONTS.WEIGHTS.semi_bold}>Company Settings</TitleCommon>
        <Styled.CloseBtn onClick={onClose} />
      </DivCommon>

      <Formik
        initialValues={{
          first_name: isView ? data?.company.first_name ?? '' : '',
          last_name: isView ? data?.company.last_name ?? '' : '',
          email: isView ? data?.company.email ?? '' : '',
          phone: isView ? data?.company.phone ?? '' : '',
          name: isView ? data?.company.name ?? '' : '',
          location: isView ? data?.company.location ?? [] : []
        }}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={validationSchemaCompany(id)}
      >
        {({ isValid, values }) => (
          <Form>
            <TitleCommon mt={SPACES.xxxxl} mb={SPACES.l}>
              Primary Contact{' '}
            </TitleCommon>
            <Styled.Line />

            <InputContainer readOnly={!isEdit} />
            {/*{!id ? (<Input*/}
            {/*    mt={SPACES.xxxxl}*/}
            {/*    readOnly={!isEdit}*/}
            {/*    type="password"*/}
            {/*    height="3.5rem"*/}
            {/*    {...InputsConst.password}*/}
            {/*/>) : null}*/}

            <TitleCommon mt={SPACES.xxxxl} mb={SPACES.l}>
              Company Information
            </TitleCommon>
            <Styled.Line />

            <Input
              mt={SPACES.xxxxl}
              readOnly={!isEdit}
              height='3.5rem'
              {...InputsConst.company_info}
              name='name'
            />

            {/*<InputMatchedWordsDynamic*/}
            {/*    {...InputsConst.location}*/}
            {/*    readOnly={!isEdit}*/}
            {/*    height="3.5rem"*/}
            {/*    mt={SPACES.xxxxl}*/}
            {/*/>*/}

            {/*<Button*/}
            {/*    mt={SPACES.xxxxl}*/}
            {/*    content="Add location"*/}
            {/*    type="button"*/}
            {/*    variant="primary"*/}
            {/*    onClick={toggleIsLocation.bind(this, true)}*/}
            {/*/>*/}

            <Styled.LocationContainer>
              <div className='header'>
                <span>Company location</span>
                <img
                  onClick={toggleIsLocation.bind(this, true)}
                  src={FilePath(Icons.plusIcon)}
                  alt='plas'
                />
              </div>

              <Styled.UlLocation>
                {values.location.length
                  ? values.location.map((v: any, i: number) => (
                      <div key={i}>
                        <div>Country: {v?.country}</div>
                        <div>City: {v?.city}</div>
                        <div>State: {v?.state}</div>
                        <div>Zip: {v?.zip}</div>
                        <p></p>
                      </div>
                    ))
                  : null}
              </Styled.UlLocation>
            </Styled.LocationContainer>

            <FieldArray name='location'>
              {({ push }) => (
                <div>
                  {newLocation ? (
                    <Portal>
                      <Formik
                        initialValues={{
                          country: '',
                          state: '',
                          city: '',
                          zip: ''
                        }}
                        onSubmit={(data: any) => {
                          onSubmitAddLocation(data, push);
                        }}
                        enableReinitialize
                        validationSchema={validationSchemaCompanyLocation}
                      >
                        {({ isValid: _isValid, values }) => (
                          <Form>
                            <PopupLayout
                              onClose={toggleIsLocation.bind(this, false)}
                              width={'500px'}
                            >
                              <TitleCommon>Add new location</TitleCommon>
                              <DivCommon height='400px' className='scroll'>
                                {/*<Input mt={SPACES.xxxxl}*/}
                                {/*       height="3.5rem"*/}
                                {/*       {...InputsConst.name}*/}
                                {/*       label='Location Title'*/}
                                {/*       placeholder='Location Title'*/}
                                {/*/>*/}

                                <InputMatchedWords
                                  matchedWords={Country}
                                  height='3.5rem'
                                  mt={SPACES.xxxxl}
                                  isFilter
                                  isEdit
                                  isFilterVisibleAllData
                                  readOnlyKeyboard
                                  {...InputsConst.country}
                                />

                                {values?.country === 'Other' && (
                                  <Input
                                    mt={SPACES.xxxxl}
                                    height='3.5rem'
                                    {...InputsConst.country_other}
                                  />
                                )}

                                {values?.country !== 'Other' && (
                                  <InputMatchedWords
                                    matchedWords={State[values?.country]}
                                    height='3.5rem'
                                    mt={SPACES.xxxxl}
                                    isEdit
                                    isFilter
                                    isFilterVisibleAllData
                                    readOnlyKeyboard
                                    {...InputsConst.state}
                                  />
                                )}

                                {(
                                  [
                                    ...(values?.country !== 'Other' ? [] : ['state']),
                                    'city'
                                  ] as (keyof typeof InputsConst)[]
                                ).map((name, index) => (
                                  <Input
                                    key={index}
                                    mt={SPACES.xxxxl}
                                    height='3.5rem'
                                    {...InputsConst[name]}
                                  />
                                ))}

                                <Input
                                  mt={SPACES.xxxxl}
                                  height='3.5rem'
                                  {...InputsConst.zip}
                                  type='number'
                                />

                                <Button
                                  mt={SPACES.xxxxl}
                                  content='Add location'
                                  type='submit'
                                  variant='primary'
                                  disabled={!_isValid}
                                />
                              </DivCommon>
                            </PopupLayout>
                          </Form>
                        )}
                      </Formik>
                    </Portal>
                  ) : null}
                </div>
              )}
            </FieldArray>

            {!isView && (
              <Button
                mt={SPACES.xxxxl}
                content={
                  !isLoading ? (
                    'Create Company'
                  ) : (
                    <Loader size='small' color={COLORS.green} height='auto' />
                  )
                }
                type='submit'
                variant='primary'
                disabled={!isValid || isLoading}
              />
            )}

            {isView && !isEdit && (
              <Button
                mt={SPACES.xxxxl}
                content='Edit Company'
                type='button'
                variant='primary'
                onClick={() => setEdit(true)}
              />
            )}

            {isView && isEdit && (
              <>
                <Button
                  mt={SPACES.xxxxl}
                  content={
                    !isLoading ? (
                      'Save Changes'
                    ) : (
                      <Loader size='small' color={COLORS.green} height='auto' />
                    )
                  }
                  type='submit'
                  variant='primary'
                  disabled={!isValid || isLoading}
                />
                <Button
                  mt={SPACES.xxxxl}
                  content='Cancel'
                  type='button'
                  variant='inverse2'
                  onClick={() => setEdit(false)}
                />

                <Button
                  mt={SPACES.xxxxl}
                  content='Delete Company'
                  type='button'
                  variant='delete'
                  startIcon={FilePath(Icons.deleteIcon)}
                  widthIcon='1.2rem'
                  background={COLORS.mainRed}
                  onClick={toggleIsDelete.bind(this, true)}
                />
              </>
            )}
          </Form>
        )}
      </Formik>

      {isDelete ? (
        <Portal>
          <PopupLayout onClose={toggleIsDelete.bind(this, false)}>
            <TitleCommon>Are you sure</TitleCommon>
            <DivCommon>
              <Button
                mt={SPACES.xxxxl}
                content='Yes'
                type='button'
                variant='delete'
                onClick={onDelete}
              />
              <Button content='Cancel' type='button' variant='inverse2' onClick={toggleIsDelete} />
            </DivCommon>
          </PopupLayout>
        </Portal>
      ) : null}
    </Styled.Container>
  );
};
