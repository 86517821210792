import React from 'react';

import { IUsersResponse } from '../../../types';
import { IHomeLayoutContextProviderProps } from '../types';

export type AppContextValue = {
  user: IUsersResponse;
  setUser: React.Dispatch<React.SetStateAction<IUsersResponse | {}>>;
};

// @ts-ignore
export const HomeLayoutContext = React.createContext<AppContextValue>();

export const HomeLayoutContextProvider = ({ children }: IHomeLayoutContextProviderProps) => {
  const [user, setUser] = React.useState<IUsersResponse | any>({});

  const navBarLayoutContextProviderValue = React.useMemo(
    () => ({
      user,
      setUser
    }),
    [user]
  );

  return (
    <HomeLayoutContext.Provider value={navBarLayoutContextProviderValue}>
      {children}
    </HomeLayoutContext.Provider>
  );
};
