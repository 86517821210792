import { Form } from 'formik';
import styled from 'styled-components';

import { COLORS, SHADOWS, SPACES } from '../../../../theme';
import { DivCommon, Fonts, IconCommon } from '../../../common/styles';

export const Container = styled(DivCommon)`
  height: 100dvh;
  position: fixed;
  flex: 1;
`;

export const ContainerContent = styled(DivCommon)`
  width: 100%;
  height: 100%;
  align-items: center;
  position: fixed;
`;

export const Icon = styled(IconCommon)`
  height: 0.875rem;
  background: ${COLORS.green};
  cursor: pointer;
`;

export const HeaderDiv = styled(DivCommon)`
  width: 90%;
  padding-top: ${SPACES.l};
  margin-bottom: ${SPACES.xs};
  height: 10%;
`;

export const _Form = styled(Form)`
  &::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0;
    background-color: ${COLORS.black50};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.green};
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export const BodyDiv = styled(DivCommon)`
  max-width: 22.5rem !important;
  width: 22.5rem;
  height: 100%;

  padding-bottom: ${SPACES.xxxxl};
  margin: 0 auto;

  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0;
    background-color: ${COLORS.black50};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.green};
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export const Test = styled.div`
  background-color: ${COLORS.green};
`;

export const ContainerInfo = styled(DivCommon)`
  width: 100%;
  height: fit-content;

  border-radius: 12px;

  margin: auto 0;

  background-color: ${COLORS.white};
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  box-shadow: ${SHADOWS.l};
  padding: 2.5rem ${SPACES.xxxxl};
`;

export const GoBack = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: absolute;

  top: 0.875rem;
  left: 0.875rem;
  ${Fonts};
  color: ${COLORS.green};
  cursor: pointer;
`;

export const BottomDiv = styled(DivCommon)`
  width: 100%;
  height: 12%;
  max-height: 6.25rem;
  background-color: ${COLORS.white};
`;
