import { EnhancedWithAuthHttpService, HttpFactoryService } from '../module/common/services';
import { ExpectedFromQuery, IMessage, Iuuid } from '../module/common/types';
import { ICompany, IGetAllCompanies, IGetAllFilters, IGetOneCompanies } from '../types';

class CompanyService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}

  async all(data: IGetAllFilters): Promise<ExpectedFromQuery<IGetAllCompanies>> {
    return this.httpService.post<IGetAllCompanies, IGetAllFilters>('/company/all', data);
  }

  async findOne(id?: Iuuid): Promise<ExpectedFromQuery<IGetOneCompanies>> {
    return this.httpService.get<IGetOneCompanies>(`/company/${id}`, {});
  }

  async post(data: ICompany): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.post<IMessage, ICompany>('/company', data);
  }

  async put(data: ICompany, id?: Iuuid): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.put<IMessage, Partial<ICompany>>(`/company/${id}`, data);
  }

  async delete(id?: Iuuid): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.delete<IMessage>(`/company/${id}`);
  }
}

const factory = new HttpFactoryService();
export const companyService = new CompanyService(factory.createAuthHttpService());
