import jwt_decode from 'jwt-decode';

import { IJwtDecode } from '../types';

export interface IGetToken {
  token: string;
  isToken: boolean;
  decoded?: IJwtDecode;
}

export type TokenReturnType<T extends string> = T extends `${infer R}_TOKEN` | `${infer R}_token`
  ? IGetToken
  : T extends 'TOKEN' | 'token'
  ? IGetToken
  : { value: string };

export const useLocalStorage = () => {
  const get = <T extends string>(key: T): TokenReturnType<T> => {
    const data = localStorage.getItem(key);

    if (key.toLowerCase().includes('token')) {
      const isToken = !!data;

      let decoded = {};

      if (isToken) {
        decoded = jwt_decode<IJwtDecode>(data ?? '');
      }

      return { token: data as string, isToken, decoded } as TokenReturnType<T>;
    }

    return { value: data } as TokenReturnType<T>;
  };

  const set = (key: string, value: string) => {
    localStorage.setItem(key, value);
  };

  const remove = (key: string) => {
    localStorage.removeItem(key);
  };

  const clear = (key: string, value: string) => {
    localStorage.clear();
  };

  return { get, set, clear, remove };
};
