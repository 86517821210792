import { getIn } from 'formik';
import React from 'react';

import { COLORS, FONTS, SPACES } from '../../../../theme';
import { IImage } from '../../../../types';
import { InputsConst } from '../../../common/constants';
import { DivCommon, SubTitleCommon, TitleCommon } from '../../../common/styles';
import { IStepEnd } from '../../types';
import * as Styled2 from '../step-four/step-four.styled';
import * as Styled from './step-end.styled';

export const StepEnd = ({ values, inputs, onEdit, title }: IStepEnd) => (
  <Styled.Container width='100%' background={COLORS.white} margin={`0 0 ${SPACES.xl} 0`}>
    <DivCommon fd='row' jc='space-between' margin={`0 0 ${SPACES.xxs} 0`}>
      <TitleCommon
        fs={FONTS.SIZES.l}
        lh={FONTS.SIZES.xxl}
        color={COLORS.black20}
        fw={FONTS.WEIGHTS.semi_bold}
      >
        {title}
      </TitleCommon>
      <SubTitleCommon onClick={onEdit} cursor='pointer' color={COLORS.green}>
        Edit
      </SubTitleCommon>
    </DivCommon>

    {inputs
      ? (inputs as (keyof typeof InputsConst)[]).map((name, index) => {
          const value = getIn(values, name);
          const value2 = getIn(values, `user_info.${name}`);
          const text =
            (name === 'files' &&
              value.map((v: any, i: number) => (
                <Styled.FileLine key={index + i + v.name}>{v.name}</Styled.FileLine>
              ))) ||
            (name === 'company_info' && getIn(values, 'company_info').name) ||
            (name === 'admin_user_info' && getIn(values, 'admin_user_info').email) ||
            // (name === 'category_other' || getIn(values, 'category') === 'Other') ?  '' : '' ||
            // (name === 'manufacturer_mame_other' || getIn(values, 'manufacturer_mame') === 'Other') ? '' : '' ||
            // (name === 'country_other' || getIn(values, 'country') === 'Other') ? '' : value || value2 ||
            value ||
            value2;

          return text ? (
            <DivCommon key={index} margin={`${SPACES.xxs} 0 0  0`}>
              <TitleCommon
                ff={FONTS.FAMILIES.source_sans_pro}
                fs={FONTS.SIZES.l}
                lh={FONTS.SIZES.xxl}
                color={COLORS.black20}
                fw={FONTS.WEIGHTS.normal}
              >
                {InputsConst[name].label}
              </TitleCommon>
              <SubTitleCommon color={COLORS.black30}>{text}</SubTitleCommon>
            </DivCommon>
          ) : null;
        })
      : null}

    {title === 'Photos' ? (
      <Styled2.Container>
        {getIn(values, 'images').map(
          ({ path, is_manufacturer_tag, is_primary_photo }: IImage, index: number) => (
            <Styled2.Image2
              is_manufacturer_tag={is_manufacturer_tag}
              is_primary_photo={is_primary_photo}
              key={index}
              image={path}
            />
          )
        )}
      </Styled2.Container>
    ) : null}
  </Styled.Container>
);
