import React, { useCallback, useEffect, useState } from 'react';

import { Png } from '../../../../assets';
import { COLORS, SPACES } from '../../../../theme';
import { fileService } from '../../services';
import { DivCommon, SubTitleCommon } from '../../styles';
import { Button } from '../buttons';
import * as Styled from './camera.styled';

export interface ICamera {
  onClose: () => void;
  onSuccess: (images?: any) => void;
  images: any[];
  setImages: any;
  isSingle?: boolean;
  setSingleImage?: any;
}

export const Camera = ({
  onClose,
  onSuccess,
  images,
  setImages,
  isSingle = false,
  setSingleImage
}: ICamera) => {
  const maxImages = isSingle ? 1 : 9;

  const [facingMode, setFacingMode] = useState('user');
  const [webcamRef, setWebcamRef] = useState(null);
  const [clientWidth, setClientWidth] = useState('100%');
  const [image, setImage] = useState(null);

  const [windowSize, setWindowSize] = useState<{
    width: undefined | number;
    height: undefined | number;
  }>({
    width: undefined,
    height: undefined
  });

  const cleanupCamera = useCallback(async () => {
    // @ts-ignore
    if (webcamRef && webcamRef.srcObject) {
      // @ts-ignore
      const stream = webcamRef.srcObject as MediaStream;
      const tracks = stream.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });

      // @ts-ignore
      webcamRef.srcObject = null;
    }
  }, [webcamRef]);

  const initCamera = useCallback(async () => {
    try {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode } });
        if (webcamRef) {
          // @ts-ignore
          webcamRef.srcObject = stream;
        }
      } else {
        throw new Error('getUserMedia is not supported in this browser.');
      }
    } catch (error) {
      throw new Error(`Error accessing camera:${error}`);
    }
  }, [facingMode, webcamRef]);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state

      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  useEffect(() => {
    initCamera();

    // Cleanup camera when the component unmounts
    return () => {
      cleanupCamera();
    };
  }, []);

  const switchCamera = () => {
    setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
    initCamera();
  };

  const capturePhoto = () => {
    if (webcamRef) {
      // @ts-ignore
      const imageSrc = webcamRef.getScreenshot();
      if (imageSrc) {
        setImage(imageSrc);
      }
    }
  };

  const onUsePhoto = () => {
    if (isSingle) {
      setSingleImage(image);
      setImage(null);
      onClose();
    } else {
      setImage(null);
      // @ts-ignore
      images.length <= maxImages && setImages((prev) => [...prev, image]);
    }
  };

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputFiles = e.target.files;

    if (!inputFiles) {
      // Handle the case when inputFiles is null
      return;
    }

    const files: string[] = [];

    // Create an array of promises for converting files
    const filePromises = Array.from(inputFiles).map(async (file) => {
      // Check for file limit
      if (images.length < maxImages) {
        const fileBase64 = await fileService.convertBase64(file);
        files.push(fileBase64);
      }
    });

    await Promise.all(filePromises);

    if (isSingle) {
      setSingleImage(files[0]);
      onClose();
    } else {
      const filesToAdd = files.slice(0, maxImages + 1 - images.length);

      setImages((prevValue: any) => [...prevValue, ...filesToAdd]);
    }
  };

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode,
    frameRate: 30
  };

  return (
    <Styled.Container>
      {!image && (
        <DivCommon height='100%'>
          <Styled.DivHeaderButton>
            <Styled.ToglCamera onClick={switchCamera}>
              <Styled.CameraswitchIconStyled />
            </Styled.ToglCamera>
            <SubTitleCommon color={COLORS.white}>
              {isSingle ? `Photo ${image ? 1 : 0}/1` : `Photos ${images.length}/10`}{' '}
            </SubTitleCommon>
          </Styled.DivHeaderButton>

          <Styled.WebcamTest
            mirrored={facingMode === 'user'}
            minScreenshotHeight={windowSize.height}
            minScreenshotWidth={windowSize.width}
            height={windowSize.height ?? 0}
            width={windowSize.width ?? 0}
            videoConstraints={videoConstraints}
            screenshotQuality={1}
            screenshotFormat='image/png'
            audio={false}
            ref={(webcam: any) => setWebcamRef(webcam)}
            style={{
              textAlign: 'center',
              height: '80%',
              width: '100%',
              objectFit: 'contain'
            }}
          />

          <Styled.Div>
            <Styled.DivTakePhoto>
              <Styled.InputContainer>
                <Button content='Upload' width='fit-content' />
                <input type='file' accept='image/*' multiple={!isSingle} onChange={onChange} />
              </Styled.InputContainer>
              <Styled.TakeImag onClick={capturePhoto} />
            </Styled.DivTakePhoto>

            <Styled.DivFutterButton>
              <SubTitleCommon onClick={onClose} cursor='pointer' color={COLORS.white}>
                Cancel
              </SubTitleCommon>
              <SubTitleCommon
                onClick={isSingle || !images.length ? () => {} : onSuccess.bind(this, images)}
                cursor={images.length ? 'pointer' : 'text'}
                color={COLORS.white}
                style={!images.length ? { opacity: '0.2' } : {}}
              >
                Save Photo{isSingle ? '' : 's'}
              </SubTitleCommon>
            </Styled.DivFutterButton>
          </Styled.Div>
        </DivCommon>
      )}

      {image && (
        <DivCommon height='100%'>
          <Styled.DivHeaderButton />
          <Styled.Image src={image} width={clientWidth} alt='image' />

          <DivCommon width='100%' ai='center'>
            <Styled.Button1
              width='90%'
              mt={SPACES.l}
              content='Retake'
              variant='delete'
              type='button'
              onClick={() => setImage(null)}
            />
            <Styled.Button2
              width='90%'
              mt={SPACES.l}
              content='Use Photo'
              variant='inverse2'
              type='button'
              onClick={onUsePhoto}
            />
          </DivCommon>
        </DivCommon>
      )}
    </Styled.Container>
  );
};
