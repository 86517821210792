import { UseQueryResult, useQuery, useQueryClient } from 'react-query';

import { APP_KEYS } from '../../module/common/constants';
import { Iuuid } from '../../module/common/types';
import { userService } from '../../services';
import { IGetAllFiltersUsers, IGetAllUsers, IGetAllUsersAndGroup, IGetOneUser } from '../../types';

const all = (data: IGetAllFiltersUsers, options: any): UseQueryResult<IGetAllUsers | undefined> =>
  useQuery(
    [APP_KEYS.QUERY_KEYS.GET_ALL_USERS, data.company_name, data],
    () => userService.all(data),
    {
      ...options
    }
  );
const all_and_group = (): UseQueryResult<IGetAllUsersAndGroup | undefined> =>
  useQuery([APP_KEYS.QUERY_KEYS.GET_USER_GROUP], () => userService.all_and_group());

const findOne = (id?: Iuuid | null, isEnabled = true): UseQueryResult<IGetOneUser | undefined> =>
  useQuery([APP_KEYS.QUERY_KEYS.GET_USER, id], () => userService.findOne(id), {
    ...(isEnabled && { enabled: !!id })
  });

const findOneCacheMainUser = (): IGetOneUser | undefined => {
  const client = useQueryClient();

  return client.getQueryData<IGetOneUser | undefined>(APP_KEYS.QUERY_KEYS.GET_MAIN_USER);
};

export const useUserQuery = {
  all,
  findOne,
  all_and_group,
  findOneCacheMainUser
};
