// Local storage keys
import {useManufacturersMutation} from "../../../hooks/manufacturers";

export const STORAGE_KEYS = {
  TOKEN: 'TOKEN',
  USER: 'USER',
  USER_EMAIL: 'USER_EMAIL'
};

export const ROUTER_KEYS = {
  HOME: '/inventory',
  INVENTORY_DASHBOARD: '/dashboard',
  INVENTORY_PRODUCT: '/manager',
  SINGLE_PRODUCT: '/single-product',
  ACTION_TRACKER: '/auction-tracker',
  ACCOUNT_SETTING: '/account-setting',
  USER_SETTING: '/user-setting',

  LOGIN: '/login',
  GUEST: '/',
  AFTER_SING_UP: '/after-sing-up',
  GUEST_AFTER_ITEM: '/guest-after-item',
  // CREATE_ITEM_PUBLIC: '/create-product-public',
  CREATE_ITEM_PUBLIC: '/create-item',
  SING_UP: '/sing-up',
  FORGOT_PASSWORD: '/forgot-password'
};

export const QUERY_KEYS = {
  GET_COMPANY: 'get-сompany',
  GET_USER: 'get-user',
  GET_USER_GROUP: 'get-user-croup',
  GET_PRODUCT: 'get-product',
  GET_MAIN_USER: 'get-main-user',

  GET_ALL_USERS: 'get-all-users',
  GET_ALL_GUESTS: 'get-all-guests',
  GET_ALL_MANUFACTURES: 'get-all-manufacturers',
  GET_ALL_CATEGORY: 'get-all-category',
  GET_ALL_PRODUCTS: 'get-all-products',
  GET_ALL_PRODUCTS_MY: 'get-all-products-my',
  GET_ALL_COMPANIES: 'get-all-companies',

  GET_ALL_PRODUCTS_ADMIN: 'get-all-products-admin',
  GET_ALL_PRODUCTS_SUPER_ADMIN: 'get-all-products-super-admin'
};
