import { getIn, useFormikContext } from 'formik';
import React from 'react';

import { IInputMask } from '../../../types';
import * as Styled from './input-mask.styled';

export const InputMask: React.FC<IInputMask> = ({
  mask,
  label,
  name,
  required,
  isOptional,
  placeholder,
  readOnly,
  width,
  isHidePhone = false,
  ...props
}) => {
  const { values, errors, touched, setFieldValue } = useFormikContext();
  const value = getIn(values, name);
  const error = getIn(errors, name);
  const touch = getIn(touched, name);

  const value2 = isHidePhone ? (getIn(values, 'is_hide_phone') ? value : '***-***-****') : value;

  function handleOnChange(str: string) {
    setFieldValue(name, str);
  }

  return (
    <Styled.OptionBlock readOnly={readOnly} width={width} {...props}>
      <Styled.Label isError={!!error && touch} required={required} width={width}>
        {label}
        {isOptional && <Styled.LabelOptional>· Optional</Styled.LabelOptional>}
      </Styled.Label>
      <Styled.StyledMaskedInput
        isError={!!error && touch}
        name={name}
        maskChar='*'
        mask={mask}
        readOnly={readOnly}
        placeholder={placeholder}
        value={value2}
        onChange={(e) => handleOnChange(e.target.value)}
        {...props}
      />

      {touch && error && error !== 'is required' && <Styled.Error>{error}</Styled.Error>}
    </Styled.OptionBlock>
  );
};
