import { UseQueryResult, useQuery } from 'react-query';

import { APP_KEYS } from '../../module/common/constants';
import { Iuuid } from '../../module/common/types';
import { companyService } from '../../services';
import { IGetAllCompanies, IGetOneCompanies } from '../../types';

interface IAll {
  page?: number;
  isCompanyUser?: boolean;
}

const all = (
  { page, isCompanyUser }: IAll,
  options?: any
): UseQueryResult<IGetAllCompanies | undefined> =>
  useQuery(
    [APP_KEYS.QUERY_KEYS.GET_ALL_COMPANIES, isCompanyUser],
    () => companyService.all({ page, isCompanyUser }),
    {
      ...options
    }
  );

const findOne = (id?: Iuuid): UseQueryResult<IGetOneCompanies | undefined> =>
  useQuery([APP_KEYS.QUERY_KEYS.GET_COMPANY, id], () => companyService.findOne(id), {
    enabled: !!id
  });

export const useCompanyQuery = {
  all,
  findOne
};
