import { getIn, useFormikContext } from 'formik';
import React from 'react';

import { COLORS, SPACES } from '../../../../theme';
import {
  CheckBoxFormik,
  Input,
  InputFile,
  InputMatchedWords,
  InputTextArea
} from '../../../common/component';
import { InputsConst } from '../../../common/constants';
import {useManufacturersQuery} from "../../../../hooks";

export const StepTow = () => {
  const { values, errors } = useFormikContext();

  const { data} = useManufacturersQuery.all()

  const is_spare_parts = getIn(values, 'is_spare_parts');
  const manufacturer_mame = getIn(values, 'manufacturer_mame');

  return (
    <>
      <InputMatchedWords
        matchedWords={data?.manufacturers.map((v)=> v.name) ?? []}
        height='3.5rem'
        mt={SPACES.xxxxl}
        isFilter
        isFilterVisibleAllData
        {...InputsConst.manufacturer_mame}
      />
      {manufacturer_mame === 'Other' && (
        <Input mt={SPACES.xxxxl} height='3.5rem' {...InputsConst.manufacturer_mame_other} />
      )}

      <Input mt={SPACES.xxxxl} height='3.5rem' {...InputsConst.model_number} />
      <Input mt={SPACES.xxxxl} height='3.5rem' {...InputsConst.auction_link} isOptional />

      <InputTextArea rows={5} maxLength={1000} mt={SPACES.xxxxl} {...InputsConst.description} />

      <InputFile
        isOptional
        height='3.5rem'
        mb={SPACES.xxxxl}
        accept='.pdf'
        multiple
        {...InputsConst.files}
      />

      <CheckBoxFormik
        name='is_spare_parts'
        label='Spare Parts and Accessories'
        colorText={is_spare_parts ? COLORS.black : COLORS.black30}
        background={is_spare_parts ? COLORS.green : COLORS.black30}
      />

      {is_spare_parts && (
        <InputTextArea
          rows={2}
          maxLength={1000}
          mt={SPACES.xxxxl}
          name='spare_parts'
          label='Spare Parts and Accessories Information'
          placeholder=''
        />
      )}
    </>
  );
};
