import { EnhancedWithAuthHttpService, HttpFactoryService } from '../module/common/services';
import { ExpectedFromQuery, IMessage, Iuuid } from '../module/common/types';
import { IGetAllFiltersGuests, IGetAllGuests, IGuestEdit } from '../types';

class GuestService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}

  async all(data: IGetAllFiltersGuests): Promise<ExpectedFromQuery<IGetAllGuests>> {
    return this.httpService.post<IGetAllGuests, IGetAllFiltersGuests>('/guest/all', data);
  }

  async put(data: Partial<IGuestEdit>, id?: Iuuid): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.put<IMessage, Partial<IGuestEdit>>(`/guest/${id}`, data);
  }
}

const factory = new HttpFactoryService();
export const guestService = new GuestService(factory.createAuthHttpService());
