import { Form, Formik } from 'formik';
import React from 'react';

import { useLogin } from '../../../../hooks/auth';
import { COLORS, SPACES } from '../../../../theme';
import { ILogin } from '../../../../types';
import { Button, CheckBoxFormik, Input, Loader } from '../../../common/component';
import { InputsConst } from '../../../common/constants';
import { TitleCommon } from '../../../common/styles';
import { PageLayout } from '../../common';
import { validationSchemaLogin } from '../../validation/shema';

export const Login = () => {
  const { isLoading, mutate } = useLogin();

  const onSubmit = (data: ILogin) => {
    mutate(data);
  };

  return (
    <PageLayout>
      <Formik
        initialValues={{
          email: '',
          password: '',
          is_remember: false
        }}
        onSubmit={async (values: ILogin, { setValues, setTouched }) => {
          await onSubmit(values);
          setValues((v: any) => ({ ...v, password: '' }));
          setTouched({ password: false });
        }}
        validationSchema={validationSchemaLogin}
      >
        {({ isValid }) => (
          <Form>
            <TitleCommon>Welcome to Invy</TitleCommon>

            {(['email', 'password'] as (keyof typeof InputsConst)[]).map((v, index) => (
              <Input key={index} mt={SPACES.xxxxl} {...InputsConst[v]} type={v} height='3rem' />
            ))}

            <CheckBoxFormik mt={SPACES.l} name='is_remember' label='Remember for 30 days' />

            <Button
              mt={SPACES.xxxxxxl}
              content={
                !isLoading ? 'Sign In' : <Loader size='small' color={COLORS.green} height='auto' />
              }
              type='submit'
              variant='primary'
              disabled={!isValid || isLoading}
            />
          </Form>
        )}
      </Formik>
    </PageLayout>
  );
};
