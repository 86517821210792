import React from 'react';
import { useNavigate } from 'react-router-dom';

import { COLORS, FONTS, SPACES } from '../../theme';
import { Button } from '../common/component';
import { APP_KEYS } from '../common/constants';
import { DivCommon, SubTitleCommon, TitleCommon } from '../common/styles';
import * as Styled from './guest-pages.styled';

export const GuestPages = () => {
  const navigate = useNavigate();

  const onNavigate = (to: string) => {
    navigate(to);
  };

  return (
    <DivCommon width='100%' height='100dvh' background={COLORS.white50} ai='center' jc='center'>
      <Styled.ContainerInfo width='90%' background={COLORS.white}>
        <TitleCommon color={COLORS.black20} ta='center' fw={FONTS.WEIGHTS.semi_bold}>
          Guest Mode
        </TitleCommon>
        <SubTitleCommon ml={SPACES.l} color={COLORS.black20} mb='4.5rem' ta='center'>
          You are currently submitting an item in guest mode. We encourage you to create an account
          upon completion of your item submission.
        </SubTitleCommon>

        <Button
          content='Continue'
          mt={SPACES.l}
          type='button'
          variant='primary'
          onClick={onNavigate.bind(this, APP_KEYS.ROUTER_KEYS.CREATE_ITEM_PUBLIC)}
        />

        {/* <Button */}
        {/*   content='Create an Account' */}
        {/*   mt={SPACES.l} */}
        {/*   type='button' */}
        {/*   variant='inverse2' */}
        {/*   onClick={onNavigate.bind(this, APP_KEYS.ROUTER_KEYS.SING_UP)} */}
        {/* /> */}
        <Button
          content='Login'
          mt={SPACES.l}
          type='button'
          variant='primary'
          onClick={onNavigate.bind(this, APP_KEYS.ROUTER_KEYS.LOGIN)}
        />
      </Styled.ContainerInfo>
    </DivCommon>
  );
};
