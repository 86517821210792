import React, { useState } from 'react';

import { Icons } from '../../../../assets';
import { COLORS, FONTS, SPACES } from '../../../../theme';
import { FilePath } from '../../../../utils';
import {
  Accordion,
  Button,
  Camera,
  InputTextArea,
  PopupLayoutBottom,
  Switch
} from '../../../common/component';
import { DivCommon, SubTitleCommon, TitleCommon } from '../../../common/styles';
import { IImageEdit, IImageEditPopupLayout } from '../../types';
import * as Styled from './image-edit.styled';

const PopupLayout = ({ isCreate, children, onClose }: IImageEditPopupLayout) => {
  if (isCreate) {
    return (
      <PopupLayoutBottom onClose={onClose} styled={Styled.StilePopupLayoutBottom}>
        {children}
      </PopupLayoutBottom>
    );
  }
  return <Styled.StyledDrawerContainer>{children}</Styled.StyledDrawerContainer>;
};

export const ImageEdit = ({
  onClose,
  image,
  onSave,
  onDelete,
  isCreate = true,
  isEdit = false
}: IImageEdit) => {
  const [isOpenCamera, setIsOpenCamera] = useState(false);

  const [mutateImage, setMutateImage] = useState({ ...image });

  const areEqual = JSON.stringify(image) === JSON.stringify(mutateImage);

  const onClick = (tag: string) => {
    setMutateImage((prev: any) => {
      // @ts-ignore
      prev[tag] = !prev[tag];

      return { ...prev };
    });
  };

  const onChange = (value: string) => {
    setMutateImage((prev: any) => {
      // @ts-ignore
      prev.description = value;

      return { ...prev };
    });
  };
  const setSingleImage = (i: any) => {
    setMutateImage((prev: any) => {
      prev.path = i;

      return { ...prev };
    });
  };

  const onRetakePhoto = () => setIsOpenCamera(true);
  const onCloseCamera = () => setIsOpenCamera(false);

  const value = mutateImage.description ?? '';

  return (
    <>
      {isOpenCamera && (
        <Camera
          onClose={onCloseCamera}
          onSuccess={onCloseCamera}
          images={[]}
          setImages={() => {}}
          isSingle
          setSingleImage={setSingleImage}
        />
      )}

      <PopupLayout onClose={onClose} isCreate={isCreate}>
        <DivCommon height='100%' padding={`${SPACES.xxl} ${SPACES.l} ${SPACES.l} ${SPACES.xxxxl}`}>
          <DivCommon fd='row' jc='space-between'>
            <Styled.Icon onClick={onClose} icon={FilePath(Icons.arrowLeftIcon2)} />
            <TitleCommon ff={FONTS.FAMILIES.source_sans_pro}>
              Photo Details {mutateImage.id}
            </TitleCommon>
            {isCreate ? (
              <Styled.Icon
                onClick={onDelete}
                icon={FilePath(Icons.deleteIcon)}
                background={COLORS.red100}
              />
            ) : (
              <div />
            )}
          </DivCommon>

          <Styled.Content>
            {mutateImage?.path && (
              <Styled.ImageContainer>
                <Styled.Image src={mutateImage?.path} alt='image-product' />
              </Styled.ImageContainer>
            )}

            {/*{[*/}
            {/*  { tag: 'is_primary_photo', text: 'Primary Photo' },*/}
            {/*  { tag: 'is_manufacturer_tag', text: 'Manufacturer Tag' }*/}
            {/*].map(({ tag, text }, index) => (*/}
            {/*  <Styled.DivSwitch key={index}>*/}
            {/*    <DivCommon fd='row' jc='space-between'>*/}
            {/*      <SubTitleCommon>{text}</SubTitleCommon>*/}
            {/*      <Switch*/}
            {/*        name={tag}*/}
            {/*        isChecked={mutateImage[tag]}*/}
            {/*        onClick={onClick.bind(this, tag)}*/}
            {/*      />*/}
            {/*    </DivCommon>*/}
            {/*  </Styled.DivSwitch>*/}
            {/*))}*/}

            <Accordion index={0} title='Add Photo Description'>
              <InputTextArea
                rows={5}
                maxLength={1000}
                mt={SPACES.xxs}
                name='description'
                label=''
                placeholder='Photo Description'
                value={value}
                onChange={onChange}
              />
            </Accordion>

            {(isCreate || isEdit) && (
              <Button
                mt={SPACES.l}
                onClick={onRetakePhoto}
                content='Retake Photo'
                variant='inverse2'
                type='submit'
              />
            )}

            <Button
              mt={SPACES.l}
              onClick={areEqual ? onClose : onSave.bind(this, mutateImage)}
              content={areEqual ? 'Cancel' : 'Save'}
              variant='primary'
              type='submit'
            />
            {isEdit}

            <Button
              mt={SPACES.l}
              onClick={onDelete}
              content='Delete'
              variant='delete'
              type='submit'
            />
          </Styled.Content>
        </DivCommon>
      </PopupLayout>
    </>
  );
};
