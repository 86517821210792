import styled from 'styled-components';

import { SHADOWS, SPACES } from '../../../../theme';
import { DivCommon } from '../../../common/styles';

export const ContainerInfo = styled(DivCommon)`
  max-width: 22.5rem !important;
  width: 22.5rem;
  border-radius: 12px;

  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  box-shadow: ${SHADOWS.l};

  padding: 2.5rem ${SPACES.xxxxl};
`;
