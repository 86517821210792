import { getIn, useFormikContext } from 'formik';
import React from 'react';

import add from '../../../../assets/icons/add-avatar-icon.svg';
import { FilePath } from '../../../../utils';
import { fileService } from '../../services';
import * as Styled from './avatar-setup.styled';

export interface IAvatarSetup {
  label?: string;
  name: string;
  readOnly?: boolean;
}

export const AvatarSetup = ({ label, name, readOnly }: IAvatarSetup) => {
  const { values, setFieldValue } = useFormikContext();

  const avatar = getIn(values, name);

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const fileBase64 = await fileService.convertBase64(file);
      setFieldValue(name, fileBase64);
    }
  };

  const delAvatar = () => {
    setFieldValue(name, null);
  };

  return (
    <Styled.OptionBlock>
      {label && <Styled.FieldLabel>{label}</Styled.FieldLabel>}
      <Styled.AvatarContainer>
        {avatar ? <Styled.Avatar src={avatar} alt='avatar' key={avatar} /> : null}

        {!avatar && (
          <>
            <label htmlFor='field-upload'>
              <Styled.AddAvatar src={FilePath(add)} alt='avatar' />
            </label>
            {!readOnly && (
              <input id='field-upload' type='file' accept='image/*' onChange={onChange} title='' />
            )}
          </>
        )}
        {avatar && !readOnly && (
          <Styled.CloseButton onClick={delAvatar}>&#10006;</Styled.CloseButton>
        )}
      </Styled.AvatarContainer>
    </Styled.OptionBlock>
  );
};
