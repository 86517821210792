import { EnhancedWithAuthHttpService, HttpFactoryService } from '../module/common/services';
import { ExpectedFromQuery, IMessage, Iuuid } from '../module/common/types';
import {
  IGetAllFiltersProduct,
  IGetMyAllProducts,
  IGetSingleProduct,
  IMessageProductStatus,
  IProduct,
  IProductEdit,
  ISoldChange,
  IStatusChange
} from '../types';

class ProductService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}

  async myAll(data: Partial<IGetAllFiltersProduct>): Promise<ExpectedFromQuery<IGetMyAllProducts>> {
    return this.httpService.post<IGetMyAllProducts, Partial<IGetAllFiltersProduct>>(
      '/product/my',
      data
    );
  }

  async findOne(id: Iuuid): Promise<ExpectedFromQuery<IGetSingleProduct>> {
    return this.httpService.get<IGetSingleProduct>(`/product?product_id=${id}`, {});
  }

  async post(data: IProduct): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.post<IMessage, IProduct>('/product', data);
  }

  async put(data: IProductEdit): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.put<IMessage, IProductEdit>('/product', data);
  }

  async remove({ id }: Iuuid): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.delete<IMessage>(`/product?product_id=${id}`);
  }

  async status(data: IStatusChange): Promise<ExpectedFromQuery<IMessageProductStatus>> {
    return this.httpService.put<IMessageProductStatus, IStatusChange>('/product/status', data);
  }

  async sold(data: ISoldChange): Promise<ExpectedFromQuery<IMessage>> {
    return this.httpService.put<IMessage, ISoldChange>('/product/sold', data);
  }
}

const factory = new HttpFactoryService();
export const productService = new ProductService(factory.createAuthHttpService());
