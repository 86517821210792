import { EnhancedWithAuthHttpService, HttpFactoryService } from '../module/common/services';
import { ExpectedFromQuery } from '../module/common/types';
import { IFiltersData } from '../module/inventory-manager/component';
import { IAllInfoProduct, IAllInfoProductSuperAdmin, IStatusMutation } from '../types';

class AdminService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}

  async all_info_product(
    { page, is_dashboard, status }: IStatusMutation,
    filters?: Omit<IFiltersData, 'users' | 'companies'>
  ): Promise<ExpectedFromQuery<IAllInfoProduct>> {
    return this.httpService.post<
      IAllInfoProduct,
      { filters?: Omit<IFiltersData, 'users' | 'companies'> }
    >(
      `/admin/all-info-product?page=${page}&is_dashboard=${is_dashboard ?? false}&status=${status}`,
      { filters }
    );
  }

  async all_info_product_super_admin(
    { page, is_dashboard = false, status, is_download }: IStatusMutation,
    filters?: IFiltersData
  ): Promise<ExpectedFromQuery<IAllInfoProductSuperAdmin>> {
    const link = this.httpService.createQueryLink('/admin/super-all-info-product', {
      page,
      is_dashboard: is_dashboard ?? false,
      status,
      is_download: is_download ?? false
    });

    return this.httpService.post<IAllInfoProductSuperAdmin, { filters?: IFiltersData }>(link, {
      filters
    });
  }
}

const factory = new HttpFactoryService();
export const adminService = new AdminService(factory.createAuthHttpService());
