import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Icons, Png } from '../../../../assets';
import { useProductQuery } from '../../../../hooks';
import { COLORS, FONTS, SPACES } from '../../../../theme';
import { FilePath } from '../../../../utils';
import { Button, Loader, Pagination } from '../../../common/component';
import { APP_KEYS } from '../../../common/constants';
import { DivCommon, SubTitleCommon, TitleCommon } from '../../../common/styles';
import { Warning } from '../../../product/component';
import { Nav } from '../nav/nav';
import * as Styled from './user-page.styled';

export const UserPage = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(1);

  const { isLoading, data } = useProductQuery.myAll({ page });

  const products = data?.products ?? [];

  return (
    <Styled.Container
      width='100%'
      height='100%'
      ai='center'
      jc='center'
      background={COLORS.white50}
      id='container'
    >
      {window.innerWidth < 600 && (
        <>
          <Styled.BodyDiv height='100%'>
            <DivCommon fd='row' jc='space-between' ai='center'>
              <TitleCommon fw={FONTS.WEIGHTS.semi_bold}>Home</TitleCommon>
              <Button
                onClick={() => navigate(APP_KEYS.ROUTER_KEYS.CREATE_ITEM_PUBLIC)}
                startIcon={FilePath(Icons.plusIcon)}
                widthIcon='1rem'
                background={COLORS.white}
                width='fit-content'
                content='Add Item'
              />
            </DivCommon>
            <DivCommon margin={`${SPACES.xxxxxxls} 0 ${SPACES.l} 0`}>
              <SubTitleCommon fs={FONTS.SIZES.l} fw={FONTS.WEIGHTS.semi_bold}>
                Recent Activity
              </SubTitleCommon>
            </DivCommon>

            {!products.length ? (
              <DivCommon height='100%' jc='center' ai='center'>
                <SubTitleCommon>No items submitted yet.</SubTitleCommon>
              </DivCommon>
            ) : null}

            {products.length ? (
              <Styled.Content>
                {products.map(({ user, category, name }, i) => (
                  <Styled.Item key={i}>
                    <Styled.ImageContainer />
                    <DivCommon>
                      <TitleCommon fs={FONTS.SIZES.l}>{name}</TitleCommon>
                      <SubTitleCommon fs={FONTS.SIZES.s} color={COLORS.black50}>
                        {category}
                      </SubTitleCommon>
                    </DivCommon>
                    <span>
                      {user.first_name} {user.last_name}
                    </span>
                  </Styled.Item>
                ))}

                {isLoading && <Loader size='small' color={COLORS.green} height='auto' />}
                {data?.count && +data?.count > 20 ? (
                  <Pagination
                    totalCount={+data?.count}
                    pageSize={20}
                    siblingCount={1}
                    onPageChange={(num: number) => setPage(num)}
                    currentPage={page}
                  />
                ) : null}
              </Styled.Content>
            ) : null}
          </Styled.BodyDiv>

          <Nav />
        </>
      )}

      {window.innerWidth > 600 ? <Warning /> : null}
    </Styled.Container>
  );
};
