import styled from 'styled-components';

import { COLORS } from '../../../../theme';
import { CloseButton } from '../../../common/component';
import { DrawerContainer, Margin } from '../../../common/styles';

export const Container = styled(DrawerContainer)``;

export const CloseBtn = styled(CloseButton)`
  margin: 0 0 0 auto;
  width: 1.5rem;
  height: 1.5rem;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${COLORS.black250};

  ${Margin};
`;
