import styled, { css } from 'styled-components';

import { COLORS, SHADOWS, SPACES } from '../../../../theme';
import * as CommonStyled from '../../../common/component/navigation-component/navigation-component.styled';
import { Center, DivCommon, IconCommon } from '../../../common/styles';

export const Container = styled(DivCommon)`
  width: 100%;
  height: 12%;
  max-height: 6.25rem;
  min-height: 6.25rem;
  padding: 0 ${SPACES.xxxxxxls};
  background-color: ${COLORS.white};
  ${Center};

  position: relative;
  bottom: 0;
`;

export const ContainerInfo = styled(DivCommon)`
  ${Center};
  flex-direction: row;
  justify-content: space-between;
`;

export const Icon = styled(IconCommon)`
  height: ${SPACES.xxxl};
  aspect-ratio: 1/1;
  cursor: pointer;
  position: relative;

  background: ${COLORS.black50} !important;
`;

export const IconContainer = styled.div<{ isActive: boolean }>`
  width: fit-content;

  &::after {
    content: '';
    display: none;
    width: 100%;
    height: 2px;
    margin-top: 4px;
    background-color: #82b090;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &::after {
        display: block;
      }

      & > .icon {
        background: ${COLORS.green} !important;
      }
    `}
`;

export const Setting = styled(CommonStyled.Setting)`
  max-width: 13rem;

  top: -8rem;
  right: -0.5rem;
  z-index: 2;

  box-shadow: ${SHADOWS.xxs};

  &::before {
    content: '';
    position: absolute;
    top: 6.6rem;
    right: 10px;
    border-width: 8px;
    border-style: solid;
    border-color: ${COLORS.white} transparent transparent transparent;
  }
`;

export const Div = styled(CommonStyled.Div)``;

export const SettingItem = styled(CommonStyled.SettingItem2)``;

export const Line = styled.div`
  width: 100%;
  height: 2px;
  background: ${COLORS.black250};
`;
