export const Icons = {
  addAvatarIcon: require('./add-avatar-icon.svg'),
  error: require('./error-icon.svg').default,
  success: require('./success-icon.svg').default,
  logo: require('./logo.svg').default,
  home: require('./home.svg').default,
  home2: require('./home2.svg').default,
  logaut: require('./logaut.svg').default,
  inventory: require('./inventory.svg').default,
  action: require('./action.svg').default,
  setting: require('./setting.svg').default,
  userIcon: require('./user-icon.svg').default,
  companiesIcon: require('./companies-icon.svg').default,
  plusIcon: require('./plus.svg').default,
  arrowBottomIcon: require('./arrow-bottom.svg').default,
  arrowLeftIcon: require('./arrow-left.svg').default,
  arrowLeftIcon2: require('./arrow-left2.svg').default,
  arrowRightIcon: require('./arrow-right.svg').default,
  closeIcon: require('./close-icon.svg').default,
  deleteIcon: require('./delete-icon.svg').default,
  filterIcon: require('./filter-icon.svg').default,
  visibilityOn: require('./visibility-icon.svg').default,
  visibilityOff: require('./visibility-off-icon.svg').default,
  pendingIcon: require('./pending-icon.svg').default,
  successIcon2: require('./success-icon2.svg').default,
  actionIcon: require('./action-icon.svg').default,
  rejectedIcon: require('./rejected-icon.svg').default,
  clockIcon: require('./clock-icon.svg').default,
  outActione: require('./out-actione.svg').default,
  arrowRight2: require('./arrow-right2.svg').default,
  copyIcon: require('./copi-iocn.svg').default,
  download: require('./download.svg').default
};
