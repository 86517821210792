import React, { useState } from 'react';

import { useGuestMutation, useUserQuery } from '../../../../hooks';
import { SPACES } from '../../../../theme';
import { IGuestResponse } from '../../../../types';
import { Button, PopupLayout, Portal } from '../../../common/component';
import * as StyledTable from '../../../common/component/table/table.styled';
import { DivCommon, TitleCommon } from '../../../common/styles';
import { Iuuid } from '../../../common/types';

export interface ICompanyTable {
  guests: IGuestResponse[];
}

export const GuestsTable = ({ guests }: ICompanyTable) => {
  const [putId, setPutId] = useState<Iuuid | null>(null);
  const [open, setOpen] = useState(false);

  const { put } = useGuestMutation;
  const { findOneCacheMainUser } = useUserQuery;

  const { mutate } = put(putId as Iuuid);
  const mainUser = findOneCacheMainUser();

  const onApprove = (_id: Iuuid) => {
    setPutId(_id);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setPutId(null);
  };

  const onSuccess = () => {
    mutate({ data: { status: 'Approved' } });
    onClose();
  };

  return (
    <>
      <StyledTable.Table>
        <StyledTable.Head>
          <StyledTable.Row>
            <StyledTable.HeadRow>First Name</StyledTable.HeadRow>
            <StyledTable.HeadRow>Last Name</StyledTable.HeadRow>
            <StyledTable.HeadRow>Email</StyledTable.HeadRow>
            <StyledTable.HeadRow>Phone</StyledTable.HeadRow>
            <StyledTable.HeadRow>Status</StyledTable.HeadRow>

            <StyledTable.HeadRow className='button' />
          </StyledTable.Row>
        </StyledTable.Head>
        <StyledTable.Body>
          {guests
            ? guests?.map(({ first_name, last_name, email, phone, status, id }, index) => (
                <StyledTable.Row key={`${id}${index}`}>
                  <StyledTable.Data className='title'>
                    <StyledTable.ItemLabel
                      className='tooltip'
                      linesToTruncate={1}
                      tooltipText={first_name}
                    >
                      {first_name}
                    </StyledTable.ItemLabel>
                  </StyledTable.Data>

                  <StyledTable.Data className='title'>
                    <StyledTable.ItemLabel
                      className='tooltip'
                      linesToTruncate={1}
                      tooltipText={last_name}
                    >
                      {last_name}
                    </StyledTable.ItemLabel>
                  </StyledTable.Data>

                  <StyledTable.Data className='title'>
                    <StyledTable.ItemLabel
                      className='tooltip'
                      linesToTruncate={1}
                      tooltipText={email}
                    >
                      {email}
                    </StyledTable.ItemLabel>
                  </StyledTable.Data>

                  <StyledTable.Data className='title'>
                    <StyledTable.ItemLabel
                      className='tooltip'
                      linesToTruncate={1}
                      tooltipText={phone}
                    >
                      {phone}
                    </StyledTable.ItemLabel>
                  </StyledTable.Data>

                  <StyledTable.Data className='title'>
                    <StyledTable.ItemLabel
                      className='tooltip'
                      linesToTruncate={1}
                      tooltipText={status}
                    >
                      {status}
                    </StyledTable.ItemLabel>
                  </StyledTable.Data>

                  {/* edit item button */}
                  <StyledTable.Data className='button'>
                    {status === 'Registered' && (
                      <>
                        {mainUser?.user.role === 'admin' ? (
                          'Registered'
                        ) : (
                          <StyledTable.ButtonTable
                            width='100%'
                            onClick={onApprove.bind(this, id)}
                            content='Approve'
                            type='button'
                            variant='inverse2'
                          />
                        )}
                      </>
                    )}
                    {status === 'Approved' && 'Approved'}
                  </StyledTable.Data>
                </StyledTable.Row>
              ))
            : null}
        </StyledTable.Body>
      </StyledTable.Table>

      {open ? (
        <Portal>
          <PopupLayout onClose={onClose} width='30rem'>
            <TitleCommon>Are you sure you want to approve this user to be signed up?</TitleCommon>
            <DivCommon>
              <Button
                mt={SPACES.xxxxl}
                content='Yes'
                type='button'
                variant='primary'
                onClick={onSuccess}
              />
              <Button content='Cancel' type='button' variant='inverse2' onClick={onClose} />
            </DivCommon>
          </PopupLayout>
        </Portal>
      ) : null}
    </>
  );
};
