import { getIn, useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import { useCompanyQuery, useUserQuery } from '../../../../hooks';
import { SPACES } from '../../../../theme';
import {
  CheckBoxFormik,
  Input,
  InputContainer,
  InputMatchedWords,
  InputMatchedWordsAndCheckBox
} from '../../../common/component';
import {
  APP_KEYS,
  Category,
  Country,
  InputsConst,
  Location,
  State
} from '../../../common/constants';
import { useLocalStorage } from '../../../common/hooks';
import {useCategoryQuery } from "../../../../hooks";

export const StepOne = () => {
  const { isToken, decoded } = useLocalStorage().get(APP_KEYS.STORAGE_KEYS.TOKEN as 'token');
  //TODO wait until the client decides
  // const { data } = useUserQuery.findOne(decoded?.sub);
  // const locations = isToken ? data?.user.location ?? Location : Location;

  const { values, setFieldError, setFieldValue } = useFormikContext();
  const { data} = useCategoryQuery.all()

  const isAdmin = ['super_admin', 'admin'].includes(decoded?.type ?? '');

  const showCompanyName = getIn(values, 'showCompanyName');

  // const { data: dataCompanies } = useCompanyQuery.all(
  //   { isCompanyUser: true },
  //   {
  //     // enabled: isAdmin
  //   }
  // );

  // const company_info = getIn(values, 'company_info');

  // const { isFetching, data: dataUsers } = useUserQuery.all(
  //   { company_name: company_info?.name },
  //   { enabled: (!!company_info?.name && isAdmin) ?? !(isAdmin || !!company_info?.name) }
  // );

  // const companies =
  //   dataCompanies?.companies && dataCompanies?.companies.length
  //     ? dataCompanies.companies.map((v) => ({
  //         name: v.name,
  //         id: v?.id,
  //         location: v.location
  //       }))
  //     : [];

  // const users =
  //   dataUsers?.users && dataUsers?.users.length
  //     ? dataUsers.users.map((v) => ({
  //         email: v.email,
  //         id: v.id,
  //         role: v.role
  //       }))
  //     : [];
  //
  // useEffect(() => {
  //   if (dataUsers?.users.length === 0) {
  //     setFieldError('company_info', 'no user in this company');
  //   }
  //
  //   const company = dataCompanies?.companies.find((val) => val?.id === company_info?.id);
  //
  //   const user = dataUsers?.users.find((val) => val.email === company?.email);
  //
  //   if (user) {
  //     setFieldValue('admin_user_info', {
  //       email: user?.email,
  //       id: user?.id,
  //       role: user?.role
  //     });
  //   }
  // }, [isFetching, dataUsers?.users, company_info]);

  // useEffect(() => {
  //   setFieldValue("admin_user_info", null);
  // }, [company_info]);

  const category = getIn(values, 'category');
  const country = getIn(values, 'country');
  // const location = getIn(values, 'location');

  const isCountryOther = country === 'Other';

  // useEffect(() => {
  //   if (showCompanyName) {
  //     setFieldValue('company_info', '');
  //   }
  // }, [showCompanyName]);

  // useEffect(() => {
  //   if (location) {
  //     setFieldValue('country', location.country);
  //     setFieldValue('city', location.city);
  //     setFieldValue('state', location.state);
  //     setFieldValue('zip', location.zip);
  //     setFieldError('country', '');
  //     setFieldError('city', '');
  //     setFieldError('state', '');
  //     setFieldError('zip', '');
  //   }
  // }, [location]);

  return (
    <>
      <Input mt={SPACES.xxxxl} height='3.5rem' {...InputsConst.name} />

      <InputMatchedWords
        matchedWords={data?.categorys.map((v)=> v.name) ?? []}
        height='3.5rem'
        mt={SPACES.xxxxl}
        isFilter
        isFilterVisibleAllData
        isEdit
        {...InputsConst.category}
      />

      {category === 'Other' && (
        <Input mt={SPACES.xxxxl} height='3.5rem' {...InputsConst.category_other} />
      )}

      {/*  //TODO wait until the client decides*/}
      {/*{!isToken ? (*/}
      {/*  <Input mt={SPACES.xxxxl} height='3.5rem' {...InputsConst.location} />*/}
      {/*) : (*/}
      {/*  <InputMatchedWords*/}
      {/*    matchedWords={locations as string[]}*/}
      {/*    height='3.5rem'*/}
      {/*    mt={SPACES.l}*/}
      {/*    readOnlyKeyboard*/}
      {/*    {...InputsConst.location}*/}
      {/*  />*/}
      {/*)}*/}

      {
        !isToken &&  (
              <InputMatchedWordsAndCheckBox
                  isVisibleCheckBox={isAdmin}
                  companies={[]}
                  showCompanyName={showCompanyName}
                  isAdmin={!isAdmin}
              />
          )
      }


      {/*{users.length ? (*/}
      {/*  <InputMatchedWords*/}
      {/*    matchedWords={users}*/}
      {/*    visibleItem='email'*/}
      {/*    height='3.5rem'*/}
      {/*    isDontChange*/}
      {/*    mt={SPACES.xxxxl}*/}
      {/*    {...InputsConst.email}*/}
      {/*    name='admin_user_info'*/}
      {/*  />*/}
      {/*) : null}*/}
      {/*{!!company_info || (getIn(values, 'companyTest') && showCompanyName && isAdmin) ? (*/}
      {/*  <InputMatchedWords*/}
      {/*    // matchedWords={companies.find((val) => val?.id === (!isAdmin ? company_info?.id : getIn(values, 'companyTest')?.id))?.location ?? []}*/}
      {/*    matchedWords={company_info.location ?? []}*/}
      {/*    height='3.5rem'*/}
      {/*    visibleItem='city'*/}
      {/*    mt={SPACES.xxxxl}*/}
      {/*    isEdit*/}
      {/*    isFilter*/}
      {/*    isFilterVisibleAllData*/}
      {/*    readOnlyKeyboard*/}
      {/*    {...InputsConst.location}*/}
      {/*  />*/}
      {/*) : null}
  */}

      <Input mt={SPACES.xxxxl} height='3.5rem' {...InputsConst.item_location} />

      {/*<>*/}
      {/*  <InputMatchedWords*/}
      {/*    matchedWords={Country}*/}
      {/*    height='3.5rem'*/}
      {/*    readOnly={!isAdmin ? false : !showCompanyName}*/}
      {/*    mt={SPACES.xxxxl}*/}
      {/*    isFilter*/}
      {/*    isEdit*/}
      {/*    isFilterVisibleAllData*/}
      {/*    readOnlyKeyboard*/}
      {/*    {...InputsConst.country}*/}
      {/*  />*/}

      {/*  {isCountryOther && (*/}
      {/*    <Input*/}
      {/*      mt={SPACES.xxxxl}*/}
      {/*      readOnly={!isAdmin ? false : !showCompanyName}*/}
      {/*      height='3.5rem'*/}
      {/*      {...InputsConst.country_other}*/}
      {/*    />*/}
      {/*  )}*/}

      {/*  {!isCountryOther && (*/}
      {/*    <InputMatchedWords*/}
      {/*      matchedWords={State[country]}*/}
      {/*      height='3.5rem'*/}
      {/*      mt={SPACES.xxxxl}*/}
      {/*      readOnly={!isAdmin ? false : !showCompanyName}*/}
      {/*      isEdit*/}
      {/*      isFilter*/}
      {/*      isFilterVisibleAllData*/}
      {/*      readOnlyKeyboard*/}
      {/*      {...InputsConst.state}*/}
      {/*    />*/}
      {/*  )}*/}

      {/*  {([...(!isCountryOther ? [] : ['state']), 'city'] as (keyof typeof InputsConst)[]).map(*/}
      {/*    (name, index) => (*/}
      {/*      <Input*/}
      {/*        key={index}*/}
      {/*        readOnly={!isAdmin ? false : !showCompanyName}*/}
      {/*        mt={SPACES.xxxxl}*/}
      {/*        height='3.5rem'*/}
      {/*        {...InputsConst[name]}*/}
      {/*      />*/}
      {/*    )*/}
      {/*  )}*/}

      {/*  <Input*/}
      {/*    mt={SPACES.xxxxl}*/}
      {/*    readOnly={!isAdmin ? false : !showCompanyName}*/}
      {/*    height='3.5rem'*/}
      {/*    {...InputsConst.zip}*/}
      {/*    type='number'*/}
      {/*  />*/}
      {/*</>*/}

      {!isToken ? <InputContainer objectName='user_info' /> : null}
    </>
  );
};
