import React, { useEffect } from 'react';
import { UseQueryResult, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { userService } from '../../services';
import { COLORS } from '../../theme';
import { IGetOneUser } from '../../types';
import { Loader } from '../common/component';
import { APP_KEYS } from '../common/constants';
import { Iuuid } from '../common/types';
import { isPageExist, urlUtil } from '../common/utils';
import { AdminPage, UserPage } from './component';
import { useHomeLayoutContext } from './hooks';
import { IHomeLayout } from './types';

export const HomeLayout = ({ children }: IHomeLayout) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // const { setUser } = useHomeLayoutContext();

  const onSuccess = ({ user: _user }: IGetOneUser) => {
    // setUser(_user);
  };

  const onError = async () => {
    await localStorage.removeItem(APP_KEYS.STORAGE_KEYS.TOKEN);
    navigate(APP_KEYS.ROUTER_KEYS.LOGIN);
  };

  const { isLoading, data }: UseQueryResult<IGetOneUser, Error> = useQuery(
    [APP_KEYS.QUERY_KEYS.GET_MAIN_USER],
    () => userService.findOne(null),
    {
      onSuccess,
      onError
    }
  );

  useEffect(() => {
    if (!isPageExist(pathname)) {
      navigate(urlUtil(APP_KEYS.ROUTER_KEYS.INVENTORY_PRODUCT));
    }
  }, []);

  if (isLoading) {
    return <Loader size='small' color={COLORS.green} height='auto' />;
  }

  return (
    <>
      {(data?.user.role === 'super_admin' || data?.user.role === 'admin' || data?.user.role === 'viewer') && (
        <AdminPage user_id={data?.user?.id as Iuuid}>{children}</AdminPage>
      )}

      {(data?.user.role === 'user' || data?.user.role === 'guest') && <UserPage />}
    </>
  );
};
