import { Form, Formik } from 'formik';
import React from 'react';

import { useCompanyQuery, useUserQuery,useManufacturersQuery , useCategoryQuery} from '../../../../hooks';
import { COLORS, FONTS, SPACES } from '../../../../theme';
import { IStatus } from '../../../../types';
import {
  Accordion,
  Button,
  CheckBoxFormik,
  Input,
  InputMatchedWords,
  Loading
} from '../../../common/component';
import { Category, Country, InputsConst, ManufacturerNames } from '../../../common/constants';
import { DivCommon, TitleCommon } from '../../../common/styles';
import { Roles } from '../../../user-setting/user-setting';
import * as Styled from './filters.styled';

export interface IFilters {
  onClose: () => void;
  setFilters: (data: IFiltersData) => void;
  filters: IFiltersData;
  isSuperAdmin?: boolean;
}

export interface IFiltersData {
  companies: string[];
  users: string[];
  search: string;
  category: string;
  country: string;
  manufacturer_mame: string;
  sold: { name: string; flag: boolean } | null;
  status?: IStatus;
  last_name: string;
  first_name: string;
  orders: { [name:string]: 'ASC' | "DESC" | ''}
}

export const Filters = ({ onClose, setFilters, filters, isSuperAdmin }: IFilters) => {
  const { data, isLoading: isLoadingCompany } = useCompanyQuery.all({});

  const { data: manufacturersData} = useManufacturersQuery.all()
  const { data: categoryData} = useCategoryQuery.all()

  const mainUser = useUserQuery.findOneCacheMainUser();

  const { isLoading: isLoadingUsers, data: dataUsers } = useUserQuery.all(
    { company_name: mainUser?.user.role === 'admin' ? mainUser.user.company_info.name : '' },
    {}
  );

  const first_names = dataUsers?.users.map((v) => v.first_name) ?? [];
  const last_names = dataUsers?.users.map((v) => v.last_name) ?? [];

  const isLoading = isLoadingUsers || isLoadingCompany;

  const companies = data?.companies.map((v) => v.name) ?? [];

  // @ts-ignore
  const onSubmit = (data: any) => {
    const country = data.test;

    delete data.country;

    setFilters({ ...data, country });
    // onClose();
  };

  const onClear = () => {
    setFilters({
      companies: [],
      users: [],
      search: '',
      category: '',
      country: '',
      manufacturer_mame: '',
      sold: null,
      status: undefined,
      last_name: '',
      first_name: '',
      orders: {}
    });
  };

  // @ts-ignore
  const isFilters =
    filters?.users?.length || filters.companies.length || Object.values(filters ?? {}).length;

  return (
    <Styled.Container>
      {isLoading ? <Loading className='full-screen' /> : null}
      <DivCommon fd='row' ai='center'>
        <TitleCommon fw={FONTS.WEIGHTS.semi_bold}>Filters</TitleCommon>
        <Styled.CloseBtn onClick={onClose} />
      </DivCommon>

      <Formik
        initialValues={{
          ...filters,
          test: filters.country
        }}
        onSubmit={onSubmit}
      >
        {({ resetForm, values }) => (
          <Form>
            {/*<InputMatchedWords*/}
            {/*  matchedWords={['New Opportunity', 'Hot Lead', 'Medium Lead', 'Now Status']}*/}
            {/*  height='3.5rem'*/}
            {/*  mt={SPACES.xxxxl}*/}
            {/*  isFilter*/}
            {/*  isFilterVisibleAllData*/}
            {/*  isEdit*/}
            {/*  name='status'*/}
            {/*  label='Select Status'*/}
            {/*  placeholder='Select Status'*/}
            {/*/>*/}

            <InputMatchedWords
              matchedWords={categoryData?.categorys.map((v)=>v.name) ?? []}
              height='3.5rem'
              mt={SPACES.xxxxl}
              isFilter
              isFilterVisibleAllData
              isEdit
              {...InputsConst.category}
            />

            <InputMatchedWords
              matchedWords={Country}
              height='3.5rem'
              mt={SPACES.xxxxl}
              isFilter
              isFilterVisibleAllData
              {...InputsConst.country}
              name='test'
            />

            <InputMatchedWords
              matchedWords={manufacturersData?.manufacturers.map((v)=>v.name) ?? []}
              height='3.5rem'
              mt={SPACES.xxxxl}
              isFilter
              isFilterVisibleAllData
              {...InputsConst.manufacturer_mame}
            />

            <InputMatchedWords
              matchedWords={[
                { name: 'Yes', flag: true },
                { name: 'No', flag: false }
              ]}
              visibleItem='name'
              height='3.5rem'
              mt={SPACES.xxxxl}
              name='sold'
              label='Select Sold'
              placeholder='Select Sold'
            />

            <InputMatchedWords
              matchedWords={first_names}
              height='3.5rem'
              mt={SPACES.xxxxl}
              {...InputsConst.first_name}
            />
            <InputMatchedWords
              matchedWords={last_names}
              height='3.5rem'
              mt={SPACES.xxxxl}
              {...InputsConst.last_name}
            />

            {isSuperAdmin ? (
              <InputMatchedWords
                matchedWords={companies}
                height='3.5rem'
                mt={SPACES.xxxxl}
                name='companies'
                placeholder='Select companies'
                label='Select companies'
                isDontChange
                isChip
              />
            ) : null}

            {/*{*/}
            {/*    isSuperAdmin && (*/}
            {/*        <Accordion style={Styled.StyleAccordion2} title='Users' index={0} isOpen>*/}
            {/*            {users.map((value, index) => (*/}
            {/*                <Accordion*/}
            {/*                    style={Styled.StyleAccordion}*/}
            {/*                    title={value[0]}*/}
            {/*                    index={1 + index}*/}
            {/*                    propComponent={*/}
            {/*                        value[0] !== 'GUESTS' && (*/}
            {/*                            <div style={{padding: '10px 0px'}}>*/}
            {/*                                <CheckBoxFormik*/}
            {/*                                    label=''*/}
            {/*                                    labelValue={value[0]}*/}
            {/*                                    name='companies'*/}
            {/*                                    isMulti*/}
            {/*                                    colorText={COLORS.black}*/}
            {/*                                />*/}
            {/*                            </div>*/}
            {/*                        )*/}
            {/*                    }*/}
            {/*                >*/}
            {/*                    {value[1].map((v, i) => (*/}
            {/*                        <CheckBoxFormik*/}
            {/*                            key={index + i}*/}
            {/*                            label={v.email}*/}
            {/*                            name='users'*/}
            {/*                            isMulti*/}
            {/*                            colorText={COLORS.black}*/}
            {/*                        />*/}
            {/*                    ))}*/}
            {/*                </Accordion>*/}
            {/*            ))}*/}
            {/*        </Accordion>*/}
            {/*    )*/}
            {/*}*/}

            <Button
              mt={SPACES.l}
              content='Apply Filters'
              type='button'
              variant='primary'
              onClick={onSubmit.bind(this, values)}
            />

            {isFilters ? (
              <Button
                mt={SPACES.l}
                content='Clear Filters'
                type='button'
                variant='delete'
                onClick={() => {
                  resetForm();
                  onClear();
                }}
              />
            ) : null}
          </Form>
        )}
      </Formik>
    </Styled.Container>
  );
};
