import { Form, Formik } from 'formik';
import React from 'react';

import { SPACES } from '../../../../theme';
import { Button, Input, PopupLayout, Portal } from '../../../common/component';
import { DivCommon, SubTitleCommon, TitleCommon } from '../../../common/styles';
import { validationSchemaSold } from '../../validation/shema';

export interface ISoldPopup {
  onClose: () => void;
  onSubmit: (data: any) => void;
}

export const SoldPopup = ({ onClose, onSubmit }: ISoldPopup) => (
  <Portal>
    <PopupLayout width='30%' minWidth='32rem' onClose={onClose}>
      <TitleCommon mb={SPACES.l}>Item Sold</TitleCommon>
      <SubTitleCommon width='100%'>This action cannot be reverted.</SubTitleCommon>
      <DivCommon>
        <Formik
          initialValues={{ price: '' }}
          onSubmit={onSubmit}
          validationSchema={validationSchemaSold}
        >
          {() => (
            <Form>
              <Input
                mt={SPACES.xxxxl}
                height='3rem'
                name='price'
                type='number'
                label='Hammer Price'
                placeholder='Hammer Price'
              />

              <Button mt={SPACES.xxxxl} content='Save' type='submit' variant='primary' />
              <Button content='Cancel' type='button' variant='inverse2' onClick={onClose} />
            </Form>
          )}
        </Formik>
      </DivCommon>
    </PopupLayout>
  </Portal>
);
