import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { toastContainer } from '../../module/common/component/toast';
import { APP_KEYS } from '../../module/common/constants';
import { IMessage, Iuuid } from '../../module/common/types';
import { companyService } from '../../services';
import { IAuthError, ICompany } from '../../types';

const onError = (_err: AxiosError<IAuthError>) => {
  const err = _err.response?.data as IAuthError;
  toastContainer.error({ title: err.message ?? _err.message });
};

const onSuccess = ({ message }: IMessage) => {
  toastContainer.success({ title: message });
};

const post = () => {
  const client = useQueryClient();

  return useMutation<any, AxiosError<IAuthError>, ICompany>(
    (data: ICompany) => companyService.post(data),
    {
      onSuccess: async (data) => {
        await onSuccess(data);
        await client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_ALL_COMPANIES]);
      },
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};

const put = (id?: Iuuid) => {
  const client = useQueryClient();
  return useMutation<any, AxiosError<IAuthError>, ICompany>(
    (data: ICompany) => companyService.put(data, id),
    {
      onSuccess: async (data) => {
        await onSuccess(data);

        const invalidateQueriesPromises = [
          client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_ALL_COMPANIES])
        ];
        if (id) {
          invalidateQueriesPromises.push(
            client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_COMPANY, id])
          );
        }

        await Promise.allSettled(invalidateQueriesPromises);
      },
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};

const deleteCompany = (id?: Iuuid) => {
  const client = useQueryClient();
  return useMutation<any, AxiosError<IAuthError>>(() => companyService.delete(id), {
    onSuccess: async (data) => {
      await onSuccess(data);
      await client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_ALL_COMPANIES]);
    },
    onError: (err: AxiosError<IAuthError>) => onError(err)
  });
};

export const useCompanyMutation = {
  post,
  put,
  deleteCompany
};
