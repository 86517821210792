import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {Icons, Png} from '../../../../assets';
import testAvatar from '../../../../assets/images/png/test-avatar.png';
import {useProductMutation} from '../../../../hooks';
import {COLORS, SPACES} from '../../../../theme';
import {IGetOneUser, IProductResponseSuperAdmin, IStatus} from '../../../../types';
import {FilePath, dateTransform, statusTransform} from '../../../../utils';
import {Button, Loader, Pagination, PopupLayout, Portal} from '../../../common/component';
import * as StyledTable from '../../../common/component/table/table.styled';
import {APP_KEYS} from '../../../common/constants';
import {DivCommon, IconCommon, TitleCommon} from '../../../common/styles';
import {Iuuid} from '../../../common/types';
import {urlUtil} from '../../../common/utils';
import {IFiltersData} from "../filters/filters";
import {useQueryClient} from "react-query";

interface IActionTable {
    products: IProductResponseSuperAdmin[];
    count: number;
    isManager?: boolean;
    pageStatus?: IStatus;
    page: number;
    onPageChange: (v: number) => void;
    isLoading?: boolean;
    setFilters?: (data: IFiltersData) => void;
    orders?: { [name: string]: string }
}

export const InventoryTableSuperAdmin = ({
                                             products,
                                             isManager = false,
                                             count,
                                             pageStatus,
                                             page,
                                             onPageChange,
                                             isLoading,
                                             setFilters,
                                             orders
                                         }: IActionTable) => {
    const navigate = useNavigate();
    const {mutate} = useProductMutation.status({
        status: pageStatus,
        page,
        is_dashboard: false,
        role: 'super_admin'
    });
    const client = useQueryClient();

    const dataMainUser = client.getQueryData<IGetOneUser | undefined>(
        APP_KEYS.QUERY_KEYS.GET_MAIN_USER
    );

    const {mutate: mutateRemove} = useProductMutation.remove();

    const onClick = (id: Iuuid) => {
        navigate(urlUtil(`${APP_KEYS.ROUTER_KEYS.SINGLE_PRODUCT}/${id}`));
    };

    const [isDelete, setIsDelete] = useState<null | string>(null);

    const statusBtnText = {
        Pending: {
            text: 'Send',
            onClick: (id: Iuuid) => {
                mutate({
                    product_id: id,
                    status: 'In Auction'
                });
            }
        },
        Archived: {
            text: 'Renew',
            onClick: (id: Iuuid) => {
                mutate({
                    product_id: id,
                    status: 'Pending'
                });
            }
        }
    };

    const toggleIsDelete = (id: string | null) => {
        setIsDelete(id);
    };

    const onDelete = () => {
        mutateRemove({id: isDelete} as Iuuid);
        setIsDelete(null);
    };

    const onChangeOrder = (name: string) => {
        // @ts-ignore
        setFilters((prev: IFiltersData) => {
            if (!prev.orders[name]) {
                return {...prev, orders: {...prev.orders, [name]: 'ASC'}}
            }
            if (prev.orders[name] === 'ASC') {
                return {...prev, orders: {...prev.orders, [name]: 'DESC'}}
            }
            if (prev.orders[name] === 'DESC') {
                delete prev.orders[name]

                return {...prev, orders: {...prev.orders}}
            }
            return {...prev}
        })
    };

    const sort = (name: string) => {
        // @ts-ignore
        if (!orders[name]) {
            return <StyledTable.Sort onClick={onChangeOrder.bind(this, name)}>
                <img src={Png.sortImage}/>
            </StyledTable.Sort>
        }
        // @ts-ignore
        if (orders[name]) {
            // @ts-ignore
            return orders[name] === 'ASC' ?
                <StyledTable.Sort onClick={onChangeOrder.bind(this, name)}> <img src={Png.sortImage}/> <span>a-z</span></StyledTable.Sort> :
                <StyledTable.Sort onClick={onChangeOrder.bind(this, name)}> <img src={Png.sortImage}/> <span>z-a</span></StyledTable.Sort>


        }
    };

    // @ts-ignore
    // const buttonInfo = isManager && pageStatus ? statusBtnText[pageStatus] : null;

    return (
        <>
            <StyledTable.Table>
                <StyledTable.Head>
                    <StyledTable.Row>
                        {/*<StyledTable.HeadRow>Status</StyledTable.HeadRow>*/}
                        <StyledTable.HeadRow className='title2'>
                            <div> Item Title {sort('name')}</div>
                        </StyledTable.HeadRow>
                        <StyledTable.HeadRow>
                            <div> Model # {sort('model_number')}</div>
                        </StyledTable.HeadRow>
                        {/*<StyledTable.HeadRow>Date</StyledTable.HeadRow>*/}
                        <StyledTable.HeadRow>
                            <div> Item Category {sort('category')}</div>
                        </StyledTable.HeadRow>
                        <StyledTable.HeadRow>
                            <div> Item Location {sort('item_location')}</div>
                        </StyledTable.HeadRow>
                        <StyledTable.HeadRow className='link'>Auction</StyledTable.HeadRow>
                        {/*<StyledTable.HeadRow>First Name</StyledTable.HeadRow>*/}
                        {/*<StyledTable.HeadRow>Last Name</StyledTable.HeadRow>*/}


                        <StyledTable.HeadRow>Auction Status</StyledTable.HeadRow>

                        {
                            dataMainUser &&  dataMainUser?.user?.role !== 'viewer' && (
                                <StyledTable.HeadRow className='edit'/>
                            )
                        }


                        {/*{buttonInfo?.text && (*/}
                        {/*  <StyledTable.HeadRow className='button'>Auction</StyledTable.HeadRow>*/}
                        {/*)}*/}
                    </StyledTable.Row>
                </StyledTable.Head>
                <StyledTable.Body>
                    {!isLoading && products
                        ? products?.map(
                            (
                                {
                                    name,
                                    status,
                                    createdAt,
                                    auction_link,
                                    item_location,
                                    category,
                                    id,
                                    user,
                                    company,
                                    model_number,
                                    is_listed
                                }: IProductResponseSuperAdmin,
                                index
                            ) => {
                                const {text, background} = status
                                    ? statusTransform(status)
                                    : statusTransform('Now Status');
                                // const created_at = dateTransform(createdAt);

                                const _auction_link = auction_link ?? '-';
                                const _is_listed = typeof is_listed === 'boolean' ? is_listed ? 'Currently Listed' : 'Not Listed' : '-'
                                return (
                                    <StyledTable.Row key={`${id}${index}`} onClick={onClick.bind(this, id)}>
                                        {/*<StyledTable.Data className='title'>*/}
                                        {/*  <StyledTable.ItemLabel*/}
                                        {/*    className='tooltip'*/}
                                        {/*    linesToTruncate={0}*/}
                                        {/*    tooltipText={text}*/}
                                        {/*  >*/}
                                        {/*    {text}*/}
                                        {/*  </StyledTable.ItemLabel>*/}
                                        {/*</StyledTable.Data>*/}

                                        <StyledTable.Data className='title2'>
                                            <StyledTable.ItemLabel
                                                className='tooltip'
                                                linesToTruncate={1}
                                                tooltipText={name}
                                            >
                                                {name}
                                            </StyledTable.ItemLabel>
                                        </StyledTable.Data>

                                        <StyledTable.Data className='title'>
                                            <StyledTable.ItemLabel
                                                className='tooltip'
                                                linesToTruncate={1}
                                                tooltipText={model_number}
                                            >
                                                {model_number}
                                            </StyledTable.ItemLabel>
                                        </StyledTable.Data>

                                        {/*<StyledTable.Data className='title'>*/}
                                        {/*  <StyledTable.ItemLabel*/}
                                        {/*    className='tooltip'*/}
                                        {/*    linesToTruncate={1}*/}
                                        {/*    tooltipText={created_at}*/}
                                        {/*  >*/}
                                        {/*    {created_at}*/}
                                        {/*  </StyledTable.ItemLabel>*/}
                                        {/*</StyledTable.Data>*/}

                                        <StyledTable.Data className='title'>
                                            <StyledTable.ItemLabel
                                                className='tooltip'
                                                linesToTruncate={1}
                                                tooltipText={category}
                                            >
                                                {category}
                                            </StyledTable.ItemLabel>
                                        </StyledTable.Data>

                                        <StyledTable.Data className='title'>
                                            <StyledTable.ItemLabel
                                                className='tooltip'
                                                linesToTruncate={1}
                                                tooltipText={item_location}
                                            >
                                                {item_location}
                                            </StyledTable.ItemLabel>
                                        </StyledTable.Data>

                                        <StyledTable.Data className='link'>
                                            <StyledTable.ItemLabel
                                                className='tooltip'
                                                linesToTruncate={1}
                                                tooltipText={_auction_link}
                                            >
                                                {_auction_link ? <a href={_auction_link} target='_blank'>Link</a> : null}


                                            </StyledTable.ItemLabel>


                                        </StyledTable.Data>

                                        {/*<StyledTable.Data className='title'>*/}
                                        {/*  <StyledTable.ItemLabel*/}
                                        {/*    className='tooltip'*/}
                                        {/*    linesToTruncate={1}*/}
                                        {/*    tooltipText={user?.first_name ?? '-'}*/}
                                        {/*  >*/}
                                        {/*    {user?.first_name ?? '-'}*/}
                                        {/*  </StyledTable.ItemLabel>*/}
                                        {/*</StyledTable.Data>*/}
                                        {/*<StyledTable.Data className='title'>*/}
                                        {/*  <StyledTable.ItemLabel*/}
                                        {/*    className='tooltip'*/}
                                        {/*    linesToTruncate={1}*/}
                                        {/*    tooltipText={user?.last_name ?? '-'}*/}
                                        {/*  >*/}
                                        {/*    {user?.last_name ?? '-'}*/}
                                        {/*  </StyledTable.ItemLabel>*/}
                                        {/*</StyledTable.Data>*/}



                                        <StyledTable.Data className='title'>
                                            {/*<StyledTable.Avatar*/}
                                            {/*  src={user?.avatar ? user?.avatar : testAvatar}*/}
                                            {/*  alt='user-avatar'*/}
                                            {/*/>*/}

                                            <StyledTable.ItemLabel
                                                className='tooltip'
                                                linesToTruncate={1}
                                                tooltipText={_is_listed}
                                            >
                                                {_is_listed}
                                            </StyledTable.ItemLabel>
                                        </StyledTable.Data>

                                        {
                                            dataMainUser && dataMainUser.user.role !== 'viewer' && (
                                                <StyledTable.Data className='edit'>
                                                    <StyledTable.Avatar
                                                        src={FilePath(Icons.deleteIcon)}
                                                        alt='user-avatar'
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            toggleIsDelete(id);
                                                        }}
                                                    />
                                                </StyledTable.Data>
                                            )
                                        }



                                        {/*{buttonInfo?.text && (*/}
                                        {/*  <StyledTable.Data className='button'>*/}
                                        {/*    <StyledTable.ButtonTable*/}
                                        {/*      content={buttonInfo.text}*/}
                                        {/*      onClick={buttonInfo.onClick.bind(this, id)}*/}
                                        {/*      type='button'*/}
                                        {/*      variant='inverse2'*/}
                                        {/*    />*/}
                                        {/*  </StyledTable.Data>*/}
                                        {/*)}*/}
                                    </StyledTable.Row>
                                );
                            }
                        )
                        : null}
                    {!isLoading && !products?.length && (
                        <StyledTable.NowData>No items submitted yet.</StyledTable.NowData>
                    )}
                    {isLoading && (
                        <StyledTable.NowData>
                            <Loader size='small' color={COLORS.green} height='100%'/>
                        </StyledTable.NowData>
                    )}
                    {count && +count > 10 ? (
                        <Pagination
                            totalCount={+count}
                            pageSize={50}
                            siblingCount={1}
                            onPageChange={onPageChange}
                            currentPage={page}
                        />
                    ) : null}
                </StyledTable.Body>
            </StyledTable.Table>

            {isDelete ? (
                <Portal>
                    <PopupLayout onClose={toggleIsDelete.bind(this, null)}>
                        <TitleCommon>Are you sure</TitleCommon>
                        <DivCommon>
                            <Button
                                mt={SPACES.xxxxl}
                                content='Yes'
                                type='button'
                                variant='delete'
                                onClick={onDelete}
                            />
                            <Button
                                content='Cancel'
                                type='button'
                                variant='inverse2'
                                onClick={toggleIsDelete.bind(this, null)}
                            />
                        </DivCommon>
                    </PopupLayout>
                </Portal>
            ) : null}
        </>
    );
};
