import { UseQueryResult, useQuery } from 'react-query';

import { APP_KEYS } from '../../module/common/constants';
import {manufacturertsService} from "../../services/manufacturers.service";
import {IManufacturerAll} from "../../types/manufacturers.type";

const all = (): UseQueryResult<IManufacturerAll | undefined> =>
  useQuery([APP_KEYS.QUERY_KEYS.GET_ALL_MANUFACTURES], () => manufacturertsService.all() );


export const useManufacturersQuery = {
  all,
};
