import styled, { css } from 'styled-components';

import { COLORS, MEDIA, SHADOWS, SPACES, TRANSITIONS } from '../../../../theme';
import * as CommonStyled from '../../../common/component/navigation-component/navigation-component.styled';
import { Center, DivCommon, Fonts, IconCommon } from '../../../common/styles';

export const Container = styled(DivCommon)`
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
`;

export const ContainerChildren = styled(Container)`
  @media screen and (max-width: ${MEDIA.tablet}) {
    padding: 0 ${SPACES.xxxxxxls};
  }
`;

export const Icon = styled(IconCommon)`
  background: ${COLORS.black50};
`;

export const Logo = styled.img`
  height: 2rem;
`;

export const IconContainer = styled.div<{ isActive: boolean; tooltipText?: string }>`
  height: 2.5rem;
  aspect-ratio: 1/1;

  cursor: pointer;
  position: relative;

  border-radius: 50%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: ${COLORS.white};
    z-index: -1;
    transition: opacity 0.3s ease;
    opacity: 0;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  &:hover::before {
    opacity: 1;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${COLORS.white};

      &::before {
        opacity: 1;
      }

      & > div {
        background: ${COLORS.green};
      }
    `}

  ${Center};

  &.tooltip::after {
    width: fit-content;

    position: absolute;
    bottom: 70%;
    left: 100%;
    width: max-content;
    max-width: 12rem;
    content: ${({ tooltipText }) => `'${tooltipText}'`};
    padding: ${`${SPACES.xxs} ${SPACES.xs}`};

    ${Fonts};

    border: 1px solid ${COLORS.black50};
    border-radius: ${SPACES.m};

    background-color: ${COLORS.white};
    box-shadow: ${SHADOWS.xxl};

    visibility: hidden;
    z-index: 2;

    transition: visibility ${`${TRANSITIONS.duration.semi_slow} ${TRANSITIONS.function.linear}`};
  }

  ${({ tooltipText }) =>
    tooltipText &&
    css`
      &.tooltip:hover::after {
        visibility: visible;
      }
    `}
`;

export const UserName = styled.p`
  cursor: pointer;

  display: flex;
  gap: 10px;

  & > .isVisible {
    transform: rotate(-180deg);
  }
`;

export const Setting = styled(CommonStyled.Setting)`
  max-width: 13rem;

  top: 3.4rem;
  right: 1.5rem;
  z-index: 10;
`;

export const Div = styled(CommonStyled.Div)``;

export const SettingItem = styled(CommonStyled.SettingItem2)``;

export const Line = styled.div`
  width: 100%;
  height: 2px;
  background: ${COLORS.black250};
`;
