import React, {useEffect, useRef, useState} from 'react';
import {useQueryClient} from 'react-query';
import {useLocation, useNavigate} from 'react-router-dom';

import {Icons} from '../../assets';
import {useAdminQuery} from '../../hooks/admin';
import {COLORS, SPACES} from '../../theme';
import {IGetOneUser, IProductResponse, IProductResponseSuperAdmin, IStatus} from '../../types';
import {FilePath, statusTransform} from '../../utils';
import {
    Button,
    Drawer,
    Input,
    InputDefault,
    NavigationComponentButton,
    NavigationComponentFilters
} from '../common/component';
import {APP_KEYS} from '../common/constants';
import {useConnectionSocket} from '../common/hooks';
import {DivCommon, SubTitleCommon, TitleCommon} from '../common/styles';
import {Filters, IFiltersData, InventoryTable, InventoryTableSuperAdmin} from './component';
import * as Styled from './inventory-managerstyled';

interface IOpenDrawer {
    flag: boolean;
    component: '' | 'filter';
}

export const InventoryManager = () => {
    const client = useQueryClient();
    const location = useLocation();
    const navigate = useNavigate();
    useConnectionSocket({role: 'admin'});

    // const searchParams = new URLSearchParams(location.search);
    // const status = (searchParams.get('status') as IStatus) ?? 'Pending';

    const dataMainUser = client.getQueryData<IGetOneUser | undefined>(
        APP_KEYS.QUERY_KEYS.GET_MAIN_USER
    );
    const roleMainUser = dataMainUser?.user.role;
    const [openDrawer, setOpenDrawer] = useState<IOpenDrawer>({
        flag: false,
        component: ''
    });
    const [filters, setFilters] = useState<IFiltersData>({
        search: '',
        companies: [],
        users: [],
        category: '',
        country: '',
        manufacturer_mame: '',
        sold: null,
        status: undefined,
        last_name: '',
        first_name: '',
        orders: {}
    });

    const [page, setPage] = useState<number>(1);
    const [isDownload, setIsDownload] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement | null>(null);

    const {
        isLoading: isLoadingAdmin,
        isFetching: isFetchingAdmin,
        data: dataAdmin
    } = useAdminQuery.all_info_product(
        {
            page,
            is_dashboard: false,
            status: filters.status,
            role: roleMainUser,
            is_download: isDownload
        },
        filters
    );

    const {
        isLoading: isLoadingSuperAdmin,
        isFetching: isFetchingSuperAdmin,
        data: dataSuperAdmin
    } = useAdminQuery.all_info_product_super_admin(
        {
            page,
            is_dashboard: false,
            status: filters.status,
            role: roleMainUser,
            is_download: isDownload
        },
        filters
    );

    useEffect(() => {
        if (isDownload) {
            if (!isFetchingAdmin && !isFetchingSuperAdmin) {
                setIsDownload(false);
            }
        }
    }, [isDownload, isFetchingAdmin, isFetchingSuperAdmin]);

    const onPageChange = (_page: number) => {
        setPage(_page);

        if (ref.current) {
            // @ts-ignore
            ref.current.scrollTo({top: 0, behavior: 'smooth'});
        }
    };

    const onToggleDrawer = ({flag, component}: IOpenDrawer) => {
        setOpenDrawer((prev) => {
            prev.flag = flag;
            prev.component = component;
            return {...prev};
        });
    };

    const onClose = () => {
        onToggleDrawer({flag: false, component: ''});
    };

    const onNavigate = () => {
        navigate(APP_KEYS.ROUTER_KEYS.CREATE_ITEM_PUBLIC);
    };

    const onChange = (v: string) => {
        setFilters((prev) => ({...prev, search: v}));
    };

    return (
        <DivCommon>
            <TitleCommon mb={SPACES.s}>Inventory Manager</TitleCommon>
            <DivCommon fd='row' ai='center' jc='space-between'>
                <SubTitleCommon color={COLORS.black30}>
                    Showing {dataAdmin?.company_products?.length ?? dataSuperAdmin?.products?.length} out of{' '}
                    {dataAdmin?.count ?? dataSuperAdmin?.count} items
                </SubTitleCommon>

                {
                    dataMainUser?.user.role !== 'viewer' && (
                        <Button content='Add Item' width='fit-content' onClick={onNavigate}/>
                    )
                }
            </DivCommon>
            <DivCommon
                width='fi'
                fd='row'
                margin={`${SPACES.xxxxxxls} 0 ${SPACES.xxxxl} 0`}
                jc='space-between'
                ai='center'
            >
                <InputDefault
                    propValue={filters.search}
                    onChange={onChange}
                    name='search'
                    placeholder='search'
                    mt={SPACES.l}
                    mb={SPACES.l}
                    width='500px'
                />

                {/*<NavigationComponentButton*/}
                {/*    isPage={status}*/}
                {/*    array={*/}
                {/*        roleMainUser === 'super_admin'*/}
                {/*            ? [statusTransform('Pending'), statusTransform('Archived')]*/}
                {/*            : [*/}
                {/*                statusTransform('Pending'),*/}
                {/*                statusTransform('Approved'),*/}
                {/*                statusTransform('Rejected'),*/}
                {/*                statusTransform('Sent'),*/}
                {/*                statusTransform('Archived')*/}
                {/*            ]*/}
                {/*    }*/}
                {/*/>*/}

                <NavigationComponentFilters
                    array={[
                        {
                            icon: FilePath(Icons.arrowBottomIcon),
                            text: 'Show All Filters',
                            onClick: onToggleDrawer.bind(this, {flag: true, component: 'filter'})
                        },
                        {
                            icon: FilePath(Icons.download),
                            text: 'Download CSV',
                            onClick: () => setIsDownload(true)
                        }
                    ]}
                />
            </DivCommon>

            <Styled.TableContainer>
                {(roleMainUser === 'super_admin' ||  roleMainUser === 'viewer') ? (
                    <InventoryTableSuperAdmin
                        // pageStatus={status}
                        isManager
                        products={dataSuperAdmin?.products as IProductResponseSuperAdmin[]}
                        count={dataSuperAdmin?.count as number}
                        page={page}
                        onPageChange={onPageChange}
                        isLoading={isLoadingSuperAdmin || isFetchingSuperAdmin}
                        setFilters={setFilters}
                        orders={filters.orders}
                    />
                ) : (
                    <InventoryTable
                        // pageStatus={status}
                        isManager
                        products={dataAdmin?.company_products as IProductResponse[]}
                        count={dataAdmin?.count ?? 0}
                        page={page}
                        onPageChange={onPageChange}
                        isLoading={isLoadingAdmin || isFetchingAdmin}
                        orders={filters.orders}
                    />
                )}
            </Styled.TableContainer>

            <Drawer onClose={onClose} open={openDrawer.flag}>
                {openDrawer.component === 'filter' && (
                    <Filters
                        onClose={onClose}
                        filters={filters}
                        setFilters={setFilters}
                        isSuperAdmin={roleMainUser === 'super_admin'}
                    />
                )}
            </Drawer>
        </DivCommon>
    );
};
