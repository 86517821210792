import {Form, Formik, getIn} from 'formik';
import React, {useState} from 'react';
import {useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';

import {Icons} from '../../../../assets';
import {
    useCategoryMutation, useCategoryQuery,
    useCompanyQuery,
    useManufacturersMutation, useManufacturersQuery,
    useProductMutation,
    useProductQuery
} from '../../../../hooks';
import {COLORS, FONTS, SPACES} from '../../../../theme';
import {IFilesResponse, IGetOneUser, IImageResponse, IStatus} from '../../../../types';
import {FilePath, dateTransform, statusTransform} from '../../../../utils';
import {SoldPopup} from '../../../auction-tracker/component';
import {
    Button,
    Camera,
    Drawer,
    Input,
    InputMatchedWords,
    InputMatchedWordsAndCheckBox,
    InputTextArea,
    Loader,
    Portal
} from '../../../common/component';
import {toastContainer} from '../../../common/component/toast';
import {APP_KEYS, Country, State} from '../../../common/constants';
import {Category, InputsConst, ManufacturerNames} from '../../../common/constants';
import {useConnectionSocket} from '../../../common/hooks';
import {DivCommon, IconCommon, SubTitleCommon, TitleCommon} from '../../../common/styles';
import {Iuuid} from '../../../common/types';
import {ImageEdit} from '../../component';
import {validationSchemaProduct} from '../../validation/shema';
import {CompanyInfo} from './CompanyInfo';
import * as Styled from './single-product.styled';
import {PlusContainer} from './single-product.styled';

interface IOpenDrawer {
    flag: boolean;
    index: null | number;
    component: null | 'sold';
}

export const SingleProduct = () => {
    const navigate = useNavigate();
    const params = useParams<{ id: string | undefined }>();

    const queryClient = useQueryClient();
    const mainUser: IGetOneUser | undefined = queryClient.getQueryData(
        APP_KEYS.QUERY_KEYS.GET_MAIN_USER
    );
    const userRole = mainUser?.user.role;

    const [openDrawer, setOpenDrawer] = useState<IOpenDrawer>({
        flag: false,
        index: null,
        component: null
    });

    const [isOpenCamera, setIsOpenCamera] = useState(false);

    // @ts-ignore
    const id = params.id as Iuuid;

    const client = useQueryClient()

    const {isLoading, data} = useProductQuery.findOne(id);
    const dataUser  = client.getQueryData<IGetOneUser | undefined>(APP_KEYS.QUERY_KEYS.GET_MAIN_USER);


    const {data: dataCompanies} = useCompanyQuery.all(
        {isCompanyUser: true},
        {
            // enabled: isAdmin
        }
    );

    const { data: dataCategory} = useCategoryQuery.all()
    const { data: dataManufacturers} = useManufacturersQuery.all()

    const companies =
        dataCompanies?.companies && dataCompanies?.companies.length
            ? dataCompanies.companies.map((v) => ({
                name: v.name,
                id: v?.id,
                location: v.location
            }))
            : [];

    const _status = statusTransform(data?.product?.status ?? 'Now Status');

    const {socket} = useConnectionSocket({role: 'admin'});

    const {mutate: mutateStatus, isLoading: isLoadingStatus} = useProductMutation.status(
        {
            status: _status?.status ?? 'Pending',
            page: 0,
            is_dashboard: false,
            id,
            role: userRole
        },
        socket
    );

    const {mutate: manufacturersMutate} = useManufacturersMutation.post();
    const {mutate: categoryMutate} = useCategoryMutation.post();

    const {mutate: mutateSold, isLoading: isLoadingSold} = useProductMutation.sold({id}, socket);

    const {mutate: mutatePut, isLoading: isLoadingPut} = useProductMutation.put(
        {
            status: _status?.status ?? 'Pending',
            page: 0,
            is_dashboard: false,
            id,
            role: userRole
        },
        socket
    );

    const isLoadingData = isLoadingSold || isLoadingStatus || isLoading || isLoadingPut;

    const isAuction = ['Sent', 'In Auction', 'Out of Auction', 'Sold at Auction'].includes(status);

    const onToggleDrawer = ({flag, index, component}: IOpenDrawer) => {
        setOpenDrawer((prev) => {
            prev.flag = flag;
            prev.index = index;
            prev.component = component;
            return {...prev};
        });
    };

    const onClose = () => {
        onToggleDrawer({
            flag: false,
            index: null,
            component: null
        });
    };

    const onSoldSubmit = (_data: any) => {
        mutateStatus({
            product_id: id,
            status: 'Sold at Auction',
            price: _data.price
        });
        onClose();
    };

    const onChangeStatus = (_s: IStatus) => {
        _status?.status !== _s &&
        mutateStatus({
            product_id: id,
            status: _s
        });
    };

    const onToggleSold = (flag: boolean) => {
        mutateSold({
            product_id: id,
            is_listed: flag
        });
    };

    const onSubmit = (_data: any) => {
        if (_data.category_other && _data.category === 'Other') {
            _data.category = _data.category_other;
            categoryMutate({name: _data.category_other})

        }

        if (_data.country_other && _data.country === 'Other') {
            _data.country = _data.country_other;
        }

        if (_data.manufacturer_mame_other && _data.manufacturer_mame === 'Other') {
            _data.manufacturer_mame = _data.manufacturer_mame_other;
            manufacturersMutate({name:_data.manufacturer_mame_other})

        }

        delete _data.country_other;
        delete _data.category_other;
        delete _data.is_spare_parts;
        delete _data.manufacturer_mame_other;
        delete _data.path_file;
        delete _data.company_info;
        delete _data.location;
        delete _data.showCompanyName;

        mutatePut({
            ..._data,
            product_id: id
        });
    };

    const created_at = dateTransform(data?.product.createdAt, true);

    const onBack = () => {
        navigate(-1);
    };

    if (isLoading || isLoadingSold) {
        return <Loader size='small' color={COLORS.green} height='auto'/>;
    }

    // const location = data?.product.user.location;
    // const locations = Array.isArray(location) ? location : [location];

    const onSave = (value: IImageResponse, images: IImageResponse[], setFieldValue: any) => {
        const imagesMute = images.map((v: IImageResponse) => ({
            ...v,
            path: value.id === v.id ? value.path : v.path,

            is_primary_photo:
                value.id === v.id && value.is_primary_photo
                    ? value.is_primary_photo
                    : value.is_primary_photo
                        ? false
                        : v.is_primary_photo,
            is_manufacturer_tag:
                value.id === v.id && value.is_manufacturer_tag
                    ? value.is_manufacturer_tag
                    : value.is_manufacturer_tag
                        ? false
                        : v.is_manufacturer_tag,
            description: value.id === v.id ? value.description : ''
        }));
        setFieldValue('images', imagesMute);

        onClose();
    };

    const onDelete = (index: number, images: IImageResponse[], setFieldValue: any) => {
        const updatedImages = [...images];

        updatedImages.splice(index, 1);
        setFieldValue('images', updatedImages);

        onClose();
    };

    const onCloseCamera = (flag?: boolean) => {
        setIsOpenCamera(flag ?? false);
    };

    const setSingleImage = (image: any, setValues: any) => {
        setValues((v: any) => {
            const images = getIn(v, 'images');

            return {
                ...v,
                images: [
                    ...images,
                    {
                        path: image,
                        product_id: images[0].product_id,
                        is_manufacturer_tag: false,
                        is_primary_photo: false,
                        description: ''
                    }
                ]
            };
        });
    };

    return (
        <Styled.InfoContainer>
            <TitleCommon mb={SPACES.l}>Inventory Item</TitleCommon>
            <DivCommon
                width='fit-content'
                onClick={onBack}
                margin={`${SPACES.l} 0 ${SPACES.xxxxxxls} 0 `}
                fd='row'
                gap={SPACES.xxs}
                ai='center'
            >
                <IconCommon
                    icon={FilePath(Icons.arrowLeftIcon2)}
                    height='1rem'
                    background={COLORS.green}
                    cursor='pointer'
                />
                <SubTitleCommon ta='center' color={COLORS.green} cursor='pointer'>
                    Back
                </SubTitleCommon>
            </DivCommon>

            <Formik
                initialValues={{
                    name: data?.product.name ?? '',
                    item_location: data?.product.item_location ?? '',
                    category: data?.product?.category ?? '',
                    city: data?.product?.city ?? '',
                    state: data?.product?.state ?? '',
                    zip: data?.product?.zip ?? '',
                    manufacturer_mame: data?.product?.manufacturer_mame ?? '',
                    is_listed: data?.product?.is_listed ?? false,
                    model_number: data?.product?.model_number ?? '',
                    description: data?.product?.description ?? '',
                    spare_parts: data?.product?.spare_parts ?? '',
                    country: data?.product?.country ?? '',
                    auction_link: data?.product?.auction_link ?? '',
                    images: data?.product?.images ?? [],
                    files: data?.product?.files ?? [],
                    company_info: companies.find((v) => v.id === data?.product.company_id) ?? {},
                    showCompanyName: data?.product.company_name ? true : !data?.product.company_id,
                    companyTest: data?.product.company_name ?? ''
                }}
                onSubmit={onSubmit}
                enableReinitialize
                validationSchema={validationSchemaProduct(1, true, true)}
            >
                {({isValid, values, setFieldValue, setValues}) => (
                    <Form>
                        {isOpenCamera && (
                            <Portal>
                                <Camera
                                    onClose={onCloseCamera.bind(this, false)}
                                    onSuccess={onCloseCamera}
                                    images={[]}
                                    setImages={() => {
                                    }}
                                    isSingle
                                    setSingleImage={(image: any) => setSingleImage(image, setValues)}
                                />
                            </Portal>
                        )}
                        <DivCommon fd='row' gap={SPACES.xxxxxl}>
                            <Styled.Container>
                                <DivCommon fd='row' jc='space-between'>
                                    <TitleCommon fw={FONTS.WEIGHTS.semi_bold}>{data?.product.name}</TitleCommon>
                                    <TitleCommon>Item {data?.product.id}</TitleCommon>
                                </DivCommon>
                                <DivCommon
                                    margin={`${SPACES.l} 0 ${SPACES.l} 0 `}
                                    fd='row'
                                    jc='space-between'
                                    ai='center'
                                >
                                    <Styled.SettingItem backgroundColor={_status?.background}>
                                        <IconCommon background={COLORS.white} icon={_status?.icon}/>
                                        {_status?.status}
                                    </Styled.SettingItem>
                                    <SubTitleCommon ff={FONTS.FAMILIES.source_sans_pro}>{created_at}</SubTitleCommon>
                                </DivCommon>

                                <Input mt={SPACES.xxxxl} height='3.5rem' {...InputsConst.name} />

                                <InputMatchedWords
                                    matchedWords={dataCategory?.categorys.map((v)=> v.name) ?? []}
                                    height='3.5rem'
                                    mt={SPACES.xxxxl}
                                    isFilter
                                    isFilterVisibleAllData
                                    {...InputsConst.category}
                                />

                                {getIn(values, 'category') === 'Other' && (
                                    <Input mt={SPACES.xxxxl} height='3.5rem' {...InputsConst.category_other} />
                                )}

                                {/*<InputMatchedWordsAndCheckBox*/}
                                {/*    isVisibleCheckBox={false}*/}
                                {/*    companies={companies}*/}
                                {/*    showCompanyName={values?.showCompanyName}*/}
                                {/*/>*/}

                                {/* //TODO wait until the client decides */}
                                {/* <InputMatchedWords */}
                                {/*    matchedWords={locations} */}
                                {/*    height='3.5rem' */}
                                {/*    mt={SPACES.l} */}
                                {/*    readOnlyKeyboard */}
                                {/*    {...InputsConst.location} */}
                                {/* /> */}

                                {/*<CompanyInfo*/}
                                {/*    companies={companies}*/}
                                {/*/>*/}

                                {/*{!!getIn(values, 'company_info')?.id ? (*/}
                                {/*    <InputMatchedWords*/}
                                {/*        matchedWords={*/}
                                {/*            companies.find((val) => val.id === getIn(values, 'company_info')?.id)*/}
                                {/*                ?.location ?? []*/}
                                {/*        }*/}
                                {/*        height='3.5rem'*/}
                                {/*        visibleItem='city'*/}
                                {/*        mt={SPACES.xxxxl}*/}
                                {/*        isEdit*/}
                                {/*        isFilter*/}
                                {/*        isFilterVisibleAllData*/}
                                {/*        readOnlyKeyboard*/}
                                {/*        {...InputsConst.location}*/}
                                {/*    />*/}
                                {/*) : null}*/}

                                <Input mt={SPACES.xxxxl} height='3.5rem' {...InputsConst.item_location} />

                                {/*<InputMatchedWords*/}
                                {/*    matchedWords={Country}*/}
                                {/*    height='3.5rem'*/}
                                {/*    mt={SPACES.xxxxl}*/}
                                {/*    isFilter*/}
                                {/*    isEdit*/}
                                {/*    isFilterVisibleAllData*/}
                                {/*    {...InputsConst.country}*/}
                                {/*    readOnly={!values?.showCompanyName}*/}
                                {/*/>*/}
                                {/*<InputMatchedWords*/}
                                {/*    matchedWords={}*/}
                                {/*    height="3.5rem"*/}
                                {/*    visibleItem="name"*/}
                                {/*    mt={SPACES.xxxxl}*/}
                                {/*    isEdit*/}
                                {/*    isFilter*/}
                                {/*    isFilterVisibleAllData*/}
                                {/*    readOnlyKeyboard*/}
                                {/*    {...InputsConst.company_info}*/}
                                {/*/>*/}

                                {/*{getIn(values, 'country') === 'Other' && (*/}
                                {/*    <Input mt={SPACES.xxxxl} height='3.5rem' {...InputsConst?.country_other} />*/}
                                {/*)}*/}

                                {/*{getIn(values, 'country') !== 'Other' && (*/}
                                {/*    <InputMatchedWords*/}
                                {/*        matchedWords={State[getIn(values, 'country')]}*/}
                                {/*        height='3.5rem'*/}
                                {/*        mt={SPACES.xxxxl}*/}
                                {/*        isEdit*/}
                                {/*        isFilter*/}
                                {/*        isFilterVisibleAllData*/}
                                {/*        {...InputsConst.state}*/}
                                {/*        readOnly={!values?.showCompanyName}*/}
                                {/*    />*/}
                                {/*)}*/}

                                {/*{(*/}
                                {/*    [*/}
                                {/*        ...(getIn(values, 'country') !== 'Other' ? [] : ['state']),*/}
                                {/*        'city'*/}
                                {/*    ] as (keyof typeof InputsConst)[]*/}
                                {/*).map((name, index) => (*/}
                                {/*    <Input*/}
                                {/*        key={index}*/}
                                {/*        mt={SPACES.xxxxl}*/}
                                {/*        height='3.5rem'*/}
                                {/*        {...InputsConst[name]}*/}
                                {/*        readOnly={!values?.showCompanyName}*/}
                                {/*    />*/}
                                {/*))}*/}
                                {/*<Input*/}
                                {/*    mt={SPACES.xxxxl}*/}
                                {/*    height='3.5rem'*/}
                                {/*    {...InputsConst.zip}*/}
                                {/*    type='number'*/}
                                {/*    readOnly={!values?.showCompanyName}*/}
                                {/*/>*/}

                                <InputMatchedWords
                                    matchedWords={dataManufacturers?.manufacturers.map((v)=> v.name) ?? []}
                                    height='3.5rem'
                                    mt={SPACES.xxxxl}
                                    isFilter
                                    isFilterVisibleAllData
                                    {...InputsConst?.manufacturer_mame}
                                />

                                {getIn(values, 'manufacturer_mame') === 'Other' && (
                                    <Input
                                        mt={SPACES.xxxxl}
                                        height='3.5rem'
                                        {...InputsConst.manufacturer_mame_other}
                                    />
                                )}

                                <Input mt={SPACES.xxxxl} height='3.5rem' {...InputsConst.model_number} />
                                <Input mt={SPACES.xxxxl} height='3.5rem' {...InputsConst.auction_link} isOptional/>

                                {values.files.length ? (
                                    <Styled.Ul>
                                        <SubTitleCommon>Files</SubTitleCommon>
                                        {values.files.map((v: IFilesResponse, index: number) => (
                                            <Styled.Li key={index}>
                                                <IconCommon
                                                    icon={FilePath(Icons.copyIcon)}
                                                    cursor='pointer'
                                                    onClick={async () => {
                                                        await navigator.clipboard.writeText(v.path);
                                                        toastContainer.success({title: 'LINK COPIED!'});
                                                    }}
                                                />
                                                <SubTitleCommon>{v.path}</SubTitleCommon>
                                            </Styled.Li>
                                        ))}
                                    </Styled.Ul>
                                ) : null}

                                {/* {values.path_file ? ( */}
                                {/*  <Input */}
                                {/*    mt={SPACES.xxxxl} */}
                                {/*    readOnly */}
                                {/*    startIcon={{ */}
                                {/*      icon: FilePath(Icons.copyIcon), */}
                                {/*      cursor: 'pointer', */}
                                {/*      height: SPACES.xxxxl, */}
                                {/*      background: COLORS.black, */}
                                {/*      onClick: async () => { */}
                                {/*        await navigator.clipboard.writeText(values.path_file); */}
                                {/*        toastContainer.success({ title: 'LINK COPIED!' }); */}
                                {/*      } */}
                                {/*    }} */}
                                {/*    height='3.5rem' */}
                                {/*    {...InputsConst.path_file} */}
                                {/*  /> */}
                                {/* ) : null} */}

                                <InputTextArea
                                    rows={5}
                                    maxLength={1000}
                                    mt={SPACES.xxxxl}
                                    {...InputsConst.description}
                                />

                                <InputTextArea
                                    rows={2}
                                    maxLength={1000}
                                    mt={SPACES.xxxxl}
                                    name='spare_parts'
                                    label='Spare Parts and Accessories Information'
                                    placeholder=''
                                />

                                {
                                    dataUser?.user.role !== 'viewer' ? (
                                        <DivCommon margin={`${SPACES.l} 0 0 0`} width='100%' fd='row' jc='space-between'>
                                            <DivCommon width='fit-content' fd='row' gap={SPACES.l}>
                                                {_status?.status !== 'New Opportunity' && (
                                                    <Button
                                                        width='fit-content'
                                                        content='New Opportunity'
                                                        // widthIcon={SPACES.l}
                                                        //   startIcon={FilePath(statusTransform('New Opportunity').icon)}
                                                        onClick={onChangeStatus.bind(this, 'New Opportunity')}
                                                    />
                                                )}
                                                {_status?.status !== 'Hot Lead' && (
                                                    <Button
                                                        width='fit-content'
                                                        content='Hot Lead'
                                                        // widthIcon={SPACES.l}
                                                        // startIcon={FilePath(statusTransform('Hot Lead').icon)}
                                                        onClick={onChangeStatus.bind(this, 'Hot Lead')}
                                                    />
                                                )}

                                                {_status?.status !== 'Medium Lead' && (
                                                    <Button
                                                        width='fit-content'
                                                        content='Medium Lead'
                                                        // widthIcon={SPACES.l}
                                                        // startIcon={FilePath(statusTransform('Medium Lead').icon)}
                                                        onClick={onChangeStatus.bind(this, 'Medium Lead')}
                                                    />
                                                )}

                                                <Button
                                                    width='fit-content'
                                                    content={'Mark as Not Listed'}
                                                    onClick={onToggleSold.bind(this, false)}
                                                />
                                                <Button
                                                    width='fit-content'
                                                    content='Mark as Currently Listed'
                                                    onClick={onToggleSold.bind(this, true)}
                                                />


                                                {/* {['Rejected', 'Pending'].includes(status) && !isAuction && ( */}
                                                {/*  <Button */}
                                                {/*    width='fit-content' */}
                                                {/*    content='Approve Item' */}
                                                {/*    widthIcon={SPACES.l} */}
                                                {/*    startIcon={FilePath(statusTransform('Approved').icon)} */}
                                                {/*    onClick={onChangeStatus.bind(this, 'Approved')} */}
                                                {/*  /> */}
                                                {/* )} */}

                                                {/* {status === 'Approved' && !isAuction && ( */}
                                                {/*  <Button */}
                                                {/*    width='fit-content' */}
                                                {/*    content='Sent' */}
                                                {/*    widthIcon={SPACES.l} */}
                                                {/*    startIcon={FilePath(statusTransform('Sent').icon)} */}
                                                {/*    onClick={onChangeStatus.bind(this, 'Sent')} */}
                                                {/*  /> */}
                                                {/* )} */}

                                                {/* {isAuction && !['In Auction', 'Sent', 'Sold at Auction'].includes(status) && ( */}
                                                {/*  <Button */}
                                                {/*    width='fit-content' */}
                                                {/*    content='Sent for Auction' */}
                                                {/*    widthIcon={SPACES.l} */}
                                                {/*    startIcon={FilePath(statusTransform('In Auction').icon)} */}
                                                {/*    onClick={onChangeStatus.bind(this, 'In Auction')} */}
                                                {/*  /> */}
                                                {/* )} */}

                                                {/* {!isAuction && !['Rejected', 'Archived'].includes(status) && ( */}
                                                {/*  <Button */}
                                                {/*    width='fit-content' */}
                                                {/*    content='Reject Item' */}
                                                {/*    variant='delete' */}
                                                {/*    widthIcon={SPACES.l} */}
                                                {/*    startIcon={FilePath(Icons.rejectedIcon)} */}
                                                {/*    onClick={onChangeStatus.bind(this, 'Rejected')} */}
                                                {/*  /> */}
                                                {/* )} */}

                                                {/* {userRole === 'super_admin' && ['Sent', 'In Auction'].includes(status) && ( */}
                                                {/*  <> */}
                                                {/*    {status !== 'Sent' && ( */}
                                                {/*      <Button */}
                                                {/*        width='fit-content' */}
                                                {/*        content='Sold' */}
                                                {/*        widthIcon={SPACES.l} */}
                                                {/*        startIcon={FilePath(Icons.successIcon2)} */}
                                                {/*        onClick={onToggleDrawer.bind(this, { */}
                                                {/*          flag: true, */}
                                                {/*          index: null, */}
                                                {/*          component: 'sold' */}
                                                {/*        })} */}
                                                {/*      /> */}
                                                {/*    )} */}

                                                {/*    <Button */}
                                                {/*      width='fit-content' */}
                                                {/*      content='Out of Auction' */}
                                                {/*      variant='delete' */}
                                                {/*      widthIcon={SPACES.l} */}
                                                {/*      startIcon={FilePath(Icons.rejectedIcon)} */}
                                                {/*      onClick={onChangeStatus.bind(this, 'Out of Auction')} */}
                                                {/*    /> */}
                                                {/*  </> */}
                                                {/* )} */}

                                                {/* {status !== 'Archived' && ( */}
                                                {/*  <Button */}
                                                {/*    width='fit-content' */}
                                                {/*    content='Archive Item' */}
                                                {/*    variant='inverse' */}
                                                {/*    startIcon={FilePath(Icons.companiesIcon)} */}
                                                {/*    widthIcon={SPACES.l} */}
                                                {/*    onClick={onChangeStatus.bind(this, 'Archived')} */}
                                                {/*  /> */}
                                                {/* )} */}

                                                {/* {status === 'Archived' && ( */}
                                                {/*  <Button */}
                                                {/*    width='fit-content' */}
                                                {/*    content='Renew' */}
                                                {/*    variant='inverse' */}
                                                {/*    startIcon={FilePath(Icons.companiesIcon)} */}
                                                {/*    widthIcon={SPACES.l} */}
                                                {/*    onClick={onChangeStatus.bind(this, 'Pending')} */}
                                                {/*  /> */}
                                                {/* )} */}
                                            </DivCommon>

                                            <Button
                                                width='fit-content'
                                                content={
                                                    !isLoadingData ? (
                                                        'Save'
                                                    ) : (
                                                        <Loader size='small' color={COLORS.green} height='auto'/>
                                                    )
                                                }
                                                type='submit'
                                                disabled={!isValid || isLoadingData}
                                            />
                                        </DivCommon>
                                    ): null
                                }
                            </Styled.Container>

                            <Styled.Container2>
                                {
                                    dataUser?.user.role !=='viewer' ? (<PlusContainer>
                                        <IconCommon
                                            onClick={onCloseCamera.bind(this, true)}
                                            icon={FilePath(Icons.plusIcon)}
                                            className='icon'
                                        />
                                    </PlusContainer>) : null
                                }


                                {(values.images.sort((a, b) => {
                                    if (a.is_primary_photo === true) {
                                        return -1;
                                    }
                                    if (b.is_primary_photo === true) {
                                        return 1;
                                    }
                                    return 0;
                                })[0] as IImageResponse) ? (
                                    <Styled.Image
                                        index={0}
                                        {...values.images[0]}
                                        onClick={onToggleDrawer.bind(this, {
                                            flag: true,
                                            index: 11,
                                            component: null
                                        })}
                                    />
                                ) : null}

                                {values.images && values.images?.length > 0
                                    ? values.images
                                        .slice(1, values.images?.length)
                                        ?.map((value: IImageResponse, index) => (
                                            <Styled.Image
                                                key={index}
                                                index={index + 1}
                                                {...value}
                                                onClick={onToggleDrawer.bind(this, {
                                                    flag: true,
                                                    index: index + 1,
                                                    component: null
                                                })}
                                            />
                                        ))
                                    : null}
                            </Styled.Container2>
                        </DivCommon>

                        {openDrawer.flag && openDrawer.component === 'sold' && (
                            <SoldPopup onClose={onClose} onSubmit={onSoldSubmit}/>
                        )}

                        {openDrawer.flag && openDrawer.index && (
                            <Drawer
                                slidePosition='right'
                                contentPosition='right'
                                onClose={onClose}
                                open={openDrawer.flag}
                            >
                                <ImageEdit
                                    onSave={(v) => onSave(v, values.images, setFieldValue)}
                                    onDelete={() =>
                                        onDelete(
                                            openDrawer.index === 11 ? 0 : (openDrawer.index as number),
                                            values.images,
                                            setFieldValue
                                        )
                                    }
                                    onClose={onClose}
                                    image={
                                        openDrawer.index === 11
                                            ? values.images[0]
                                            : values.images[openDrawer.index as number]
                                    }
                                    isCreate={false}
                                    isEdit
                                />
                            </Drawer>
                        )}
                    </Form>
                )}
            </Formik>
        </Styled.InfoContainer>
    );
};
