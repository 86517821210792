export const RoleConst = ['guest', 'user', 'admin', 'super_admin'];

export const returnUserRoles = (current_role?: string) => {
  if (RoleConst.includes(current_role as string)) {
    return RoleConst.filter(
      (role) => RoleConst.indexOf(role) < RoleConst.indexOf(current_role as string)
    );
  } else {
    return [];
  }
};
