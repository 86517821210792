import {EnhancedWithAuthHttpService, HttpFactoryService} from '../module/common/services';
import {ExpectedFromQuery, IMessage} from '../module/common/types';
import {IManufacturerAll, IManufacturerPost} from "../types/manufacturers.type";
import {ICategoryAll, ICategoryPost} from "../types/category.type";

class CategoryServiceService {
    constructor(private httpService: EnhancedWithAuthHttpService) {
    }

    async all(): Promise<ExpectedFromQuery<ICategoryAll>> {
        return this.httpService.get<ICategoryAll>('/category/all');
    }

    async post(data: ICategoryPost): Promise<ExpectedFromQuery<IMessage>> {
        return this.httpService.post<IMessage, ICategoryPost>('/category', data);
    }
}

const factory = new HttpFactoryService();
export const categoryServiceService = new CategoryServiceService(factory.createAuthHttpService());
