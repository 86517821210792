import { UseQueryResult, useQuery } from 'react-query';

import { APP_KEYS } from '../../module/common/constants';
import { guestService } from '../../services';
import { IGetAllFiltersGuests, IGetAllGuests } from '../../types';

const all = (data: IGetAllFiltersGuests, options: any): UseQueryResult<IGetAllGuests | undefined> =>
  useQuery([APP_KEYS.QUERY_KEYS.GET_ALL_GUESTS, data], () => guestService.all(data), {
    ...options
  });

export const useGuestQuery = {
  all
};
