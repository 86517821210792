import React, { useEffect, useRef, useState } from 'react';

import * as Styled from './stepper.styled';

export interface IStepperProps {
  step: number;
  stepperArray: any[];
}

export const Stepper = ({ step, stepperArray }: IStepperProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [childrenWidth, setChildrenWidth] = useState<string>('10%');

  useEffect(() => {
    const calculateChildrenWidth = () => {
      if (ref.current && ref.current?.children) {
        const childrenRef = ref.current?.children;
        // @ts-ignore
        const childrenRefHTML = Array.from(childrenRef ?? []) as HTMLCollectionOf<HTMLElement>;
        const childrenRefHTMLArray = Array.from(childrenRefHTML);

        const onCalculateChildrenWidth = (index: number) => {
          const findChildren = childrenRefHTMLArray[index];
          setChildrenWidth(`${findChildren?.offsetWidth / 2.6 + findChildren.offsetLeft}px`);
        };

        if (step >= stepperArray.length) {
          onCalculateChildrenWidth(stepperArray.length - 1);
        } else if (step > 0) {
          onCalculateChildrenWidth(step);
        }
      }
    };

    calculateChildrenWidth();

    window.addEventListener('resize', calculateChildrenWidth);

    return () => {
      window.removeEventListener('resize', calculateChildrenWidth);
    };
  }, [step]);

  return (
    <Styled.Container id='stepper_container'>
      <Styled.DivLabel ref={ref} id='stepper_container_label'>
        {stepperArray.map((v, index) => (
          <Styled.StepTitle key={index} isStep={step >= index} id='stepper_label'>
            {v}
          </Styled.StepTitle>
        ))}
      </Styled.DivLabel>

      <Styled.Line id='stepper_line' />
      <Styled.LineGreen id='stepper_line_active' childrenWidth={childrenWidth} />
      <Styled.StepPointer id='stepper_pointer' childrenWidth={childrenWidth} />
    </Styled.Container>
  );
};
