import { UseQueryResult, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { APP_KEYS } from '../../module/common/constants';
import { Iuuid } from '../../module/common/types';
import { urlUtil } from '../../module/common/utils';
import { productService } from '../../services';
import { IGetAllFiltersProduct, IGetMyAllProducts, IGetSingleProduct } from '../../types';

const myAll = (
  data: Partial<IGetAllFiltersProduct>,
  options?: any
): UseQueryResult<IGetMyAllProducts | undefined> =>
  useQuery([APP_KEYS.QUERY_KEYS.GET_ALL_PRODUCTS_MY, data], () => productService.myAll(data), {
    ...options
  });

const findOne = (id: Iuuid, isEnabled = true): UseQueryResult<IGetSingleProduct> => {
  const navigate = useNavigate();

  return useQuery([APP_KEYS.QUERY_KEYS.GET_PRODUCT, id], () => productService.findOne(id), {
    ...(isEnabled && { enabled: !!id }),
    onError: () => navigate(urlUtil(APP_KEYS.ROUTER_KEYS.INVENTORY_PRODUCT)),
    refetchOnWindowFocus: true
  });
};

export const useProductQuery = {
  myAll,
  findOne
};
