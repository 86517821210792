import { getIn, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { SPACES } from '../../../../theme';
import { InputsConst, commonInformation } from '../../constants';
import { DivCommon, Fonts } from '../../styles';
import { CheckBoxFormik, Input, InputMask, InputMatchedWords } from '../inputs';

export const InputContainer = ({
  readOnly = false,
  objectName
}: {
  readOnly?: boolean;
  objectName?: any;
}) => (
  <>
    {commonInformation.map(({ name, type }, index) => (
      <Input
        key={index}
        mt={SPACES.xxxxl}
        readOnly={readOnly}
        type={type}
        height='3.5rem'
        {...InputsConst[name]}
        name={objectName ? `${objectName}.${name}` : name}
      />
    ))}
    <InputMask
      height='3rem'
      mt={SPACES.xxxxl}
      mask='999-999-9999'
      readOnly={readOnly}
      {...InputsConst.phone}
      name={objectName ? `${objectName}.phone` : 'phone'}
    />
  </>
);

export const InputCompany = styled(DivCommon)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  margin-top: ${SPACES.xxxxl};

  & > span {
    ${Fonts};
  }
`;

export const InputMatchedWordsAndCheckBox = ({
  isVisibleCheckBox,
  showCompanyName,
  companies,
  isAdmin = false
}: {
  isVisibleCheckBox: boolean;
  showCompanyName: boolean;
  companies: any[];
  isAdmin?: boolean;
}) => {
  const { values, setFieldValue } = useFormikContext();

  const company_info = getIn(values, 'company_info');
  const location = getIn(values, 'location');

  useEffect(() => {
    if (location) {
      setFieldValue('country', location.country);
      setFieldValue('city', location.city);
      setFieldValue('state', location.state);
      setFieldValue('zip', location.zip);
    }
  }, [location]);

  return (
    <div>
      <InputCompany>
        <span>Company Name</span>
        {isVisibleCheckBox ? null : (
          <CheckBoxFormik
            name='showCompanyName'
            label='Self Owned'
            inReverse
            height='1rem'
            // readOnly={isAdmin}
          />
        )}
      </InputCompany>

      {!isAdmin ? (
        <>
          {!showCompanyName ? (
            <Input
              height='3.5rem'
              {...InputsConst.company_info}
              type='text'
              label=''
              name={'companyTest'}
              mt='0'
            />
          ) : (
            <InputMatchedWords
              matchedWords={companies}
              visibleItem='name'
              height='3.5rem'
              isDontChange
              isEdit
              readOnlyKeyboard
              {...InputsConst.company_info}
              isErrorVisible
              name='company_info'
              label={''}
              mt={SPACES.xxxs}
              mb='0'
            />
          )}
        </>
      ) : (
        <Input
          height='3.5rem'
          {...InputsConst.company_info}
          type='text'
          label=''
          name={'companyTest'}
          mt='0'
          readOnly={showCompanyName}
        />
      )}
    </div>
  );
};
