import styled from 'styled-components';

import { COLORS, FONTS, SPACES } from '../../../../theme';
import { Center, Fonts } from '../../styles';

export const Container = styled.div`
  width: 100%;

  margin: 0 0 ${SPACES.xxxxxxls} 0;
`;

export const DivLabel = styled.div`
  width: 100%;
  
  ${Center};
  justify-content: space-between;
  
   padding: 0 20px;
   position: 'relative;

`;

export const StepTitle = styled.p<{ isStep: boolean }>`
  ${Fonts};
  font-size: ${FONTS.SIZES.l};
  font-weight: ${FONTS.WEIGHTS.semi_bold};
  line-height: ${FONTS.SIZES.xxl};

  color: ${({ isStep }) => (isStep ? COLORS.black : COLORS.black100)};
`;

export const Line = styled.div`
  height: 2px;
  width: 100%;
  background: ${COLORS.black150};
  position: absolute;
  bottom: ${SPACES.l};
`;

export const LineGreen = styled(Line)<{ childrenWidth: string }>`
  width: ${({ childrenWidth }) => childrenWidth};
  background: ${COLORS.green};
`;

export const StepPointer = styled.div<{ childrenWidth: string }>`
  height: 0.75rem;
  width: 0.75rem;

  background: ${COLORS.white};
  border: 1px solid ${COLORS.green};

  border-radius: 50%;
  position: absolute;
  bottom: 0.675rem;

  left: ${({ childrenWidth }) => childrenWidth};
`;
