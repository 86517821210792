import {Formik} from 'formik';
import React, {memo, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate, useNavigationType} from 'react-router-dom';


import {Icons} from '../../../../assets';
import {useProductMutation, useManufacturersMutation, useManufacturersQuery, useCategoryQuery, useCategoryMutation} from '../../../../hooks';
import {COLORS, FONTS, SPACES} from '../../../../theme';
import {FilePath} from '../../../../utils';
import {Button, Camera, Loader, Stepper} from '../../../common/component';
import {APP_KEYS} from '../../../common/constants';
import {useConnectionSocket, useLocalStorage} from '../../../common/hooks';
import {SubTitleCommon, TitleCommon} from '../../../common/styles';
import {StepEnd, StepFour, StepOne, StepThree, StepTow, Warning} from '../../component';
import {TitleSubtitleConst} from '../../constants';
import {validateForm, validationSchemaProduct} from '../../validation/shema';
import * as Styled from './create-product.styled';


export const CreateProduct = memo(() => {

    const {socket} = useConnectionSocket({});
    const {post} = useProductMutation;
    const {mutate: manufacturersMutate} = useManufacturersMutation.post();
    const {mutate: categoryMutate} = useCategoryMutation.post();
    useManufacturersQuery.all()
    useCategoryQuery.all()

    const {isLoading, mutate} = post(socket);
    const navigation = useNavigate();

    const [step, setStep] = useState(0);
    const [isEdit, setIsEdit] = useState(false);
    const [isOpenCamera, setIsOpenCamera] = useState(false);

    const [images, setImages] = useState<any[]>([]);

    const {isToken, decoded} = useLocalStorage().get(APP_KEYS.STORAGE_KEYS.TOKEN as 'token');

    const {sub_title, title, buttonText} = TitleSubtitleConst(step);

    const onNextStepState = () => setStep((prev) => prev + 1);
    const onPrevStepState = () => {
        if (step === 0) {
            navigation(-1);
        }

        setStep((prev) => prev - 1);
    };

    const onEdit = (_step: number, flag: boolean) => {
        setStep(_step);
        setIsEdit(flag);
    };

    const onNextStep = (data: any, setTouched?: any) => {
        const _data = {...data};

        delete _data.auction_link;

        const isFormValid = validateForm(_data, step, isToken);

        if (isFormValid) {
            onNextStepState();
        } else {
            const touchedObject: any = {};

            for (const key in data) {
                if (key === 'user_info') {
                    touchedObject.user_info = {};
                    for (const key2 in data.user_info) {
                        touchedObject.user_info[key2] = true;
                    }
                } else {
                    touchedObject[key] = true;
                }
            }

            setTouched(touchedObject);
        }
    };

    const onCloseCamera = () => {
        !images.length && onPrevStepState();

        setIsOpenCamera(false);
    };
    const onOpenCamera = () => setIsOpenCamera(true);

    const onOpenCameraAndNextStep = () => {
        onOpenCamera();
        onNextStepState();
    };

    const containerRef = useRef<HTMLDivElement | null>(null);

    const onScrollTo = () => {
        window.scrollTo(0, 0);
    };

    const onClickButton = (values: any, setTouched?: any) => {
        if (!isEdit) {
            step === 2 ? onOpenCameraAndNextStep() : onNextStep(values, setTouched);

            console.log('======', step);
            if (containerRef.current) {
                // @ts-ignore
                containerRef.current?.scrollIntoView();
            }
        } else {
            onEdit(4, false);
        }
        onScrollTo();
    };

    const isAdmin = ['super_admin', 'admin'].includes(decoded?.type ?? '');

    const onSubmit = (data: any) => {
        if (data.manufacturer_mame_other && data.manufacturer_mame === 'Other') {
            data.manufacturer_mame = data.manufacturer_mame_other;
            manufacturersMutate({name: data.manufacturer_mame_other})
        }

        if (data.category_other && data.category === 'Other') {
            data.category = data.category_other;
            categoryMutate({name: data.category_other})
        }

        if (data.country_other && data.country === 'Other') {
            data.country = data.country_other;
        }

        if (!isAdmin) {
            delete data.company_info;
            delete data.admin_user_info;
        }

        delete data.is_spare_parts;
        delete data.manufacturer_mame_other;
        delete data.category_other;
        delete data.country_other;
        delete data.location;
        delete data.showCompanyName;

        mutate(data);
    };

    const user_info = {
        first_name: '',
        last_name: '',
        email: '',
        phone: ''
    };

    const initialValues = {
        ...(isToken ? {} : {user_info}),
        // ...(isAdmin
        //   ? {
        //       company_info: {
        //         name: '',
        //         id: ''
        //       },
        //       admin_user_info: {
        //         email: '',
        //         id: ''
        //       }
        //     }
        //   : {}),
        name: '',
        item_location: '',
        // country: '',
        // city: '',
        // state: '',
        // zip: '',
        category: '',
        category_other: '',
        country_other: '',
        manufacturer_mame: '',
        manufacturer_mame_other: '',
        model_number: '',
        description: '',
        auction_link: '',
        files: [],
        is_spare_parts: false,
        spare_parts: '',
        images: [],
        showCompanyName: isAdmin
    };

    // const [inputsEnd, setInputsEnd] = useState<string[]>(() => ['name', 'category', 'item_location']);
    // useEffect(() => {
    //   if (!isToken) {
    //     setInputsEnd(() => inputsEndDefault);
    //     return;
    //   }
    //   if (isAdmin) {
    //     // setInputsEnd(() => [...inputsEndDefault, 'company_info', 'admin_user_info']);
    //     setInputsEnd(() => [...inputsEndDefault]);
    //     return;
    //   }
    //   // else {
    //   //   setInputsEnd(() => ['name', 'category', 'city', 'state', 'zip']);
    //   //   return;
    //   // }
    // }, []);


    return (
        <Styled.Container background={COLORS.white50} id='container'>
            {isOpenCamera && (
                <Camera
                    onClose={onCloseCamera}
                    onSuccess={onCloseCamera}
                    images={images}
                    setImages={setImages}
                />
            )}
            {window.innerWidth < 600 || isAdmin ? (
                <Styled.ContainerContent>
                    {step < 6 ? (
                        <Styled.HeaderDiv maxWidth='22.5rem'>
                            <Stepper step={step} stepperArray={['Basics', 'Product', 'Photos']}/>
                        </Styled.HeaderDiv>
                    ) : null}

                    <Styled.BodyDiv>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            enableReinitialize
                            validationSchema={validationSchemaProduct(step, isToken, undefined, isAdmin)}
                        >
                            {({isValid, values, setTouched}) => (
                                <Styled._Form>
                                    {step < 4 && (
                                        <Styled.ContainerInfo ref={containerRef}>
                                            <Styled.GoBack onClick={onPrevStepState}>
                                                <Styled.Icon icon={FilePath(Icons.arrowLeftIcon2)}/>
                                                Back
                                            </Styled.GoBack>

                                            <TitleCommon color={COLORS.black20} ta='center'
                                                         fw={FONTS.WEIGHTS.semi_bold}>
                                                {title}
                                            </TitleCommon>
                                            <SubTitleCommon ml={SPACES.l} color={COLORS.black20} ta='center'>
                                                {sub_title}
                                            </SubTitleCommon>

                                            {step === 0 ? <StepOne/> : null}
                                            {step === 1 ? <StepTow/> : null}
                                            {step === 2 ? <StepThree/> : null}
                                            {step === 3 ? (
                                                <StepFour
                                                    step={step}
                                                    setImages={setImages}
                                                    images={images}
                                                    onOpenCamera={onOpenCamera}
                                                    onNextStep={onNextStepState}
                                                />
                                            ) : null}

                                            {step <= 2 && (
                                                <Button
                                                    mt={SPACES.l}
                                                    content={isEdit ? 'Continue to Item Review' : buttonText}
                                                    variant='primary'
                                                    type='button'
                                                    onClick={onClickButton.bind(this, values, setTouched)}
                                                    disabled={step !== 2 && !isValid}
                                                />
                                            )}
                                        </Styled.ContainerInfo>
                                    )}

                                    {step === 4 && (
                                        <>
                                            <TitleCommon
                                                mt={SPACES.xxxxxxls}
                                                mb={SPACES.xxxxl}
                                                ta='center'
                                                color={COLORS.black20}
                                                fw={FONTS.WEIGHTS.semi_bold}
                                            >
                                                Review Inventory Item
                                            </TitleCommon>

                                            {/*//TODO wait until the client decides (location) */}
                                            <StepEnd
                                                values={values}
                                                onEdit={onEdit.bind(this, 0, true)}
                                                title='Basic Information'
                                                inputs={isToken ? ['name', 'category', 'item_location'] : ['name', 'category', 'item_location', 'first_name',
                                                    'last_name',
                                                    'email',
                                                    'phone']}
                                            />

                                            <StepEnd
                                                onEdit={onEdit.bind(this, 1, true)}
                                                values={values}
                                                title='Product Information'
                                                inputs={[
                                                    'manufacturer_mame',
                                                    'manufacturer_mame_other',
                                                    'model_number',
                                                    'auction_link',
                                                    'description',
                                                    'files',
                                                    'spare_parts'
                                                ]}
                                            />

                                            <StepEnd values={values} title='Photos'
                                                     onEdit={onEdit.bind(this, 3, true)}/>

                                            <Button
                                                mt={SPACES.l}
                                                content={
                                                    !isLoading ? (
                                                        'Submit for Approval'
                                                    ) : (
                                                        <Loader size='small' color={COLORS.green} height='auto'/>
                                                    )
                                                }
                                                variant='primary'
                                                type='submit'
                                                disabled={!isValid || isLoading}
                                            />

                                            <Button
                                                mt={SPACES.l}
                                                onClick={onPrevStepState}
                                                content='Cancel'
                                                variant='inverse2'
                                                type='submit'
                                            />
                                        </>
                                    )}
                                </Styled._Form>
                            )}
                        </Formik>
                    </Styled.BodyDiv>

                    {/*<Nav/>*/}
                </Styled.ContainerContent>
            ) : null}

            {window.innerWidth > 600 && !isAdmin ? <Warning/> : null}
        </Styled.Container>
    );
});
