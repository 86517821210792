import styled, { css } from 'styled-components';

import { COLORS, SPACES } from '../../../../theme';
import { Center, Fonts } from '../../../common/styles';

export const Container = styled.div`
  width: 100%;

  gap: ${SPACES.m};
  margin-top: ${SPACES.xxxxl};

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Image = styled.div<{ isBorder: boolean; image: any }>`
  width: 47%;
  aspect-ratio: 1/1;
  cursor: pointer;

  background: url(${({ image }) => image});
  background-position: center;
  background-size: cover;
  position: relative;

  ${({ isBorder }) =>
    isBorder &&
    css`
      border: 4px solid ${COLORS.green};
    `}
`;

export const Image2 = styled.div<{
  is_primary_photo: boolean;
  is_manufacturer_tag: boolean;
  image: any;
}>`
  width: 47%;
  aspect-ratio: 1/1;
  cursor: pointer;
  position: relative;

  background: url(${({ image }) => image});
  background-position: center;
  background-size: cover;

  &::before,
  &::after {
    content: '';
    height: 0.75rem;
    aspect-ratio: 1/1;
    border: 1px solid ${COLORS.white};

    border-radius: 50%;
    position: absolute;
    bottom: 0;
    left: 0.75rem;
    transform: translate(-50%, -50%);
    display: none;
  }

  ${({ is_primary_photo }) =>
    is_primary_photo &&
    css`
      &::before {
        background-color: ${COLORS.blue20};
        display: block;
      }
    `};

  ${({ is_manufacturer_tag }) =>
    is_manufacturer_tag &&
    css`
      &::after {
        background-color: ${COLORS.purple20};
        display: block;
      }
    `};

  ${({ is_manufacturer_tag, is_primary_photo }) =>
    is_manufacturer_tag &&
    is_primary_photo &&
    css`
      &::after {
        left: 1.2rem;
      }
    `};
`;

export const AddContainer = styled.div`
  width: 45%;
  aspect-ratio: 1/1;
  gap: ${SPACES.s};

  border: 1px dashed ${COLORS.black100};

  ${Center};
  flex-direction: column;

  & > img {
    width: 1rem;
    aspect-ratio: 1/1;
  }

  ${Fonts};
  color: ${COLORS.black30};

  cursor: pointer;
`;

export const Line = styled.div`
  width: 100%;
  height: 2px;

  background: ${COLORS.black250};
`;

export const CircleContainer = styled.div`
  width: 100%;

  padding: ${SPACES.l} 2rem;

  ${Center};

  gap: 2rem;
  justify-content: space-between;
`;

export const Circle = styled.div<{ index: number }>`
  position: relative;

  ${Fonts};

  ${Center};

  &::before {
    content: '';
    height: 0.75rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: ${({ index }) => (index === 0 ? COLORS.blue20 : COLORS.purple20)};

    position: relative;
    left: -0.5rem;
  }
`;
