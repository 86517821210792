import { getIn, useField, useFormikContext } from 'formik';
import React from 'react';

import { Icons } from '../../../../../assets';
import { COLORS, SPACES } from '../../../../../theme';
import { IFiles } from '../../../../../types';
import { FilePath } from '../../../../../utils';
import { fileService } from '../../../services';
import { IconCommon } from '../../../styles';
import { IMargin } from '../../../types';
import * as StyledMatched from '../input-matched-words/input-matched-words.styled';
import * as Styled from '../input/input.styled';

export interface IInputFile extends IMargin {
  label: string;
  name: string;
  height?: string;
  required?: boolean;
  isOptional?: boolean;

  accept: string;
  multiple?: boolean;
}

export const InputFile = ({
  name,
  required,
  isOptional,
  label,
  multiple = false,
  accept,
  ...props
}: IInputFile) => {
  const [field, { touched, error }] = useField(name);

  const { values, setFieldValue, setValues, errors } = useFormikContext();

  const value = getIn(values, name);

  const isMultipleChecking = Array.isArray(value) || multiple;

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputFiles = e.target.files;

    if (!inputFiles) {
      // Handle the case when inputFiles is null
      return;
    }

    const files: IFiles[] = [];

    // Create an array of promises for converting files
    const filePromises = Array.from(inputFiles).map(async (file) => {
      const fileBase64 = await fileService.convertBase64(file);
      files.push({ name: file.name, path: fileBase64 });
    });

    await Promise.all(filePromises);

    if (!isMultipleChecking) {
      setFieldValue(name, files[0]);
    } else {
      setValues((v: any) => {
        const _value = getIn(v, name);
        return { ...v, [name]: [..._value, ...files] };
      });
    }
  };

  const deleteItem = (index: number) => {
    setValues((v: any) => {
      const _value = getIn(v, name);
      _value.splice(index, 1);

      return { ...v, [name]: [..._value] };
    });
  };

  const isError = touched && error;
  return (
    <Styled.Wrapper isLabel={!!label} {...props}>
      {label && (
        <Styled.Label required={required} htmlFor={name} isError={!!isError}>
          {isMultipleChecking ? `${label}s` : label}
          {isOptional && <Styled.LabelOptional>· Optional</Styled.LabelOptional>}
        </Styled.Label>
      )}

      <Styled.Input
        id={name}
        autoComplete='off'
        isError={!!isError}
        type='text'
        readOnly
        {...props}
        {...field}
        value={''}
      />

      <Styled.InputFile
        id={`file-input-${name}`}
        type='file'
        accept={accept}
        height={props.height}
        multiple={isMultipleChecking}
        onChange={onChange}
        title=''
      />

      {isError && error !== 'is required' ? <Styled.Error>{error}</Styled.Error> : null}

      {isMultipleChecking && value?.length ? (
        <StyledMatched.ChipContainer margin={`${SPACES.xxs} 0 0 0 `}>
          {value.map((v: IFiles, index: number) => (
            <StyledMatched.Chip onClick={deleteItem.bind(this, index)} key={index}>
              {v.name}
              <IconCommon
                height='0.625rem'
                cursor='pointer'
                icon={FilePath(Icons.closeIcon)}
                background={COLORS.black}
              />
            </StyledMatched.Chip>
          ))}
        </StyledMatched.ChipContainer>
      ) : null}
    </Styled.Wrapper>
  );
};
