import { getIn, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { Icons } from '../../../../assets';
import { SPACES } from '../../../../theme';
import { IImage } from '../../../../types';
import { FilePath } from '../../../../utils';
import { Button, Drawer } from '../../../common/component';
import { DivCommon } from '../../../common/styles';
import { TextButtonStepFourConst } from '../../constants';
import { IOpenDrawer, IStepFour } from '../../types';
import { ImageEdit } from '../image-edit/image-edit';
import * as Styled from './step-four.styled';
import { CircleContainer } from './step-four.styled';

export const StepFour = ({ images, onOpenCamera, step, onNextStep, setImages }: IStepFour) => {
  const { buttonText } = TextButtonStepFourConst(step);

  const { values, setFieldValue } = useFormikContext();

  const value = getIn(values, 'images');

  const [image2, setImage2] = useState<IImage[]>(value || []);
  const [openDrawer, setOpenDrawer] = useState<IOpenDrawer>({
    flag: false,
    index: null
  });

  const [imageTeg, setImageTeg] = useState<{
    is_primary_photo: null | number;
    is_manufacturer_tag: null | number;
  }>({
    is_primary_photo: null,
    is_manufacturer_tag: null
  });

  const onToggleDrawer = ({ flag, index }: IOpenDrawer) => {
    setOpenDrawer((prev) => {
      prev.flag = flag;
      prev.index = index;
      return { ...prev };
    });
  };

  const onClose = () => {
    onToggleDrawer({ flag: false, index: null });
  };

  const onAddTeg = (index: number) => {
    // if (step === 3 || step === 4)
    //     setImageTeg((prev) => {
    //         if (step === 3) {
    //             prev.is_primary_photo = index;
    //         }
    //         if (step === 4) {
    //             prev.is_manufacturer_tag = index;
    //         }
    //         return {...prev};
    //     });
    // else {
    onToggleDrawer({ flag: true, index });
    // }
  };

  const onImagesMute = () => {
    const imagesMute = images.map((v, index) => ({
      path: v,
      is_primary_photo: imageTeg.is_primary_photo === index,
      is_manufacturer_tag: imageTeg.is_manufacturer_tag === index,
      description: ''
    }));
    setImage2(imagesMute);
  };

  const onClick = () => {
    // if (step === 3) {
    //     onNextStep();
    // } else if (step === 4) {
    //     onImagesMute();
    //     onNextStep();
    // } else {
    setFieldValue('images', image2);
    onNextStep();
    // }
  };

  const onDelete = () => {
    setImages((v: any) => {
      v.splice(openDrawer.index, 1);
      return [...v];
    });

    setImageTeg((prev) => {
      if (prev.is_primary_photo === openDrawer.index) {
        prev.is_primary_photo = null;
      }
      if (prev.is_manufacturer_tag === openDrawer.index) {
        prev.is_manufacturer_tag = null;
      }
      return { ...prev };
    });

    onClose();
  };

  const onSave = (image: any) => {
    setImageTeg((prev) => {
      if (image.is_primary_photo) {
        prev.is_primary_photo = openDrawer.index;
      }
      if (image.is_manufacturer_tag) {
        prev.is_manufacturer_tag = openDrawer.index;
      }

      return { ...prev };
    });

    setImages((v: any) => {
      // @ts-ignore
      v[openDrawer.index] = image.path;
      return [...v];
    });

    setImage2((v: any) => {
      // @ts-ignore
      v[openDrawer.index] = image;
      return [...v];
    });

    const imagesMute = images.map((v, index) => ({
      path: v,
      is_primary_photo:
        openDrawer.index === index ? image.is_primary_photo : imageTeg.is_primary_photo === index,
      is_manufacturer_tag:
        openDrawer.index === index
          ? image.is_manufacturer_tag
          : imageTeg.is_manufacturer_tag === index,
      description: openDrawer.index === index ? image.description : ''
    }));

    setImage2(imagesMute);
    onClose();
  };

  useEffect(() => {
    if (images.length !== image2.length) {
      onImagesMute();
    }
  }, [images]);

  const disabled = image2.length < 0;

  // (step === 3 && imageTeg.is_primary_photo === null) ||
  // (step === 4 && imageTeg.is_manufacturer_tag === null) ||
  // (step === 5 && (imageTeg.is_primary_photo === null || imageTeg.is_manufacturer_tag === null));

  return (
    <>
      {/*{step === 5 && (*/}
      {/*  <DivCommon margin={`${SPACES.l} 0 0 0`}>*/}
      {/*    <Styled.Line />*/}
      {/*    <CircleContainer>*/}
      {/*      {['Primary', 'Manufacturing Tag'].map((v, index) => (*/}
      {/*        <Styled.Circle key={index} index={index}>*/}
      {/*          {v}*/}
      {/*        </Styled.Circle>*/}
      {/*      ))}*/}
      {/*    </CircleContainer>*/}

      {/*    <Styled.Line />*/}
      {/*  </DivCommon>*/}
      {/*)}*/}
      <Styled.Container>
        {/*{step <= 4 &&*/}
        {/*  images.map((path, index) => {*/}
        {/*    const isBorder =*/}
        {/*      (step === 3 && imageTeg.is_primary_photo === index) ||*/}
        {/*      (step === 4 && imageTeg.is_manufacturer_tag === index);*/}
        {/*    return (*/}
        {/*      <Styled.Image*/}
        {/*        isBorder={isBorder}*/}
        {/*        key={index}*/}
        {/*        onClick={onAddTeg.bind(this, index)}*/}
        {/*        image={path}*/}
        {/*      />*/}
        {/*    );*/}
        {/*  })}*/}

        {image2.map(({ path, is_manufacturer_tag, is_primary_photo }, index) => (
          <Styled.Image2
            is_manufacturer_tag={is_manufacturer_tag}
            is_primary_photo={is_primary_photo}
            key={index}
            onClick={onAddTeg.bind(this, index)}
            image={path}
          />
        ))}

        {images.length <= 9 && step === 5 && (
          <Styled.AddContainer onClick={onOpenCamera}>
            <img src={FilePath(Icons.plusIcon)} alt='plus' />
            Add Photo
          </Styled.AddContainer>
        )}
      </Styled.Container>

      <Button
        mt={SPACES.l}
        content={buttonText}
        variant='primary'
        type='button'
        onClick={onClick}
        disabled={disabled}
      />

      <Drawer
        slidePosition='bottom'
        contentPosition='bottom'
        onClose={onClose}
        open={openDrawer.flag}
      >
        <ImageEdit
          onSave={onSave}
          onDelete={onDelete}
          onClose={onClose}
          image={image2[openDrawer.index as number]}
        />
      </Drawer>
    </>
  );
};
