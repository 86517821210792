import React, { memo, useEffect, useRef, useState } from 'react';

import { SPACES } from '../../../../../theme';
import { IconCommon } from '../../../styles';
import { IInputDefault, IInputProps } from '../../../types';
import * as Styled from './input.styled';

export const InputDefault = memo(
  ({
    name,
    label,
    gapFromLabel,
    textarea,
    checkbox,
    height,
    radio,
    startIcon,
    endIcon,
    innerPads,
    required,
    className,
    type = 'text',
    labelClassName,
    inputType = 1,
    isOptional = false,
    propValue,
    onChange,
    ...props
  }: IInputDefault) => {
    const pads =
      (startIcon ? `${SPACES.xs} ${SPACES.xxxxxxl_}` : innerPads) ||
      (type === 'password' ? `${SPACES.xs} ${SPACES.xxxxxxl} ${SPACES.xs} ${SPACES.m}` : undefined);

    const ref = useRef<HTMLDivElement | null>(null);

    const [top, setTop] = useState('50%');

    useEffect(() => {
      if (type === 'password' || startIcon || endIcon) {
        const calculateTopHeight = () => {
          const childrenRef = ref.current?.children;
          // @ts-ignore
          const childrenRefHTML = Array.from(childrenRef ?? []) as HTMLCollectionOf<HTMLElement>;
          const childrenRefHTMLArray = Array.from(childrenRefHTML).splice(0, 2);

          return (
            childrenRefHTMLArray.reduce((acc, child, i) => {
              if (i === 1) {
                return acc + child.offsetHeight / 2.5;
              }

              return acc + child.offsetHeight;
            }, 0) + 'px'
          );
        };

        setTop(calculateTopHeight());
      }
    }, []);

    return (
      <Styled.Wrapper className={className} isLabel={!!label} {...props} ref={ref} top={top}>
        {label && inputType === 1 && (
          <Styled.Label
            isError={false}
            required={required}
            htmlFor={name}
            className={labelClassName}
          >
            {label}
            {isOptional && <Styled.LabelOptional>· Optional</Styled.LabelOptional>}
          </Styled.Label>
        )}

        <Styled.Input
          height={height}
          id={name}
          name={name}
          autoComplete='off'
          isError={false}
          gapFromLabel={gapFromLabel}
          innerPads={pads}
          type='text'
          value={propValue}
          placeholder={props.placeholder}
          width={props.width}
          onChange={(e) => onChange(e.target.value)}
        />

        {label && inputType === 2 && (
          <Styled.Label2 htmlFor={name} className={labelClassName}>
            {label}
          </Styled.Label2>
        )}

        {startIcon && <IconCommon {...startIcon} className='startIcon' />}
        {endIcon && <IconCommon {...endIcon} className='endIcon' />}
      </Styled.Wrapper>
    );
  }
);
