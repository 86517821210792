import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { Icons } from '../../../../assets';
import { FilePath } from '../../../../utils';
import { Drawer } from '../../../common/component';
import { APP_KEYS } from '../../../common/constants';
import { useClickOutside, useLocalStorage } from '../../../common/hooks';
import { urlUtil } from '../../../common/utils';
import { UserCrud } from '../../../user-setting/component';
import * as Styled from './nav.styled';

export const Nav = () => {
  const navigate = useNavigate();
  const client = useQueryClient();
  const { pathname } = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);

  const onToggleDrawer = (flag: boolean) => {
    setOpenDrawer(flag);
  };
  const { decoded, isToken } = useLocalStorage().get(APP_KEYS.STORAGE_KEYS.TOKEN as 'token');

  const [isVisible, setIsVisible] = useState(false);

  const { ref } = useClickOutside(() => setIsVisible(false));

  const onNavigate = (to: string) => {
    navigate(to);
  };

  const onLogout = () => {
    client.removeQueries(APP_KEYS.QUERY_KEYS.GET_MAIN_USER);
    localStorage.removeItem(APP_KEYS.STORAGE_KEYS.TOKEN);
    onNavigate(APP_KEYS.ROUTER_KEYS.GUEST);
  };

  const onIsVisible = (flag: boolean) => {
    setIsVisible(flag);
  };

  return (
    <Styled.Container>
      <Styled.ContainerInfo>
        {[
          {
            icon: FilePath(Icons.home2),
            path: isToken
              ? urlUtil(APP_KEYS.ROUTER_KEYS.INVENTORY_DASHBOARD)
              : APP_KEYS.ROUTER_KEYS.GUEST
          },
          {
            icon: FilePath(Icons.plusIcon),
            path: APP_KEYS.ROUTER_KEYS.CREATE_ITEM_PUBLIC
          }
        ].map(({ icon, path }, i) => (
          <Styled.IconContainer
            onClick={onNavigate.bind(this, path)}
            isActive={pathname === path}
            key={i}
          >
            <Styled.Icon className='icon' icon={icon} />
          </Styled.IconContainer>
        ))}
        {isToken && (
          <Styled.IconContainer isActive={false} onClick={onIsVisible.bind(this, true)}>
            <Styled.Icon icon={FilePath(Icons.setting)} />

            {isVisible && (
              <Styled.Setting ref={ref}>
                {[
                  {
                    icon: FilePath(Icons.setting),
                    text: 'Account Settings',
                    onClick: () => {
                      onIsVisible(false);
                      onToggleDrawer(true);
                    }
                  },
                  {
                    icon: FilePath(Icons.logaut),
                    text: 'Logout',
                    onClick: onLogout
                  }
                ].map(({ icon, text, onClick }, index) => (
                  <Styled.Div key={index}>
                    <Styled.SettingItem className='item' onClick={onClick}>
                      <Styled.Icon icon={icon} />
                      {text}
                    </Styled.SettingItem>
                    <Styled.Line className='line' />
                  </Styled.Div>
                ))}
              </Styled.Setting>
            )}
          </Styled.IconContainer>
        )}
      </Styled.ContainerInfo>

      <Drawer onClose={onToggleDrawer.bind(this, false)} open={openDrawer}>
        <UserCrud
          onClose={onToggleDrawer.bind(this, false)}
          isView
          isCreate={false}
          id={decoded?.sub}
        />
      </Drawer>
    </Styled.Container>
  );
};
