import React, { useState } from 'react';

import { Icons } from '../../../../assets';
import { ICompanyResponse } from '../../../../types';
import { FilePath } from '../../../../utils';
import { Drawer } from '../../../common/component';
import * as StyledTable from '../../../common/component/table/table.styled';
import { IconCommon } from '../../../common/styles';
import { Iuuid } from '../../../common/types';
import { CompanyCrud } from '../company-crud/company-crud';

export interface ICompanyTable {
  companies: ICompanyResponse[];
}

export const CompanyTable = ({ companies }: ICompanyTable) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openId, setOpenId] = useState<Iuuid | undefined>(undefined);

  const onToggleDrawer = (flag: boolean) => {
    setOpenDrawer(flag);
  };

  const onClick = (id: any) => {
    onToggleDrawer(true);
    setOpenId(id);
  };

  return (
    <>
      <StyledTable.Table>
        <StyledTable.Head>
          <StyledTable.Row>
            <StyledTable.HeadRow>Company</StyledTable.HeadRow>
            <StyledTable.HeadRow>Location</StyledTable.HeadRow>
            <StyledTable.HeadRow>Primary Contact</StyledTable.HeadRow>
            <StyledTable.HeadRow>Email</StyledTable.HeadRow>

            <StyledTable.HeadRow className='edit' />
          </StyledTable.Row>
        </StyledTable.Head>
        <StyledTable.Body>
          {companies
            ? companies?.map(
                ({ email, name, location, first_name, last_name, id }: ICompanyResponse, index) => (
                  <StyledTable.Row key={`${id}${index}`} onClick={onClick.bind(this, id)}>
                    <StyledTable.Data className='title'>
                      <StyledTable.ItemLabel
                        className='tooltip'
                        linesToTruncate={1}
                        tooltipText={name}
                      >
                        {name}
                      </StyledTable.ItemLabel>
                    </StyledTable.Data>

                    <StyledTable.Data className='title'>
                      <StyledTable.ItemLabel
                        className='tooltip'
                        linesToTruncate={1}
                        tooltipText={location.map((v) => v.city).join(', ')}
                      >
                        {location.map((v) => v.city).join(', ')}
                      </StyledTable.ItemLabel>
                    </StyledTable.Data>

                    <StyledTable.Data className='title'>
                      <StyledTable.ItemLabel
                        className='tooltip'
                        linesToTruncate={1}
                        tooltipText={`${first_name} ${last_name}`}
                      >
                        {first_name} {last_name}
                      </StyledTable.ItemLabel>
                    </StyledTable.Data>

                    <StyledTable.Data className='title'>
                      <StyledTable.ItemLabel
                        className='tooltip'
                        linesToTruncate={1}
                        tooltipText={email}
                      >
                        {email}
                      </StyledTable.ItemLabel>
                    </StyledTable.Data>

                    <StyledTable.Data className='edit'>
                      <StyledTable.EditButton onClick={onClick.bind(this, id)}>
                        <IconCommon icon={FilePath(Icons.arrowRightIcon)} height='0.75rem' />
                      </StyledTable.EditButton>
                    </StyledTable.Data>
                  </StyledTable.Row>
                )
              )
            : null}
        </StyledTable.Body>
      </StyledTable.Table>
      <Drawer onClose={onToggleDrawer.bind(this, false)} open={openDrawer}>
        <CompanyCrud onClose={onToggleDrawer.bind(this, false)} isView id={openId} />
      </Drawer>
    </>
  );
};
