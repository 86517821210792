import styled, { css } from 'styled-components';

import { MEDIA, SPACES } from '../../../../theme';
import { Fonts } from '../../styles';
import { Button } from '../buttons';

export const Details = styled.details<{ styled: any }>`
  width: 100%;

  padding: ${SPACES.l} 0;

  & > summary::-webkit-details-marker {
    display: none;
  }

  ${({ styled }) => styled};
`;

export const Summary = styled.summary`
  list-style: none;
  display: flex;
  justify-content: space-between;

  overflow: auto;

  ${Fonts};

  & > img {
    transition: all 0.35s ease-out;
  }
`;

export const Span = styled.span`
  ${Fonts};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;

  gap: ${SPACES.xxxxl};
`;

export const FirstLayoutStyled = css`
  & > .children {
    @media screen and (max-width: ${MEDIA.tablet}) {
      padding: ${SPACES.l};
    }
  }
`;

export const Btn = styled(Button)`
  width: 100%;
  text-transform: uppercase;
`;
