import styled from 'styled-components';

import { SPACES } from '../../theme';
import { CommonPaginationContainer, CommonTableContainer } from '../common/styles';

export const TableContainer = styled(CommonTableContainer)`
  max-height: 32rem;
  padding: 0 ${SPACES.xxxxl};
`;
export const PaginationContainer = styled(CommonPaginationContainer)``;
