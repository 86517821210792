import React from 'react';
import { useNavigate } from 'react-router-dom';

import { COLORS, FONTS, SPACES } from '../../../../theme';
import { Button } from '../../../common/component';
import { APP_KEYS } from '../../../common/constants';
import { DivCommon, SubTitleCommon, TitleCommon } from '../../../common/styles';
import * as Styled from '../../../guest-pages/guest-pages.styled';

export const AfterSingUpPages = () => {
  const navigate = useNavigate();

  const onNavigate = (to: string) => {
    navigate(to);
  };

  return (
    <DivCommon width='100%' height='100vh' background={COLORS.white50} ai='center' jc='center'>
      <Styled.ContainerInfo width='90%' background={COLORS.white}>
        <TitleCommon mb={SPACES.l} color={COLORS.black20} ta='center' fw={FONTS.WEIGHTS.semi_bold}>
          Thank you for being with us!
        </TitleCommon>
        <SubTitleCommon ml={SPACES.l} color={COLORS.black20} mb='2.5rem' ta='center'>
          Admin will review your request and approve as soon as possible!
        </SubTitleCommon>

        <Button
          content='Login'
          mt={SPACES.l}
          type='button'
          variant='inverse2'
          onClick={onNavigate.bind(this, APP_KEYS.ROUTER_KEYS.LOGIN)}
        />
      </Styled.ContainerInfo>
    </DivCommon>
  );
};
