import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { toastContainer } from '../../module/common/component/toast';
import { APP_KEYS } from '../../module/common/constants';
import { IMessage, Iuuid } from '../../module/common/types';
import { userService } from '../../services';
import { IAuthError, IUserCreate, IUserEdit } from '../../types';

const onError = (_err: AxiosError<IAuthError>) => {
  const err = _err.response?.data as IAuthError;
  toastContainer.error({ title: err.message ?? _err.message });
};

const onSuccess = async ({ message }: IMessage) => {
  toastContainer.success({ title: message });
};

const post = () => {
  const client = useQueryClient();

  return useMutation<any, AxiosError<IAuthError>, IUserCreate>(
    (data: IUserCreate) => userService.post(data),
    {
      onSuccess: async (data) => {
        await onSuccess(data);

        await client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_ALL_USERS]);
      },
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};

interface IPutOptions {
  isMainUser?: boolean;
}

const put = (id?: Iuuid, options?: IPutOptions) => {
  const client = useQueryClient();
  return useMutation<any, AxiosError<IAuthError>, IUserEdit>(
    (data: IUserEdit) => userService.put(data, id),
    {
      onSuccess: async (data) => {
        await onSuccess(data);

        const invalidateQueriesPromises = [
          client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_USER, id]),
          client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_ALL_USERS])
        ];
        if (options?.isMainUser) {
          invalidateQueriesPromises.push(
            client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_MAIN_USER])
          );
        }

        await Promise.allSettled(invalidateQueriesPromises);
      },
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};

const deleteUser = (id?: Iuuid) => {
  const client = useQueryClient();
  return useMutation<any, AxiosError<IAuthError>>(() => userService.delete(id), {
    onSuccess: async (data) => {
      await onSuccess(data);

      await client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_ALL_USERS]);
    },
    onError: (err: AxiosError<IAuthError>) => onError(err)
  });
};

export const useUserMutation = {
  post,
  put,
  deleteUser
};
