import React, { ReactNode } from 'react';

import * as Styled from './page-layout.styled';
import {IconCommon} from "../../../common/styles";
import {FilePath} from "../../../../utils";
import {Icons} from "../../../../assets";
import {COLORS} from "../../../../theme";

export interface IPageLayout {
  isBackground?: boolean;
  children: ReactNode;
}

export const PageLayout = ({ children, isBackground = true }: IPageLayout) => (
  <Styled.Container>
      {isBackground && <Styled.BackgroundDiv isBackground={isBackground} >
          <IconCommon height='6rem' background={COLORS.green} icon={FilePath(Icons.logo)}/>
      </Styled.BackgroundDiv>}
    <Styled.Div isBackground={isBackground}>
      <Styled.Content>{children}</Styled.Content>
    </Styled.Div>
  </Styled.Container>
);
