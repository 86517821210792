import {AxiosError} from 'axios';
import {useMutation, useQueryClient} from 'react-query';

import {toastContainer} from '../../module/common/component/toast';
import {
    IAuthError,
} from '../../types';
import { categoryServiceService} from "../../services";
import { ICategoryPost} from "../../types";
import { APP_KEYS } from '../../module/common/constants';

const onError = (_err: AxiosError<IAuthError>) => {
    const err = _err.response?.data as IAuthError;
    toastContainer.error({title: err.message ?? _err.message});
};

const post = () => {
    const client = useQueryClient()
    return useMutation<any, AxiosError<IAuthError>, ICategoryPost>(
        (data: ICategoryPost) => categoryServiceService.post(data),
        {
            onSuccess : async ()=> {
                await client.invalidateQueries(APP_KEYS.QUERY_KEYS.GET_ALL_MANUFACTURES)
            },
            onError: (_err: AxiosError<IAuthError>) => {
                onError(_err);
            }
        }
    );
};


export const useCategoryMutation = {
    post
};
