import Webcam from 'react-webcam';
import styled from 'styled-components';

import { ReactComponent as CameraRotate } from '../../../../assets/icons/camera-rotate.svg';
import { COLORS, SPACES } from '../../../../theme';
import { Center } from '../../styles';
import { Button } from '../index';

export const Container = styled.div`
  width: 100%;
  height: 100dvh;
  background: ${COLORS.black};

  position: absolute;
  top: 0;
  z-index: 5;
`;

export const DivHeaderButton = styled.div`
  width: 100%;
  padding: ${SPACES.xxl} ${SPACES.l};

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DivTakePhoto = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  ${Center};
`;

export const DivFutterButton = styled(DivHeaderButton)`
  height: 10%;
  align-items: flex-start;
`;

export const WebcamTest = styled(Webcam)`
  max-height: 66%;
  padding: 0 ${SPACES.l};
`;

export const Div = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: ${SPACES.l};
`;

export const Image = styled.img<{ width?: string }>`
  width: ${({ width }) => width};
  height: 70%;
  padding: 0 ${SPACES.l};
  object-fit: contain;
`;

export const ToglCamera = styled.div`
  height: 30px;
  aspect-ratio: 1/1;

  border-radius: 50%;
  cursor: pointer;

  ${Center};
  background: ${COLORS.white};
`;

export const CameraswitchIconStyled = styled(CameraRotate)`
  height: 1.5rem;
  aspect-ratio: 1/1;
`;

export const TakeImag = styled.div`
  width: 72px;
  height: 72px;
  aspect-ratio: 1/1;

  border-radius: 50%;
  background: ${COLORS.white};
`;

export const Button1 = styled(Button)`
  color: ${COLORS.white};
  border: 1px solid ${COLORS.white};
  background: ${COLORS.black};
`;

export const Button2 = styled(Button)`
  color: ${COLORS.black};
  border: 1px solid ${COLORS.white};
`;

export const InputContainer = styled.div`
  width: fit-content;
  height: 49px;

  ${Center};

  position: absolute;
  left: ${SPACES.l};

  img {
    height: 100%;
    aspect-ratio: 1/1;
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
`;
