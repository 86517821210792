export const TitleSubtitleConst = (step: number) => {
  const title =
    (step === 0 && 'Basic Information') ||
    (step === 1 && 'Product Information') ||
    (step === 2 && 'Product Photos') ||
    (step === 3 && 'Photo Review');
  // (step === 3 && 'Select Primary Photo') ||
  // (step === 4 && 'Select Manufacturing Tag') ||

  const sub_title =
    (step === 0 && 'Please add the following basic information about your inventory item below.') ||
    (step === 1 && 'Input details related to the product.') ||
    (step === 2 && 'Take photos of the following listed below:') ||
    (step === 3 && 'Select the images to change labels, delete, or retake photos.');
  // (step === 3 && 'Please select the photo to be used for the inventory profile thumbnail.') ||
  // (step === 4 && 'Please select the photo that contains the manufacturing tag') ||

  const buttonText = (step < 2 && 'Continue') || (step === 2 && 'Open Camera');

  return { title, sub_title, buttonText };
};

export const TextButtonStepFourConst = (step: number) => {
  const buttonText =
    (step === 3 && 'Confirm Photos') ||
    (step === 4 && 'Confirm Manufacturing Tag') ||
    (step === 5 && 'Continue to Item Review');

  return { buttonText };
};
