import styled from 'styled-components';

import { COLORS, FONTS, SPACES } from '../../../../theme';
import { Fonts } from '../../../common/styles';

export const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${COLORS.black250};

  margin: ${SPACES.xxxxl} 0;
`;

export const Ul = styled.ul`
  display: flex;
  flex-direction: column;

  gap: ${SPACES.l};
`;

export const Li = styled.li`
  display: flex;
  align-items: center;
  gap: ${SPACES.l};
  ${Fonts};
  font-size: ${FONTS.SIZES.l};
  line-height: ${FONTS.SIZES.xxxxl};
  font-family: ${FONTS.FAMILIES.source_sans_pro};
`;

export const Circle = styled.div`
  height: 8px;
  aspect-ratio: 1/1;
  background-color: ${COLORS.green};
  border-radius: 50%;
`;
