import React from 'react';

import * as Styled from './step-three.styled';

export const StepThree = () => (
  <>
    <Styled.Line />

    <Styled.Ul>
      {[
        'Front facing photo for thumbnail',
        'Show Full Manufacturing Tag',
        'Model number',
        'Photos of equipment at different angles',
        'Photos of equipment specific features'
      ].map((v, index) => (
        <Styled.Li key={index}>
          <Styled.Circle />
          {v}
        </Styled.Li>
      ))}
    </Styled.Ul>
  </>
);
