import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { toastContainer } from '../../module/common/component/toast';
import { APP_KEYS } from '../../module/common/constants';
import { IMessage, Iuuid } from '../../module/common/types';
import { guestService } from '../../services';
import { IAuthError, IGuestEdit } from '../../types';

const onError = (_err: AxiosError<IAuthError>) => {
  const err = _err.response?.data as IAuthError;
  toastContainer.error({ title: err.message ?? _err.message });
};

const onSuccess = ({ message }: IMessage) => {
  toastContainer.success({ title: message });
};

interface IPut {
  data: Partial<IGuestEdit>;
  id?: Iuuid;
}

const put = (id?: Iuuid) => {
  const client = useQueryClient();
  return useMutation<any, AxiosError<IAuthError>, IPut>(
    ({ data, id: _id }: IPut) => guestService.put(data, id ?? _id),
    {
      onSuccess: async (data) => {
        onSuccess(data);
        await client.invalidateQueries([APP_KEYS.QUERY_KEYS.GET_ALL_GUESTS]);
      },
      onError: (err: AxiosError<IAuthError>) => onError(err)
    }
  );
};

export const useGuestMutation = {
  put
};
