import React from 'react';
import { Toaster } from 'react-hot-toast';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';

import * as theme from '../../theme';
import { toastContainer } from '../common/component/toast';
import { MainRouter } from '../navigation';
import * as Styled from './app.styled';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      cacheTime: Infinity
    }
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      // 🎉 only show error toasts if we already have data in the cache
      // which indicates a failed background update
      if (query.state.data !== undefined) {
        toastContainer.error({ title: 'error' });
      }
    }
  })
});

const env = process.env.REACT_APP_ENV;

const App = () => (
  <ThemeProvider theme={theme}>
    <Styled.GlobalStyles />
    <QueryClientProvider client={queryClient}>
      <MainRouter />
      {env === 'local' ? (
        <ReactQueryDevtools position='bottom-right' initialIsOpen={false} />
      ) : null}
      <Toaster position='top-center' reverseOrder />
    </QueryClientProvider>
  </ThemeProvider>
);

export default App;
