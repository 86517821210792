import * as Yup from 'yup';

import { commonValidation, validateSchema } from '../../common/validation/validate-shema';

const createValidationSchema = (
  step: number,
  isToken: boolean,
  isEdit?: boolean,
  isAdmin?: boolean
) => {
  const commonSchema = !isToken ? Yup.object().shape(commonValidation) : Yup.object().shape({});

  const company_infoSchema = Yup.object().shape({});
  const admin_user_infoSchema = Yup.object().shape({});

  const schema: {
    [key: string]: Yup.Schema<any>;
  } = {
    ...(!isToken ? { user_info: commonSchema } : {}),
    ...(isAdmin
      ? {
          company_info: company_infoSchema,
          admin_user_info: admin_user_infoSchema
        }
      : {}),
    name: validateSchema.text,
    item_location: validateSchema.text,
    category: validateSchema.text,
    // city: isAdmin ? validateSchema.text.optional() : validateSchema.text,
    // state: isAdmin ? validateSchema.text.optional() : validateSchema.text,
    // zip: isAdmin ? validateSchema.text.optional() : validateSchema.text
  };
  if (step === 1) {
    schema['manufacturer_mame'] = validateSchema.text;
    schema['model_number'] = validateSchema.text;
    schema['description'] = validateSchema.text;
    schema['auction_link'] = validateSchema.url.optional();
    schema['is_spare_parts'] = validateSchema.boolean.optional();
    schema['spare_parts'] = Yup.string().test('spare_parts', 'is required', function (value) {
      const isSpareParts = this.parent.is_spare_parts;
      if (isSpareParts && !value) {
        return this.createError({
          path: 'spare_parts',
          message: 'is required'
        });
      }
      return true;
    });

    schema['manufacturer_mame_other'] = Yup.string().test(
      'manufacturer_mame_other',
      'is required when manufacturer_mame is Other',
      function (value) {
        if (this.resolve(Yup.ref('manufacturer_mame')) === 'Other' && !value) {
          throw this.createError({ message: 'is required' });
        }
        return true;
      }
    );
  }

  if (isEdit) {
    schema['is_spare_parts'] = validateSchema.boolean.optional();
    schema['spare_parts'] = validateSchema.text.optional();
  }

  return Yup.object().shape(schema);
};

export const validationSchemaProduct = (
  step: number,
  isToken: boolean,
  isEdit?: boolean,
  isAdmin?: boolean
) => {
  return createValidationSchema(step, isToken, isEdit, isAdmin);
};

export const validateForm = (data: any, step: number, isToken: boolean) => {
  const validationSchema = createValidationSchema(step, isToken);

  try {
    validationSchema.validateSync(data, { abortEarly: false });
    return true; // Form is valid
  } catch (error) {
    return false; // Form is invalid
  }
};
