import styled from 'styled-components';

import { COLORS, SPACES } from '../../../../theme';
import { CloseButton } from '../../../common/component';
import { DrawerContainer } from '../../../common/styles';

export const Container = styled(DrawerContainer)``;

export const CloseBtn = styled(CloseButton)`
  margin: 0 0 0 auto;
  width: 1.5rem;
  height: 1.5rem;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${COLORS.black250};
`;

export const LocationContainer = styled.div`
  & > .header {
    margin-top: ${SPACES.l};

    & > img {
      cursor: pointer;
    }
  }

  & > div {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const UlLocation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-bottom: 1px solid gray;
    //gap: 10px;
  }
`;
