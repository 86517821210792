import styled, { css } from 'styled-components';

import { COLORS, FONTS, SHADOWS, SPACES, TRANSITIONS } from '../../../../theme';
import { Center, Fonts, IconCommon } from '../../styles';
import { Button } from '../buttons';

// makes table cells of even size
const layoutCss = css`
  display: table;
  width: 100%;
  table-layout: fixed;
`;

const tHeadRowAndTDataCommonStyle = css`
  padding: ${`${SPACES.xxxxl} ${SPACES.xxxxl}`};
`;

const cellControlledSizes = css`
  &.cell {
    width: 12.5rem;
  }
`;

// ================= table components START ====================//

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Head = styled.thead`
  ${layoutCss}
  border-bottom: 1px solid ${COLORS.black250};

  position: sticky;
  top: 0;
  z-index: 2;

  background: ${COLORS.white};
`;

export const Body = styled.tbody`
  ${layoutCss}
`;

export const Sort = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  
  cursor: pointer;
  
  & > img {
    height: 16px;
    aspect-ratio: 1/1;
  }
  
  & > span {
    width: 30px;
    font-weight: ${FONTS.WEIGHTS.medium};
    font-size: ${FONTS.SIZES.s};
    text-align: left;
    color: ${COLORS.black};
  }
`

export const HeadRow = styled.th`
  ${tHeadRowAndTDataCommonStyle};

  font-weight: ${FONTS.WEIGHTS.medium};
  font-size: ${FONTS.SIZES.s};
  text-align: left;
  color: ${COLORS.black};
  
  & > div {
    font-weight: ${FONTS.WEIGHTS.medium};
    font-size: ${FONTS.SIZES.s};
    text-align: left;
    color: ${COLORS.black};
    
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &.link {
    width: 5.375rem;
  }

  &.button {
    width: 4.375rem;
    padding-left: 0;
  }

  &.edit {
    width: 1.875rem;
    padding: 0;
  }

  &.title2 {
    width: 24.875rem;
    position: relative;
    padding: ${SPACES.l} 0 ${SPACES.l} ${SPACES.xxxxl};
    
    &:hover {
      cursor: pointer;
    }
  }
  

  ${cellControlledSizes}
`;

export const Row = styled.tr`
  ${layoutCss}
`;
export const NowData = styled.div`
  width: 100%;
  min-height: 200px;

  ${Center};
  ${Fonts};
`;
export const Data = styled.td`
  ${tHeadRowAndTDataCommonStyle};

  font-weight: ${FONTS.WEIGHTS.normal};
  font-size: ${FONTS.SIZES.m};
  vertical-align: middle;
  color: ${COLORS.black};



  &.link {
    width: 5.375rem;
    position: relative;
    &:hover {
      cursor: pointer;
    }
  }

  &.button {
    width: 4.375rem;
    padding-left: 0;
  }

  &.edit {
    width: 1.875rem;
    padding: 0;
  }

  &.title {
    position: relative;
    padding: ${SPACES.l} 0 ${SPACES.l} ${SPACES.xxxxl};

    &:hover {
      cursor: pointer;
    }
  }

  &.title2 {
    width: 24.875rem;
    position: relative;
    padding: ${SPACES.l} 0 ${SPACES.l} ${SPACES.xxxxl};

    &:hover {
      cursor: pointer;
    }
  }

  ${cellControlledSizes}
`;
// ================= table components END ====================//

// ================= helper components START ====================//

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ImagePlaceholderThumb = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2.5rem;
  height: 2.5rem;
  margin-left: ${({ theme }) => theme.SPACES.s};

  border-radius: 50%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.COLORS.gray200};
  cursor: pointer;
`;

// ================== icons END =================================//

// ================== item card fields START ====================//

const truncateStringStyles = css<{ linesToTruncate?: number }>`
  display: -webkit-box;
  -webkit-line-clamp: ${({ linesToTruncate }) => linesToTruncate ?? 1};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const labelPriceCommonStyles = css`
  ${Fonts};
`;

export const ItemImage = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  margin-left: ${SPACES.s};

  object-position: center;
  object-fit: cover;
  overflow: hidden;

  border-radius: 50%;
  cursor: pointer;
`;
// ${labelPriceCommonStyles}

export const ItemLabel = styled.strong<{
  linesToTruncate?: number;
  tooltipText: string;
  background?: string;
}>`
  & > a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  
  display: block;
  ${truncateStringStyles}
  ${labelPriceCommonStyles}
  cursor: pointer;
  &.tooltip::after {
    position: absolute;
    bottom: 70%;
    left: 40%;
    width: max-content;
    max-width: 12rem;
    content: ${({ tooltipText }) => `'${tooltipText}'`};
    ${Fonts};
    padding: ${`${SPACES.xxs} ${SPACES.xs}`};
    font-weight: ${FONTS.WEIGHTS.normal};
    font-size: ${FONTS.SIZES.s};
    border: 1px solid ${COLORS.black};
    border-radius: ${SPACES.m};
    word-break: break-word;

    background-color: ${COLORS.white};
    box-shadow: ${SHADOWS.xs};

    visibility: hidden;
    z-index: 11;

    transition: visibility ${`${TRANSITIONS.duration.fast} ${TRANSITIONS.function.linear}`};
  }

  ${({ tooltipText }) =>
    tooltipText?.length >= 17 &&
    css`
      &.tooltip:hover::after {
        visibility: visible;
      }
    `}

  ${({ background }) =>
    background &&
    css<{ background?: string }>`
      color: ${({ background }) => background}!important;

      &::before {
        content: '';
        display: inline-block;

        margin-right: 8px;
        height: 0.75rem;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background: ${({ background }) => background ?? COLORS.black};
      }
    `}

 
`;

export const ItemPrice = styled.strong`
  ${labelPriceCommonStyles}
`;

export const GenderAgeWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${SPACES.xxsm};
`;

export const EditButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;

  cursor: pointer;

  &:hover > .icon {
    background: ${COLORS.green};
  }
`;

export const Avatar = styled.img`
  height: 1.3rem;
  aspect-ratio: 1/1;

  cursor: pointer;
`;

export const ButtonTable = styled(Button)`
  width: 4rem;
  height: 2rem;

  font-size: ${FONTS.SIZES.s};
  line-height: ${FONTS.SIZES.xxxxl};
`;
