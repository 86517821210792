import { UseQueryResult, useQuery } from 'react-query';

import { APP_KEYS } from '../../module/common/constants';
import {ICategoryAll} from "../../types";
import {categoryServiceService} from "../../services";

const all = (): UseQueryResult<ICategoryAll | undefined> =>
  useQuery([APP_KEYS.QUERY_KEYS.GET_ALL_CATEGORY], () => categoryServiceService.all() );


export const useCategoryQuery = {
  all,
};
