import React, { useState } from 'react';

import { Icons } from '../../assets';
import { useCompanyQuery, useGuestQuery, useUserQuery } from '../../hooks';
import { COLORS, SPACES } from '../../theme';
import { ICompanyResponse, IGuestResponse, IUsersResponse } from '../../types';
import { FilePath } from '../../utils';
import {
  Drawer,
  Loader,
  NavigationComponentButton,
  NavigationComponentFilters,
  Pagination
} from '../common/component';
import { DivCommon, DrawerContainer, TitleCommon } from '../common/styles';
import { CompanyCrud, CompanyTable, UserCrud, UserTable } from './component';
import { GuestsTable } from './component/guests-table/guests-table';
import * as Styled from './user-setting.styled';

interface IOpenDrawer {
  flag: boolean;
  component: '' | 'add' | 'filter';
}

export const Roles = {
  user: 'Users',
  guests: 'Guests',
  companies: 'Companies'
};

export const UserSetting = () => {
  const [isPage, setIsPage] = useState(Roles.companies);
  const [page, setPage] = useState<number>(1);

  const { all: allCompanies } = useCompanyQuery;
  const { all: allUsers, findOneCacheMainUser } = useUserQuery;
  const { all: allGuests } = useGuestQuery;

  const mainUser = findOneCacheMainUser();

  // const { isLoading: isLoadingUsers, data: dataUsers } = allUsers(
  //   { page, company_name: mainUser?.user.role === 'admin' ? mainUser.user.company_info.name : '' },
  //   { enabled: isPage === Roles.user }
  // );

  const { isLoading: isLoadingGuests, data: dataGuests } = allGuests(
    { page },
    { enabled: isPage === Roles.guests }
  );
  const { isLoading: isLoadingCompanies, data: dataCompanies } = allCompanies(
    { page },
    { enabled: isPage === Roles.companies }
  );

  const Data: {
    [key: string]: {
      count: string | number;
      // dataInfo?: ICompanyResponse[] | IUsersResponse[] | IGuestResponse[];
      dataInfo?: ICompanyResponse[] | IGuestResponse[];
      isLoading: boolean;
    };
  } = {
    // Users: {
    //   count: dataUsers?.count ?? 0,
    //   dataInfo: dataUsers?.users ?? [],
    //   isLoading: isLoadingUsers
    // },

    Companies: {
      count: dataCompanies?.count ?? 0,
      dataInfo: dataCompanies?.companies ?? [],
      isLoading: isLoadingCompanies
    },

    Guests: {
      count: dataGuests?.count ?? 0,
      dataInfo: dataGuests?.guests ?? [],
      isLoading: isLoadingGuests
    }
  };

  const { dataInfo, count, isLoading } = Data[isPage];

  const isPageUser = isPage === Roles.user;

  const [openDrawer, setOpenDrawer] = useState<IOpenDrawer>({
    flag: false,
    component: ''
  });

  const onToggleDrawer = ({ flag, component }: IOpenDrawer) => {
    setOpenDrawer((prev) => {
      prev.flag = flag;
      prev.component = component;
      return { ...prev };
    });
  };

  const onClose = () => {
    onToggleDrawer({ flag: false, component: '' });
  };

  const arr = [
    {
      icon: FilePath(Icons.plusIcon),
      text: `Add ${isPageUser ? 'User' : 'Company'}`,
      onClick: onToggleDrawer.bind(this, { flag: true, component: 'add' })
    },
    {
      icon: FilePath(Icons.arrowBottomIcon),
      text: 'Filters',
      onClick: onToggleDrawer.bind(this, { flag: true, component: 'filter' })
    }
  ];

  return (
    <DivCommon>
      <TitleCommon>User Management</TitleCommon>
      <DivCommon
        width='100%'
        fd='row'
        margin={`${SPACES.xxxxxxls} 0 ${SPACES.xxxxl} 0`}
        jc='space-between'
      >
        <NavigationComponentButton
          isPage={isPage}
          setIsPage={setIsPage}
          array={[
            // { icon: FilePath(Icons.userIcon), text: Roles.user },
            { icon: FilePath(Icons.companiesIcon), text: Roles.companies },
            { icon: FilePath(Icons.companiesIcon), text: Roles.guests }
          ]}
        />

        <NavigationComponentFilters
          array={
            isPage === Roles.guests ||
            (isPage === Roles.companies && mainUser?.user.role === 'admin')
              ? [arr.pop()]
              : arr
          }
        />
      </DivCommon>

      <Styled.TableContainer>
        {/* {!isLoading && isPage === Roles.user && <UserTable users={dataInfo as IUsersResponse[]} />} */}
        {!isLoading && isPage === Roles.companies && (
          <CompanyTable companies={dataInfo as ICompanyResponse[]} />
        )}
        {!isLoading && isPage === Roles.guests && (
          <GuestsTable guests={dataInfo as IGuestResponse[]} />
        )}
        {isLoading && <Loader size='small' color={COLORS.green} height='auto' />}
      </Styled.TableContainer>
      <DivCommon ai='center'>
        <Styled.PaginationContainer>
          {count && +count > 20 ? (
            <Pagination
              totalCount={+count}
              pageSize={20}
              siblingCount={1}
              onPageChange={(num: number) => setPage(num)}
              currentPage={page}
            />
          ) : null}
        </Styled.PaginationContainer>
      </DivCommon>

      <Drawer onClose={onClose} open={openDrawer.flag}>
        {openDrawer.component === 'filter' && <DrawerContainer>filters</DrawerContainer>}

        {openDrawer.component === 'add' && isPageUser ? (
          <UserCrud onClose={onClose} />
        ) : (
          <CompanyCrud onClose={onClose} />
        )}
      </Drawer>
    </DivCommon>
  );
};
