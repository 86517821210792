import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { COLORS } from '../../theme';
import { AccountSetting } from '../account-setting/account-setting';
import { ForgotPassword, Login, SingUp } from '../auth/component';
import { AfterSingUpPages } from '../auth/component/sing-up/after-sing-up-pages';
import { Loader } from '../common/component';
import { APP_KEYS } from '../common/constants';
import { ProtectedPage, PublicPage } from '../common/hocs';
import { GuestAfterItemPages } from '../guest-pages/guest-after-item-pages';
import { GuestPages } from '../guest-pages/guest-pages';
import { HomeLayout } from '../home-layout/home-layout';
import { HomeLayoutContextProvider } from '../home-layout/providers/home-layout.provider';
import { InventoryManager } from '../inventory-manager/inventory-manager';
import { CreateProduct, SingleProduct } from '../product';
import { UserSetting } from '../user-setting/user-setting';



export const MainRouter = () => (
  <Router>
      <React.Suspense fallback={<Loader size='medium' height='auto' color={COLORS.green} />}>
      <Routes>
        <Route
          path={APP_KEYS.ROUTER_KEYS.LOGIN}
          element={
            <PublicPage>
              <Login />
            </PublicPage>
          }
        />

        <Route
          path={APP_KEYS.ROUTER_KEYS.GUEST}
          element={
            <PublicPage>
              <GuestPages />
            </PublicPage>
          }
        />
        <Route
          path={APP_KEYS.ROUTER_KEYS.GUEST_AFTER_ITEM}
          element={
            <PublicPage>
              <GuestAfterItemPages />
            </PublicPage>
          }
        />
        <Route
          path={APP_KEYS.ROUTER_KEYS.AFTER_SING_UP}
          element={
            <PublicPage>
              <AfterSingUpPages />
            </PublicPage>
          }
        />

        <Route path={APP_KEYS.ROUTER_KEYS.CREATE_ITEM_PUBLIC} element={<CreateProduct />} />

        <Route
          path={APP_KEYS.ROUTER_KEYS.SING_UP}
          element={
            <PublicPage>
              <SingUp />
            </PublicPage>
          }
        />

        <Route
          path={APP_KEYS.ROUTER_KEYS.FORGOT_PASSWORD}
          element={
            <PublicPage>
              <ForgotPassword />
            </PublicPage>
          }
        />
        <Route
          path={`${APP_KEYS.ROUTER_KEYS.HOME}/*`}
          element={
            <ProtectedPage>
              <HomeLayoutContextProvider>
                <HomeLayout>
                  <Routes>
                    {/*<Route*/}
                    {/*  path={APP_KEYS.ROUTER_KEYS.INVENTORY_DASHBOARD}*/}
                    {/*  element={<InventoryDashboard />}*/}
                    {/*/>*/}
                    <Route
                      path={APP_KEYS.ROUTER_KEYS.INVENTORY_PRODUCT}
                      element={<InventoryManager />}
                    />
                    <Route
                      path={`${APP_KEYS.ROUTER_KEYS.SINGLE_PRODUCT}/:id`}
                      element={<SingleProduct />}
                    />
                    {/*<Route*/}
                    {/*  path={APP_KEYS.ROUTER_KEYS.ACTION_TRACKER}*/}
                    {/*  element={<AuctionTracker />}*/}
                    {/*/>*/}
                    <Route
                      path={APP_KEYS.ROUTER_KEYS.ACCOUNT_SETTING}
                      element={<AccountSetting />}
                    />
                    <Route path={APP_KEYS.ROUTER_KEYS.USER_SETTING} element={<UserSetting />} />
                  </Routes>
                </HomeLayout>
              </HomeLayoutContextProvider>
            </ProtectedPage>
          }
        />
      </Routes>
    </React.Suspense>
  </Router>
);
