import styled, { css } from 'styled-components';

import { COLORS, MEDIA, SPACES } from '../../../../theme';
import { Center, DivCommon, DrawerContainer, IconCommon } from '../../../common/styles';

export const StyledDrawerContainer = styled(DrawerContainer)`
  padding: 0;
`;

export const Icon = styled(IconCommon)`
  height: 1rem;
  aspect-ratio: 1/1;
  cursor: pointer;

  &:hover {
    transform: scale(0.8);
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  max-height: 17rem;

  ${Center};
  margin: ${SPACES.xxl} 0;
  background: ${COLORS.black};
`;
export const Image = styled.img`
  width: fit-content;
  max-height: 17rem;

  object-fit: fill;
`;
export const DivSwitch = styled(DivCommon)`
  border-bottom: 1px solid ${COLORS.black125};
  padding-bottom: ${SPACES.xxsm};
  margin-bottom: ${SPACES.xxs};
`;

export const Content = styled(DivCommon)`
  height: 100%;
  position: relative;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0;
    background-color: ${COLORS.white};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.green};
  }
`;

export const StilePopupLayoutBottom = css`
  height: 80%;

  @media screen and (max-width: ${MEDIA.mobile_m}) {
    height: 86%;
  }
`;
